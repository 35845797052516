import { useAxios } from "../guard/axiosConfig"

export const missionServices = {
    getAllMissions,
    createMission,
    getMissionById,
    updateStatus,
    updateMission,
    deleteMissionById,
}

const route = '/mission';

function getAllMissions(filters) {
    return useAxios.get(`${route}/getAllMissions`, { params: filters });
}

function createMission(data) {
    return useAxios.post(`${route}/createMission`, data);
}

function getMissionById(id) {
    return useAxios.get(`${route}/getMissionById/${id}`);
}

function updateStatus(data) {
    return useAxios.put(`${route}/updateStatus`, data);
}

function updateMission(data) {
    return useAxios.put(`${route}/updateMission`, data);
}

function deleteMissionById(id) {
    return useAxios.delete(`${route}/deleteMissionById/${id}`);
}