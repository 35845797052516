import { useAxios } from "../guard/axiosConfig"

export const surveyPositionInfoServices = {
    createSurveyPositionInfo,
    getSurveyPositionInfoBySurveyPositionCode,
    updateSurveyPositionInfo,
}

const route = '/surveyPositionInfo';

function createSurveyPositionInfo(data) {
    return useAxios.post(`${route}/createSurveyPositionInfo`, data);
}

function getSurveyPositionInfoBySurveyPositionCode(code) {
    return useAxios.get(`${route}/getSurveyPositionInfoBySurveyPositionCode/${code}`);
}

function updateSurveyPositionInfo(data) {
    return useAxios.put(`${route}/updateSurveyPositionInfo`, data);
}