import { useAxios } from "../guard/axiosConfig"

export const surveyAreaServices = {
    createSurveyArea,
    getAllSurveyArea,
    getSurveyAreaByCode,
    updateStatus,
    updateSurveyArea,
    deleteSurveyAreaByCode,
    getAllSurveyAreaByAreaBoundaryId,
}

const route = '/surveyArea';

function createSurveyArea(data) {
    return useAxios.post(`${route}/createSurveyArea`, data);
}

function getAllSurveyArea(filters) {
    return useAxios.get(`${route}/getAllSurveyArea`, { params: filters });
}

function getSurveyAreaByCode(code) {
    return useAxios.get(`${route}/getSurveyAreaByCode/${code}`);
}

function updateStatus(data) {
    return useAxios.post(`${route}/updateStatus`, data);
}

function updateSurveyArea(data) {
    return useAxios.post(`${route}/updateSurveyArea`, data);
}

function deleteSurveyAreaByCode(code) {
    return useAxios.delete(`${route}/deleteSurveyAreaByCode/${code}`);
}

function getAllSurveyAreaByAreaBoundaryId(id) {
    return useAxios.get(`${route}/getAllSurveyAreaByAreaBoundaryId/${id}`);
}