import { useAxios } from "../guard/axiosConfig"

export const userGroupServices = {
    createGroup,
    getAllGroup,
    getGroupById,
    updateGroup
}

const route = '/userGroup';

function createGroup(data) {
    return useAxios.post(`${route}/createGroup`, data);
}

function getAllGroup() {
    return useAxios.get(`${route}/getAllGroup`);
}

function updateGroup(data) {
    return useAxios.post(`${route}/updateGroup`, data);
}

function getGroupById(id) {
    return useAxios.get(`${route}/getGroupById/${id}`);
}

