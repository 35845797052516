import { useAxios } from "../guard/axiosConfig";

export const flightZipFileServices = {
    getByFlightCode,
    createFlightZipFile,
    updateFlightZipFile,
    deleteFlighZipFileById,
}

const route = '/flightZipFile';

function getByFlightCode(flightCode) {
    return useAxios.get(`${route}/getByFlightCode/${flightCode}`);
}

function createFlightZipFile(data, setPercent) {
    const formData = new FormData();
    formData.append('data', JSON.stringify({ ...data }));
    formData.append('file', data.file);

    return useAxios.post(`${route}/createFlightZipFile`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
            setPercent(percentCompleted)
        }
    });
}

function updateFlightZipFile(data, setPercent) {
    const formData = new FormData();
    formData.append('data', JSON.stringify({ ...data }));
    formData.append('file', data.file);

    return useAxios.put(`${route}/updateFlightZipFile`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
            setPercent(percentCompleted)
        }
    });
}

function deleteFlighZipFileById(id) {
    return useAxios.delete(`${route}/deleteFlighZipFileById/${id}`);
}