import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

function useDataTable(pageSize = 6) {
    const [searchParams, setSearchParams] = useSearchParams({ page: 1 });
    const page = (parseInt(searchParams.get("page")) - 1) || 0;

    const paginationModel = useMemo(() =>  ({ page, pageSize }), [page, pageSize]);

    const resetPage = () => {
        setSearchParams(prev => {
            prev.set("page", 1);
            return prev;
        }, { replace: true });
    }

    return { paginationModel, resetPage }
}

export default useDataTable