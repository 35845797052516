import React, { useEffect, useState } from 'react'
import './DataTable.css'

import { Pagination } from '@mui/material'

import { 
    DataGrid, 
    gridPageSelector,
    gridPageSizeSelector,
    gridRowCountSelector,
    useGridApiContext,
    useGridSelector, 
} from '@mui/x-data-grid';

import { useSearchParams } from 'react-router-dom';

function DataTable({ columns = [], rows = [], rowCount, getRowId, pageProps }) {
    const [, setSearchParams] = useSearchParams();

    const [paginationModel, setPaginationModel] = useState({ ...pageProps });

    useEffect(() => {
        setSearchParams(prev => {
            prev.set("page", paginationModel.page + 1)
            return prev;
        }, { replace: true });
    }, [paginationModel.page]);

    useEffect(() => {
        setPaginationModel({ ...pageProps });
    }, [pageProps])

    return (
        <div className="data-table">
            <DataGrid 
                disableColumnMenu
                disableRowSelectionOnClick
                pagination
                paginationMode="server"
                getRowId={getRowId}
                rowCount={rowCount}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                rows={rows} 
                columns={columns} 
                slots={{ 
                    pagination: CustomPagination, 
                }} 
            />
        </div>
    )
}

export default DataTable

function CustomPagination() {

    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);

    const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
    const rowCount = useGridSelector(apiRef, gridRowCountSelector);
    const count = Math.ceil(rowCount / pageSize);

    const handlePageChange = (e, val) => apiRef.current.setPage(val - 1);

    return (
        <Pagination 
            showFirstButton 
            showLastButton
            variant="outlined" 
            shape="rounded"
            page={page + 1}
            count={count} 
            onChange={handlePageChange} 
        />
    )
}
