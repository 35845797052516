import { useAxios } from '../guard/axiosConfig'

export const flightReferenceUrlServices = {
    getByFlightCode,
    upsert,
}

const route = '/flightReferenceUrl';

function upsert(data) {
    return useAxios.post(`${route}/upsert`, data);
}

function getByFlightCode(flightCode) {
    return useAxios.get(`${route}/getByFlightCode/${flightCode}`);
}