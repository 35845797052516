import { useCallback, useEffect, useState } from 'react'

import { categoryServices } from '../services/categoryServices';

import useErrorHandler from './useErrorHandler';

function useCategory(filters = {}, dependency = []) {
    const { errorHandler } = useErrorHandler();

    const [categoryList, setCategoryList] = useState([]);
    const [rowCount, setRowCount] = useState(0);

    const getAllCategory = useCallback(async (currentFilters) => {
        try {
            const res = await categoryServices.getAllCategory(currentFilters);
            console.log("getAllCategory => ", res);
            if (res.status === 200) {
                setCategoryList(res.data.data);
                setRowCount(res.data.rowCount);
            }
        } catch (error) {
            console.log(error);
            errorHandler('error: getAllCategory', error.response.status);
        }
    }, []);

    useEffect(() => {
        getAllCategory(filters);
    }, dependency);

    return { categoryList, rowCount, getAllCategory };
}

export default useCategory