import { useAxios } from '../guard/axiosConfig'

import { jsonToQueryParam } from '../utils';

import env from '../guard/environments';

export const flightPathServices = {
    createFlightPath,
    updateFlightPath,
    getByFlightCode,
    getAllFlightPath,
}

const route = '/flightPath';

function createFlightPath(data) {
    const formData = new FormData();
    formData.append('data', JSON.stringify({ ...data }));
    formData.append('file', data.file);

    return useAxios.post(`${route}/createFlightPath`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}

function updateFlightPath(data) {
    const formData = new FormData();
    formData.append('data', JSON.stringify({ ...data }));
    formData.append('file', data.file);
    return useAxios.put(`${route}/updateFlightPath`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}

function getByFlightCode(flightCode) {
    return useAxios.get(`${route}/getByFlightCode/${flightCode}`);
}

function getAllFlightPath(filters) {
    return new EventSource(
        `${env.baseUrl}${route}/getAllFlightPath${jsonToQueryParam(filters)}`, 
        { withCredentials: true }
    );
}