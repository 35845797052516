import { Typography } from "@mui/material";

const DroneStatusLabel = ({ drone_status }) => {
  console.log("dronesattau", drone_status);
  const statusMap = {
    ready: {
      text: "พร้อมใช้งาน",
      color: "#03bfa9",
    },
    busy: {
      text: "กำลังดำเนินการ",
      color: "blue",
    },
    unavailable: {
      text: "ไม่พร้อมใช้งาน",
      color: "red",
    },
  };

  return (
    drone_status && (
      <div>
        <div
          style={{
            border: `4px solid ${statusMap[drone_status].color}`,
            borderRadius: "10px",
            padding: "10px",
            display: "flex",
            alignItems: "center",
            maxWidth: '120px'
          }}
        >
          <div className="status-dot"
            style={{
              backgroundColor: statusMap[drone_status].color,
              borderRadius: "50%",
              height: "10px",
              width: "10px",
              marginRight: "10px",
            }}
          ></div>
          <Typography variant="body2" color= {statusMap[drone_status].color}>
            {statusMap[drone_status].text}
          </Typography>
        </div>
      </div>
    )
  );
};

export default DroneStatusLabel;
