import { createContext, useEffect, useState } from 'react'

import { provinceServices } from '../services/provinceServices';

import { errorHandler } from '../utils';

export const ProvinceListContext = createContext();

function ProvinceProvider({ children }) {

    const [provinces, setProvinces] = useState([]);

    useEffect(() => {
        const getProvinceList = async () => {
            try {
                const res = await provinceServices.getProvinceList();
                console.log('getProvinceList => ', res);
                if (res.status === 200) {
                    setProvinces(res.data);
                }
            } catch (error) {
                console.log(error);
                errorHandler('ไม่สามารถดึงข้อมูลจังหวัดได้');
            }
        };

        getProvinceList();

    }, []);

    return (
        <ProvinceListContext.Provider value={{ provinces }}>
            {children}
        </ProvinceListContext.Provider>
    )
}

export default ProvinceProvider