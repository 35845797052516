import { useAxios } from "../guard/axiosConfig"

export const categoryServices = {
    createCategory,
    getAllCategory,
    getCategoryById,
    updateStatus,
    updateCategory,
    deleteCategoryById,
}

const route = '/category';

function createCategory(data) {
    return useAxios.post(`${route}/createCategory`, data);
}

function getAllCategory(filters) {
    return useAxios.get(`${route}/getAllCategory`, { params: filters });
}

function getCategoryById(id) {
    return useAxios.get(`${route}/getCategoryById/${id}`);
}

function updateStatus(data) {
    return useAxios.put(`${route}/updateStatus`, data);
}

function updateCategory(data) {
    return useAxios.put(`${route}/updateCategory`, data);
}

function deleteCategoryById(id) {
    return useAxios.delete(`${route}/deleteCategoryById/${id}`);
}