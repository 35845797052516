import { useAxios } from "../guard/axiosConfig"

export const logServices = {
    getAllLog,
}

const route = '/log';

function getAllLog(filters) {
    return useAxios.get(`${route}/getAllLog`, { params: filters });
}