import React, { useState } from 'react'
import './ManageAreaBoundary.css'

import { Box, Grid, InputAdornment, TextField, Typography } from '@mui/material'
import { FileDownload } from '@mui/icons-material';

import { useNavigate } from 'react-router-dom';

import DataTable from '../../components/DataTable/DataTable'
import OptionButton from '../../components/OptionButton/OptionButton';
import DeleteButton from '../../components/TableButton/DeleteButton';
import EditButton from '../../components/TableButton/EditButton';
import StatusButton from '../../components/TableButton/StatusButton';
import ExcelButton from '../../components/ExcelButton/ExcelButton';

import { areaBoundaryServices } from '../../services/areaBoundaryServices';

import useAreaBoundary from '../../hooks/useAreaBoundary';
import useDataTable from '../../hooks/useDataTable';
import useFile from '../../hooks/useFile';

const columns = [
    {
        field: 'row_num',
        headerName: 'ลำดับ',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        sortable: false,
    },
    {
        field: 'target_area',
        headerName: 'พื้นที่เป้าหมาย',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        minWidth: 150,
        sortable: false,
    },
    {
        field: 'file',
        headerName: 'เส้นขอบพื้นที่เป้าหมาย',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        minWidth: 150,
        sortable: false,
        renderCell: (param) => <DownloadButton data={param.row} />
    },
    {
        field: 'table_status',
        headerName: 'สถานะ',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        renderCell: (param) => <StatusButton data={param.row} service={areaBoundaryServices.updateAreaBoundaryStatus} />
    },
    {
        field: 'edit',
        headerName: 'แก้ไข',
        headerAlign: 'center',
        type: 'actions',
        sortable: false,
        renderCell: (param) => <EditButton id={param.row.id} formURL="manage-area-boundary-form" />
    },
    {
        field: 'delete',
        type: 'actions',
        sortable: false,
        renderCell: (param) => <DeleteButton id={param.row.id} service={areaBoundaryServices.deleteAreaBoundaryById} />
    },
];

const initFilters = {
    search: '',
    dateFrom: '',
    dateTo: '',
}

function ManageAreaBoundary() {
    const navigate = useNavigate();

    const { paginationModel, resetPage } = useDataTable();

    const [filters, setFilters] = useState(initFilters);
    const [paginationfilters, setPaginationFilters] = useState(initFilters);

    const { areaBoundaryList: rows, rowCount } = useAreaBoundary(
        { ...paginationfilters, ...paginationModel },
        [paginationModel.page, paginationfilters]
    );

    const handleAddClick = () => navigate('/dashboard/manage-area-boundary-form');

    const handleFilterChange = ({ target }) => {
        const { name, value } = target;
        console.log(value);

        setFilters(prev => {
            return {
                ...prev,
                [name]: value,
            }
        });
    }

    const handleSearchClick = () => {
        console.log(filters);
        setPaginationFilters({ ...filters });
        resetPage();
    }

    const handleClearClick = () => {
        setFilters({ ...initFilters });
        setPaginationFilters({ ...initFilters });
        resetPage();
    }

    return (
        <div className="manage-area-boundary">
            <Grid container className="filters" gap={1}>
                <Grid container item md={12} display="flex" alignItems="center">
                    <Grid item md={1}>
                        <Typography>ค้นหา</Typography>
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            fullWidth
                            size="small"
                            name="search"
                            value={filters.search}
                            onChange={handleFilterChange}
                        />
                    </Grid>
                </Grid>
                <Grid container item md={12} display="flex" alignItems="center">
                    <Grid item md={1}>
                        <Typography>วันที่เพิ่ม</Typography>
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            fullWidth
                            type="date"
                            size="small"
                            name="dateFrom"
                            value={filters.dateFrom}
                            onChange={handleFilterChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Box>From</Box>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            fullWidth
                            type="date"
                            size="small"
                            name="dateTo"
                            value={filters.dateTo}
                            onChange={handleFilterChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Box>To</Box>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid container className="options">
                <Grid item md={1}></Grid>
                <Grid
                    item xs={12} md={6}
                    sx={{
                        '& button': { width: '25%' },
                        '& :not(:nth-of-type(1))': { ml: 2 }
                    }}
                >
                    <OptionButton
                        text='ค้นหา'
                        bgColor='var(--options-btn-bg-color-1)'
                        hoverColor='var(--options-btn-bg-hover-1)'
                        onClick={handleSearchClick}
                    />
                    <OptionButton
                        text='ล้าง'
                        bgColor='var(--options-btn-bg-color-2)'
                        hoverColor='var(--options-btn-bg-hover-2)'
                        textColor='gray'
                        onClick={handleClearClick}
                    />
                    <OptionButton
                        text='เพิ่ม'
                        bgColor='var(--options-btn-bg-color-1)'
                        hoverColor='var(--options-btn-bg-hover-1)'
                        onClick={handleAddClick}
                    />
                </Grid>

                <ExcelButton modelName="AreaBoundary" importCallback={handleClearClick} />
            </Grid>

            <DataTable
                columns={columns}
                rows={rows}
                rowCount={rowCount}
                pageProps={paginationModel}
            />
        </div>
    )
}

export default ManageAreaBoundary

const DownloadButton = ({ data }) => {
    const { downloadFile } = useFile();

    const handleDownloadClick = async () => {
        const file = data.area_boundary_file;
        await downloadFile(file);
    }

    return (
        <OptionButton
            disabled={!data.area_boundary_file}
            bgColor="var(--options-btn-bg-color-5)"
            hoverColor="var(--options-btn-bg-hover-5)"
            onClick={handleDownloadClick}
        >
            ดาวน์โหลด
            <FileDownload />
        </OptionButton>
    )
}