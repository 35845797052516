import { useCallback, useEffect, useState } from 'react'

import { flightPlanServices } from '../services/flightPlanServices';

import useErrorHandler from './useErrorHandler';

function useFlightPlan(filters = {}, dependency = [], onSuccess) {
    const { errorHandler } = useErrorHandler();

    const [flightPlanList, setFlightPlanList] = useState([]);
    const [rowCount, setRowCount] = useState(0);

    const getAllFlightMissionPlan = useCallback(async (currentFilters) => {
        try {
            const res = await flightPlanServices.getAllFlightMissionPlan(currentFilters);
            console.log("getAllFlightMissionPlan => ", res);
            if (res.status === 200) {
                const data = res.data.data;
                onSuccess ? onSuccess(data, setFlightPlanList) : setFlightPlanList(data);
                setRowCount(res.data.rowCount);
            }
        } catch (error) {
            console.log(error);
            errorHandler('error: getAllFlightMissionPlan', error.response.status);
        }
    }, []);

    useEffect(() => {
        getAllFlightMissionPlan(filters);
    }, dependency);

    return { flightPlanList, setFlightPlanList, rowCount, getAllFlightMissionPlan };
}

export default useFlightPlan