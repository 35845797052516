import React, { useEffect, useState } from 'react';
import './MapInfo.css';

import { BrowserRouter, useParams } from 'react-router-dom';

import { Box, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { AccessTimeFilled, Cancel, CheckCircle, Delete, Edit } from '@mui/icons-material';

import FileUploadButton from '../../../components/FileUploadButton/FileUploadButton';
import OptionButton from '../../../components/OptionButton/OptionButton';
import KMZUploadForm from './Forms/KMZUploadForm/KMZUploadForm';
import TIFUploadForm from './Forms/TIFUploadForm/TIFUploadForm';

import { surveyResultImageServices } from '../../../services/surveyResultImageServices';

import useErrorHandler from '../../../hooks/useErrorHandler';
import useMapInfo from '../../../hooks/useMapInfo';

import { UserProvider } from '../../../contexts/UserContext';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const uploadStatusList = {
    pending: {
        text: 'กำลังประมวลผล',
        icon: <AccessTimeFilled color='info' />
    },
    completed: {
        text: 'อัปโหลดสำเร็จ',
        icon: <CheckCircle color='success' />
    },
    failed: {
        text: 'อัปโหลดไม่สำเร็จ',
        icon: <Cancel color='error' />
    },
}

const CustomSwal = withReactContent(Swal);

const fileTypeList = {
    kmz: {
        value: 'kmz',
        label: 'KMZ',
        fileTypeText: '.KML .KMZ',
        mimeType: ".kml, .kmz",
    },
    tif: {
        value: 'tif',
        label: 'TIF',
        fileTypeText: '.TIF',
        mimeType: "image/tiff",
    },
}

function MapInfo() {
    const { id } = useParams();

    const { errorHandler } = useErrorHandler();
    const { fileList, setFileList, fileType } = useMapInfo();

    useEffect(() => {
        const getSurveyResultFileBySurveyResultId = async () => {
            try {
                const res = await surveyResultImageServices.getSurveyResultFileBySurveyResultId(id, fileType);
                console.log('getSurveyResultFileBySurveyResultId => ', res);
                if (res.status === 200) {
                    setFileList(res.data.data);
                }
            } catch (error) {
                console.log(error);
                const { status, data } = error.response;
                errorHandler(
                    'error: getSurveyResultFileBySurveyResultId',
                    status,
                    data.message
                );
            }
        };

        id && getSurveyResultFileBySurveyResultId();

    }, [id]);

    return (
        <Grid container p={3} rowGap={1}>
            <Grid container item xs={12}>
                <Grid item xs={12}>
                    {fileType === "kmz" && <KMZUploadForm />}
                    {fileType === "tif" && <TIFUploadForm />}
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <FileListTable rows={fileList} />
            </Grid>
        </Grid>
    )
}

export default MapInfo

const EditForm = ({ id, data, fileType, setFileList }) => {
    const fileTypeText = fileTypeList[fileType].fileTypeText;
    const mimeType = fileTypeList[fileType].mimeType;

    const [surveyResultImage, setSurveyResultImage] = useState({
        map: data.map_file && { data: true, ...data.map_file },
        image: data.image_file && { data: true, ...data.image_file },
    });
    const [isFileChange, setIsFileChange] = useState({ map: false, image: false });

    const { errorHandler } = useErrorHandler();

    const handleFileChange = (acceptedFiles, fieldName) => {
        console.log(acceptedFiles);

        setIsFileChange(prev => ({
            ...prev,
            [fieldName]: true,
        }));
        setSurveyResultImage(prev => ({
            ...prev,
            [fieldName]: acceptedFiles
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            surveyResultImage: {
                surveyResultImageId: id,
                hasNewMapFile: isFileChange.map,
                hasNewImageFile: isFileChange.image,
                fileType,
            },
            mapFile: surveyResultImage.map?.data,
            imageFile: surveyResultImage.image?.data,
        }
        console.log(data);

        Swal.fire({
            title: `กำลังบันทึกไฟล์`,
            allowOutsideClick: false,
            didOpen: async () => {
                Swal.showLoading();
                await updateSurveyResultFileById(data);
            }
        });
    }

    const updateSurveyResultFileById = async (data) => {
        try {
            const res = await surveyResultImageServices.updateSurveyResultFileById(data);
            console.log('updateSurveyResultFileById => ', res);
            if (res.status === 201) {
                const resData = res.data || [];
                setFileList(resData);
                handleCloseSwal();
                Swal.fire({
                    title: "แก้ไขสำเร็จ",
                    icon: "success",
                });
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: updateSurveyResultFileById',
                status,
                data.message
            );
        }
    }

    const handleCloseSwal = () => CustomSwal.close();

    return (
        <form onSubmit={handleSubmit} className="map-info">
            <Grid container item xs={12} rowGap={1} className="file-upload">
                <Grid item xs={12}>
                    <Typography fontSize={20}>แก้ไขข้อมูล</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FileUploadButton
                        key={surveyResultImage.map}
                        fileTypeText={fileTypeText}
                        mimeType={mimeType}
                        file={surveyResultImage.map}
                        setFile={(file) => handleFileChange(file, 'map')}
                    />
                </Grid>
                {fileType !== "tif" && (
                    <Grid item xs={12}>
                        <FileUploadButton
                            key={surveyResultImage.image}
                            fileTypeText="(รูปภาพ)"
                            mimeType=".png, .jpg, .jpeg"
                            file={surveyResultImage.image}
                            setFile={(file) => handleFileChange(file, 'image')}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <div className="btn">
                        <OptionButton
                            text='ยกเลิก'
                            bgColor='var(--options-btn-bg-color-2)'
                            hoverColor='var(--options-btn-bg-hover-2)'
                            textColor="gray"
                            onClick={handleCloseSwal}
                        />
                        <OptionButton
                            text="บันทึก"
                            disabled={!surveyResultImage.map}
                            type="submit"
                            bgColor="var(--options-btn-bg-color-5)"
                            hoverColor="var(--options-btn-bg-hover-5)"
                        />
                    </div>
                </Grid>
            </Grid>
        </form>
    )
}

const FileListTable = ({ rows }) => {

    const { errorHandler } = useErrorHandler();
    const { fileType, setFileList } = useMapInfo();

    const handleFileEdit = (id, index) => {
        CustomSwal.fire({
            html: (
                <BrowserRouter>
                    <UserProvider>
                        <EditForm
                            id={id}
                            data={rows[index]}
                            fileType={fileType}
                            setFileList={setFileList}
                        />
                    </UserProvider>
                </BrowserRouter>
            ),
            showConfirmButton: false,
        });
    }

    const handleFileDelete = (id) => {
        Swal.fire({
            title: `คุณต้องการลบไฟล์หรือไม่?`,
            text: 'เมื่อยืนยันแล้วจะไม่สามารถกู้คืนไฟล์ได้',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: 'var(--options-btn-bg-color-5)',
            confirmButtonText: 'ยืนยันการลบ',
            cancelButtonColor: 'var(--options-btn-bg-color-6)',
            cancelButtonText: 'ยกเลิกการลบ',
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: `กำลังลบไฟล์`,
                    allowOutsideClick: false,
                    didOpen: async () => {
                        Swal.showLoading();
                        await deleteSurveyResultFileById(id);
                    }
                });
            }
        })
    }

    const deleteSurveyResultFileById = async (id) => {
        try {
            const res = await surveyResultImageServices.deleteSurveyResultFileById(id);
            console.log('deleteSurveyResultFileById =>', res);
            if (res.status === 200) {
                Swal.fire({
                    title: "ลบไฟล์สำเร็จ",
                    icon: "success",
                }).then((result) => {
                    if (result.isConfirmed) {
                        setFileList(prev => prev.filter(file => file.id !== id));
                    }
                });
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: deleteSurveyResultFileById',
                status,
                data.message
            );
        }
    }

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow>
                        {fileType === "tif" && <CustomTableHeader>สถานะแผนที่</CustomTableHeader>}
                        <CustomTableHeader>ชื่อไฟล์แผนที่</CustomTableHeader>
                        <CustomTableHeader>ชื่อไฟล์รูปภาพ</CustomTableHeader>
                        <CustomTableHeader></CustomTableHeader>
                        <CustomTableHeader></CustomTableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {rows.map((row, index) => {
                        const currentUploadStatus = uploadStatusList[row.upload_status];
                        const currentMapFilename = row.map_file.originalname;
                        const currentImageFilename = row.image_file?.originalname || '-';
                        return (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                {fileType === "tif" && (
                                    <CustomTableBody>
                                        <IconButton disabled>
                                            {currentUploadStatus.icon}
                                        </IconButton>
                                        <Typography>
                                            {currentUploadStatus.text}
                                        </Typography>
                                    </CustomTableBody>
                                )}
                                <CustomTableBody>
                                    {currentMapFilename}
                                </CustomTableBody>
                                <CustomTableBody>
                                    {currentImageFilename}
                                </CustomTableBody>
                                <CustomTableBody>
                                    <IconButton onClick={() => handleFileEdit(row.id, index)}>
                                        <Edit />
                                    </IconButton>
                                </CustomTableBody>
                                <CustomTableBody>
                                    <IconButton onClick={() => handleFileDelete(row.id)}>
                                        <Delete />
                                    </IconButton>
                                </CustomTableBody>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>

            {rows.length === 0 && (
                <Box sx={{ height: 200, display: 'grid', placeItems: 'center' }}>
                    <Typography>ไม่พบข้อมูล</Typography>
                </Box>
            )}

        </TableContainer>
    )
}

const CustomTableHeader = ({ children }) => {
    return (
        <TableCell
            align="center"
            sx={{
                bgcolor: 'var(--options-btn-bg-color-1)',
                color: 'white',
                fontWeight: '700'
            }}
        >
            {children}
        </TableCell>
    )
}

const CustomTableBody = ({ children }) => {
    return (
        <TableCell align="center" sx={{ textOverflow: 'ellipsis' }}>
            {children}
        </TableCell>
    )
}