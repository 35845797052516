import { Box, IconButton, Typography } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";

import DroneStatusLabel from "../../../components/Drone/DroneStatusLabel";

const DronePopup = ({ onClose, droneData }) => {
  return (
    <Box
      sx={{
        height: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
      }}
    >
      <Box
        sx={{
          height: "80%",
          backgroundColor: "rgba(255, 255, 255)",
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          borderRadius: "10px",
          overflow: "auto",
          boxShadow: 4,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton style={{ margin: "20px" }} size="small" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "60%", height: "100%", marginLeft: '50px'}}>
            <ImageGallery
              items={droneData.photos.map((photo) => ({
                original: photo.imagePreview,
                thumbnail: photo.imagePreview,
                originalAlt: photo.originalname,
                originalWidth: "800",
                originalHeight: "650",
              }))}
              showFullscreenButton={false}
              showPlayButton={false}
            />
          </div>
          <div
            style={{
              width: "30%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography  sx={{ fontWeight: 'bold', fontSize: '2rem' }}>{droneData.drone_code}</Typography>

            <Typography  sx={{ fontWeight: 'bold', fontSize: '1.2rem', mb: "5rem" }}>(รหัสอากาศยาน)</Typography>

            <Typography sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}>ประเภทอากาศยาน</Typography>
            <Typography  sx={{ fontWeight: 'bold', fontSize: '1.2rem', mb: "5rem" }}>{droneData.DroneType.type_name}</Typography>

            <Typography sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}>สัมภาระที่ติดตั้งได้</Typography>
            <Typography sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}>{droneData.equipped_payload}</Typography>
          </div>
          <div style={{ width: "10%", height: "100%", marginRight: '30px' }}>
             <DroneStatusLabel drone_status={droneData.drone_status}/>
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default DronePopup;
