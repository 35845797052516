import { useCallback, useEffect, useState } from 'react'

import { droneSizeServices } from '../services/droneSizeServices';

import useErrorHandler from './useErrorHandler';

function useDroneSize(filters = {}, dependency = [], onSuccess) {
    const { errorHandler } = useErrorHandler();

    const [droneSizeList, setDroneSizeList] = useState([]);
    const [rowCount, setRowCount] = useState(0);

    const getAllDroneSize = useCallback(async (currentFilters) => {
        try {
            const res = await droneSizeServices.getAllDroneSize(currentFilters);
            console.log("getAllDroneSize => ", res);
            if (res.status === 200) {
                const data = res.data.data;
                onSuccess ? onSuccess(data, setDroneSizeList) : setDroneSizeList(data);
                setRowCount(res.data.rowCount);
            }
        } catch (error) {
            console.log(error);
            errorHandler('error: getAllDroneSize', error.response.status);
        }
    }, []);

    useEffect(() => {
        getAllDroneSize(filters);
    }, dependency);

    return { droneSizeList, setDroneSizeList, rowCount, getAllDroneSize };
}

export default useDroneSize