import React, { useState } from 'react';
import './TIFUploadForm.css';

import { useNavigate, useParams } from 'react-router-dom';

import { Grid } from '@mui/material';

import { surveyResultImageServices } from '../../../../../services/surveyResultImageServices';

import FileUploadButton from '../../../../../components/FileUploadButton/FileUploadButton';
import OptionButton from '../../../../../components/OptionButton/OptionButton';

import useErrorHandler from '../../../../../hooks/useErrorHandler';
import useMapInfo from '../../../../../hooks/useMapInfo';

import Swal from 'sweetalert2';

function TIFUploadForm() {
    const { setFileList } = useMapInfo();

    const handleAddClick = (data) => {
        setFileList(prev => {
            return [...prev, data]
        });
    }

    return (
        <div className="tif-upload-form">
            <UploadButton onAdd={handleAddClick} />
        </div>
    )
}

export default TIFUploadForm

const UploadButton = ({ onAdd }) => {
    const { id } = useParams();
    const navigate = useNavigate();

    const { errorHandler } = useErrorHandler();

    const [uploadedFile, setUploadedFile] = useState(null);

    const handleFileChange = (file) => {
        console.log(file);

        setUploadedFile(file);
    };

    const goToDashboard = () => navigate('/dashboard/survey-result');
    const clearForm = () => setUploadedFile(null);

    const handleAddClick = () => {
        const data = {
            surveyResultImage: {
                surveyResultId: id,
            },
            mapFile: uploadedFile?.data,
        }
        console.log(data);

        Swal.fire({
            title: `กำลังบันทึกไฟล์`,
            allowOutsideClick: false,
            didOpen: async () => {
                Swal.showLoading();
                await createSurveyResultTifFile(data);
            }
        });

    }

    const createSurveyResultTifFile = async (data) => {
        try {
            const res = await surveyResultImageServices.createSurveyResultTifFile(data);
            console.log('createSurveyResultTifFile => ', res);
            if (res.status === 201) {
                Swal.fire({
                    title: "บันทึกไฟล์สำเร็จ",
                    icon: "success",
                }).then((result) => {
                    if (result.isConfirmed) {
                        onAdd(res.data);
                        clearForm();
                    }
                });
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: createSurveyResultTifFile',
                status,
                data.message
            );
        }
    }

    return (
        <Grid container item xs={12} rowGap={1}>
            <Grid item xs={12}>
                <FileUploadButton
                    key={uploadedFile}
                    fileTypeText="(.TIF)"
                    mimeType="image/tiff"
                    file={uploadedFile}
                    setFile={handleFileChange}
                />
            </Grid>
            <Grid item xs={12}>
                <div className="btn">
                    <OptionButton
                        text="บันทึก"
                        disabled={!uploadedFile}
                        onClick={handleAddClick}
                        bgColor="var(--options-btn-bg-color-5)"
                        hoverColor="var(--options-btn-bg-hover-5)"
                    />
                    <OptionButton
                        text="ยกเลิก"
                        bgColor="var(--options-btn-bg-color-2)"
                        hoverColor="var(--options-btn-bg-hover-2)"
                        textColor="gray"
                        onClick={goToDashboard}
                    />
                </div>
            </Grid>
        </Grid>
    )
}