import { useAxios } from "../guard/axiosConfig"

export const surveyResultImageServices = {
    getSurveyResultFileBySurveyResultId,
    createSurveyResultKmzWithImageFile,
    createSurveyResultTifFile,
    createSurveyResultKmzAndImageZipFile,
    updateSurveyResultFileById,
    deleteSurveyResultFileById,
}

const route = '/surveyResultImage';

function getSurveyResultFileBySurveyResultId(id, fileType) {
    return useAxios.get(`${route}/getSurveyResultFileBySurveyResultId/${id}`, { params: { fileType } });
}

function createSurveyResultKmzWithImageFile(data) {
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('mapFile', data.mapFile);
    formData.append('imageFile', data.imageFile);

    return useAxios.post(`${route}/createSurveyResultKmzWithImageFile`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}

function createSurveyResultTifFile(data) {
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('file', data.mapFile);

    return useAxios.post(`${route}/createSurveyResultTifFile`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}

function createSurveyResultKmzAndImageZipFile(data) {
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('mapFile', data.mapFile);
    formData.append('imageFile', data.imageFile);

    return useAxios.post(`${route}/createSurveyResultKmzAndImageZipFile`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}

function updateSurveyResultFileById(data) {
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('mapFile', data.mapFile);
    formData.append('imageFile', data.imageFile);

    return useAxios.put(`${route}/updateSurveyResultFileById`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}

function deleteSurveyResultFileById(id) {
    return useAxios.delete(`${route}/deleteSurveyResultFileById/${id}`);
}