import FlightPlan from "../pages/FlightPlan/FlightPlan";
import FlightPlanForm from "../pages/FlightPlan/FlightPlanForm";
import Calendar from "../pages/HomePage/Calendar/Calendar"
import Drone from "../pages/HomePage/Drone/DroneStatus"
import ManageAircraftTeam from '../pages/ManageAircraftTeam/ManageAircraftTeam';
import ManageAircraftTeamForm from "../pages/ManageAircraftTeam/ManageAircraftTeamForm";
import ManageDrone from '../pages/ManageDrone/ManageDrone';
import ManageDroneForm from "../pages/ManageDrone/ManageDroneForm";
import ManageFlightArea from "../pages/ManageFlightArea/ManageFlightArea";
import ManageFlightAreaForm from "../pages/ManageFlightArea/ManageFlightAreaForm";
import ManageUser from "../pages/ManageUser/ManageUser";
import ManageUserForm from "../pages/ManageUser/ManageUserForm";
import UserLog from "../pages/UserLog/UserLog";
import Profile from "../pages/HomePage/Profile/Profile";
import UavMap from "../pages/UavMap/UavMap";
import ManageUserGroup from "../pages/MangeUserGroup/ManageUserGroup";
import ManageUserGroupForm from "../pages/MangeUserGroup/ManageUserGroupForm";
import Overview from "../pages/Overview/Overview";
import ManageAreaBoundary from "../pages/ManageAreaBoundary/ManageAreaBoundary";
import ManageAreaBoundaryForm from "../pages/ManageAreaBoundary/ManageAreaBoundaryForm";
import ManageFlightImageForm from "../pages/Overview/ManageFlightImage/ManageFlightImageForm";
import ManageCoral from "../pages/ManageCoral/ManageCoral";
import ManageCoralForm from "../pages/ManageCoral/ManageCoralForm";
import SurveyResult from "../pages/SurveyResult/SurveyResult";
import SurveyResultForm from "../pages/SurveyResult/SurveyResultForm";

export const dashboardRoutes = [
    {
        element: <Overview />,
        path: "/overview",
        index: true,
    },
    {
        element: <ManageFlightImageForm />,
        path: "/overview/flightImage-form",
    },
    {
        element: <ManageFlightImageForm />,
        path: "/overview/flightImage-form/:id",
    },
    {
        element: <ManageDrone />,
        path: "/manage-drone",
    },
    {
        element: <ManageDroneForm />,
        path: "/manage-drone-form",
    },
    {
        element: <ManageDroneForm />,
        path: "/manage-drone-form/:droneCode",
    },
    {
        element: <ManageAircraftTeam />,
        path: "/manage-aircraft-team",
    },
    {
        element: <ManageAircraftTeamForm />,
        path: "/manage-aircraft-team-form",
    },
    {
        element: <ManageAircraftTeamForm />,
        path: "/manage-aircraft-team-form/:id",
    },
    {
        element: <ManageFlightArea />,
        path: "/manage-flight-area",
    },
    {
        element: <ManageFlightAreaForm />,
        path: "/manage-flight-area-form",
    },
    {
        element: <ManageFlightAreaForm />,
        path: "/manage-flight-area-form/:id",
    },
    {
        element: <ManageAreaBoundary />,
        path: "/manage-area-boundary",
    },
    {
        element: <ManageAreaBoundaryForm />,
        path: "/manage-area-boundary-form",
    },
    {
        element: <ManageAreaBoundaryForm />,
        path: "/manage-area-boundary-form/:id",
    },
    {
        element: <FlightPlan />,
        path: "/flight-plan",
    },
    {
        element: <FlightPlanForm />,
        path: "/flight-plan-form",
    },
    {
        element: <FlightPlanForm />,
        path: "/flight-plan-form/:id",
    },
    {
        element: <SurveyResult />,
        path: "/survey-result",
    },
    {
        element: <SurveyResultForm />,
        path: "/survey-result-form",
    },
    {
        element: <SurveyResultForm />,
        path: "/survey-result-form/:id",
    },
    {
        element: <ManageCoral />,
        path: "/manage-coral",
    },
    {
        element: <ManageCoralForm />,
        path: "/manage-coral-form",
    },
    {
        element: <ManageCoralForm />,
        path: "/manage-coral-form/:code",
    },
    {
        element: <h1 style={{ backgroundColor: 'white', margin: 0, padding: 0 }}>manage-streaming</h1>,
        path: "/manage-streaming",
    },
    {
        element: <ManageUser />,
        path: "/manage-user",
    },
    {
        element: <ManageUserForm />,
        path: "/manage-user-form",
    },
    {
        element: <ManageUserForm />,
        path: "/manage-user-form/:id",
    },
    {
        element: <ManageUserGroup />,
        path: "/manage-group",
    },
    {
        element: <ManageUserGroupForm />,
        path: "/manage-group-form",
    },
    {
        element: <ManageUserGroupForm />,
        path: "/manage-group-form/:id",
    },
    {
        element: <UserLog />,
        path: "/user-log",
    },
    {
        element: <UserLog />,
        path: "/admin-log",
    },
];

export const homeRoutes = [
    {
        element: <Calendar />,
        path: "/calendar",
        index: true,
    },
    {
        element: <UavMap />,
        path: "/map",
    },
    {
        element: <Drone />,
        path: "/drone"
    },
    {
        element: <Profile />,
        path: "/profile"
    },
   
];