import React, { useState } from "react";

import { useGridApiContext } from "@mui/x-data-grid";

import { useSearchParams } from "react-router-dom";

import { IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";

import FormModal from "../FormModal/FormModal";

function OverviewEditButton({ id, modalTitle, findById, fieldName, service }) {
    const apiRef = useGridApiContext();

    const [openModal, setOpenModal] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const handleEditClick = () => {
        const currentParams = Object.fromEntries([...searchParams]);
        setSearchParams({ ...currentParams, id });
        handleOpenModal();
    }

    const updateDataTable = (data) => {
        apiRef.current.updateRows([{ ...data }]);
    };

    return (
        <div>
            <IconButton onClick={handleEditClick}>
                <Edit />
            </IconButton>

            {openModal && (
                <FormModal
                    openModal={openModal}
                    onClose={handleCloseModal}
                    modalTitle={modalTitle}
                    fieldName={fieldName}
                    findById={findById}
                    service={service}
                    onDataChange={updateDataTable}
                />
            )}
        </div>
    )
}

export default OverviewEditButton