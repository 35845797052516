import { useAxios } from "../guard/axiosConfig"

export const droneSizeServices = {
    createDroneSize,
    getAllDroneSize,
    getDroneSizeById,
    updateStatus,
    updateDroneSize,
    deleteDroneSizeById,
}

const route = '/droneSize';

function createDroneSize(data) {
    return useAxios.post(`${route}/createDroneSize`, data);
}

function getAllDroneSize(filters) {
    return useAxios.get(`${route}/getAllDroneSize`, { params: filters });
}

function getDroneSizeById(id) {
    return useAxios.get(`${route}/getDroneSizeById/${id}`);
}

function updateStatus(data) {
    return useAxios.put(`${route}/updateStatus`, data);
}

function updateDroneSize(data) {
    return useAxios.put(`${route}/updateDroneSize`, data);
}

function deleteDroneSizeById(id) {
    return useAxios.delete(`${route}/deleteDroneSizeById/${id}`);
}