import { useAxios } from "../guard/axiosConfig"

export const flyingAreaServices = {
    getAllFlyingArea,
    getFlyingAreaById,
    createFlyingArea,
    updateFlyingArea,
    updateFlyingAreaStatus,
    deleteFlyingAreaById,
}

const route = '/flyingArea';

function getAllFlyingArea(filters) {
    return useAxios.get(`${route}/getAllFlyingArea`, { params: filters });
}

function getFlyingAreaById(id) {
    return useAxios.get(`${route}/getFlyingAreaById/${id}`);
}

function createFlyingArea(data) {
    return useAxios.post(`${route}/createFlyingArea`, data);
}

function updateFlyingArea(data) {
    return useAxios.put(`${route}/updateFlyingArea`, data);
}

function updateFlyingAreaStatus(data) {
    return useAxios.put(`${route}/updateFlyingAreaStatus`, data);
}

function deleteFlyingAreaById(id) {
    return useAxios.delete(`${route}/deleteFlyingAreaById/${id}`);
}