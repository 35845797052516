import React, { useEffect, useState } from 'react'

import { BrowserRouter, useSearchParams } from 'react-router-dom';

import { Badge, Box, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { BackupOutlined, Delete, Edit, ImageSearch } from '@mui/icons-material';

import OptionButton from '../../../../components/OptionButton/OptionButton';

import { fileServices } from '../../../../services/fileServices';
import { surveyPositionImageServices } from '../../../../services/surveyPositionImageServices';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'

import useErrorHandler from '../../../../hooks/useErrorHandler';

import { UserProvider } from '../../../../contexts/UserContext';

const CustomSwal = withReactContent(Swal);

const placeholderImage = "https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png";

const initFile = {
    data: null,
    originalname: "",
}

function PositionImage() {
    const [searchParams] = useSearchParams();

    const { errorHandler } = useErrorHandler();

    const [imageList, setImageList] = useState([]);

    const [description, setDescription] = useState('');
    const [file, setFile] = useState(initFile);

    useEffect(() => {
        const getImageBySurveyPositionCode = async () => {
            try {
                const res = await surveyPositionImageServices.getImageBySurveyPositionCode(searchParams.get("surveyPositionCode"));
                console.log("getImageBySurveyPositionCode => ", res);
                if (res.status === 200) {
                    const data = res.data.data || [];
                    setImageList(data.map(file => {
                        return {
                            id: file.id,
                            description: file.description,
                            file: file.file,
                        }
                    }));
                }
            } catch (error) {
                console.log(error);
                const { status, data } = error.response;
                errorHandler(
                    'error: getImageBySurveyPositionCode',
                    status,
                    data.message
                );
            }
        }

        searchParams.get("surveyPositionCode") && getImageBySurveyPositionCode();
    }, [searchParams]);

    const handleDescriptionChange = ({ target }) => setDescription(target.value);

    const handleFileChange = ({ target }) => {
        const files = target.files;
        console.log(files);
        if (files.length === 0) return;

        const currentFile = files[0];

        setFile({
            data: currentFile,
            originalname: currentFile.name,
        });
    };

    const clearInput = () => {
        setDescription('');
        setFile(initFile);
    }

    const handleAddImageClick = async (e) => {
        e.preventDefault();
        const data = {
            surveyPositionImage: {
                survey_position_code: searchParams.get("surveyPositionCode"),
                description,
            },
            file: file?.data,
        }
        console.log(data);

        try {
            const res = await surveyPositionImageServices.createSurveyPositionImage(data);
            console.log("createSurveyPositionImage => ", res);
            if (res.status === 201) {
                const resData = res.data;
                setImageList(prev => {
                    return [
                        ...prev,
                        {
                            id: resData.id,
                            file: resData.file,
                            description,
                        },
                    ]
                });
                clearInput();
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: createSurveyPositionImage',
                status,
                data.message
            );
        }
    }

    return (
        <div className="position-image">
            <form onSubmit={handleAddImageClick}>
                <Grid container columnGap={2} p={2}>
                    <Badge variant="dot" color="primary" invisible={!file.data}>
                        <OptionButton
                            input
                            bgColor='var(--options-btn-bg-color-5)'
                            hoverColor='var(--options-btn-bg-hover-5)'
                            startIcon={<BackupOutlined />}
                        >
                            อัปโหลดรูปภาพ {file.data && " (1 ไฟล์)"}
                            <input
                                hidden
                                type="file"
                                onChange={handleFileChange}
                                accept=".png, .jpg, .jpeg"
                            />
                        </OptionButton>
                    </Badge>
                    <Grid item xs={4}>
                        <TextField
                            required
                            fullWidth
                            size="small"
                            placeholder='คำอธิบาย'
                            value={description}
                            onChange={handleDescriptionChange}
                        />
                    </Grid>
                    <OptionButton
                        text="เพิ่มไฟล์"
                        type="submit"
                        disabled={!file.data}
                        bgColor='var(--options-btn-bg-color-1)'
                        hoverColor='var(--options-btn-bg-hover-1)'
                    />
                </Grid>
            </form>

            <ImageList rows={imageList} setImageList={setImageList} />
        </div>
    )
}

export default PositionImage

const ImageList = ({ rows, setImageList }) => {
    const [searchParams] = useSearchParams();

    const { errorHandler } = useErrorHandler();

    const handleViewImage = (file, index) => {
        CustomSwal.fire({
            html: (
                <BrowserRouter>
                    <UserProvider>
                        <ImagePreview file={file} index={index} setImageList={setImageList} />
                    </UserProvider>
                </BrowserRouter>
            ),
            confirmButtonText: 'ปิด',
        });
    }

    const handleEditClick = (id, index) => {
        CustomSwal.fire({
            html: (
                <BrowserRouter>
                    <UserProvider>
                        <EditForm
                            id={id}
                            data={rows[index]}
                            setImageList={setImageList}
                            surveyPositionCode={searchParams.get("surveyPositionCode")}
                        />
                    </UserProvider>
                </BrowserRouter>
            ),
            showConfirmButton: false,
        });
    }

    const handleDeleteClick = async (id) => {
        Swal.fire({
            title: `ยืนยันการลบรูปภาพ`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: 'var(--options-btn-bg-color-5)',
            confirmButtonText: 'ยืนยัน',
            cancelButtonColor: 'var(--options-btn-bg-color-6)',
            cancelButtonText: 'ยกเลิก',
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                deletePositionImage(id);
            }
        })
    }

    const deletePositionImage = async (id) => {
        try {
            const res = await surveyPositionImageServices.deleteSurveyPositionImageById(id);
            console.log(res);
            if (res.status === 200) {
                setImageList(prev => prev.filter(image => image.id !== id));
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: deleteSurveyPositionImageById',
                status,
                data.message
            );
        }
    }

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow>
                        <CustomTableHeader></CustomTableHeader>
                        <CustomTableHeader>ชื่อไฟล์</CustomTableHeader>
                        <CustomTableHeader>คำอธิบาย</CustomTableHeader>
                        <CustomTableHeader></CustomTableHeader>
                        <CustomTableHeader></CustomTableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {rows.map((row, index) => {
                        const currentFilename = row.file.originalname;
                        const currentDescription = row.description;
                        return (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <CustomTableBody>
                                    <IconButton onClick={() => handleViewImage(row.file, index)}>
                                        <ImageSearch />
                                    </IconButton>
                                </CustomTableBody>
                                <CustomTableBody>
                                    {currentFilename}
                                </CustomTableBody>
                                <CustomTableBody>
                                    {currentDescription}
                                </CustomTableBody>
                                <CustomTableBody>
                                    <IconButton onClick={() => handleEditClick(row.id, index)}>
                                        <Edit />
                                    </IconButton>
                                </CustomTableBody>
                                <CustomTableBody>
                                    <IconButton onClick={() => handleDeleteClick(row.id)}>
                                        <Delete />
                                    </IconButton>
                                </CustomTableBody>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>

            {rows.length === 0 && (
                <Box sx={{ height: 200, display: 'grid', placeItems: 'center' }}>
                    <Typography>ไม่พบข้อมูลไฟล์</Typography>
                </Box>
            )}

        </TableContainer>
    )
}

const EditForm = ({ id, data, setImageList, surveyPositionCode }) => {
    const { errorHandler } = useErrorHandler();

    const [file, setFile] = useState(data.file);
    const [isFileChange, setIsFileChange] = useState(false);
    const [description, setDescription] = useState(data.description);

    useEffect(() => console.log(data), [])

    const handleFileChange = ({ target }) => {
        const files = target.files;
        console.log(files);
        if (files.length === 0) return;

        const currentFile = files[0];

        setIsFileChange(true);
        setFile({
            data: currentFile,
            originalname: currentFile.name,
        });
    };

    const handleDescriptionChange = ({ target }) => setDescription(target.value);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newData = {
            surveyPositionImage: {
                id,
                description,
                hasNewFile: isFileChange,
                survey_position_code: surveyPositionCode,
            },
            file: file?.data,
        }
        console.log(newData);

        try {
            const res = await surveyPositionImageServices.updateSurveyPositionImage(newData);
            console.log('updateSurveyPositionImage => ', res);
            if (res.status === 201) {
                const resData = res.data || [];
                setImageList(resData.map(file => {
                    return {
                        id: file.id,
                        description: file.description,
                        file: file.file,
                    }
                }));
                handleCloseSwal();
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: updateSurveyPositionImage',
                status,
                data.message
            );
        }
    }

    const handleCloseSwal = () => CustomSwal.close();

    return (
        <form onSubmit={handleSubmit}>
            <Grid container rowGap={2} p={2}>
                <Badge variant="dot" color="primary" invisible={!file}>
                    <OptionButton
                        input
                        bgColor='var(--options-btn-bg-color-5)'
                        hoverColor='var(--options-btn-bg-hover-5)'
                        startIcon={<BackupOutlined />}
                    >
                        อัปโหลดรูปภาพ {file && " (1 ไฟล์)"}
                        <input
                            hidden
                            type="file"
                            onChange={handleFileChange}
                            accept=".png, .jpg, .jpeg"
                        />
                    </OptionButton>
                </Badge>
                <Grid item xs={12}>
                    <TextField
                        required
                        fullWidth
                        label='คำอธิบาย'
                        placeholder='คำอธิบาย'
                        value={description}
                        onChange={handleDescriptionChange}
                        sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--form-border-color)',
                                borderWidth: '3px',
                            },
                            '& .MuiInputBase-root': {
                                borderRadius: '8px',
                            }
                        }}
                    />
                </Grid>
                <Grid container item xs={12} columnGap={1} justifyContent="center">
                    <OptionButton
                        text='ยกเลิก'
                        bgColor='var(--options-btn-bg-color-2)'
                        hoverColor='var(--options-btn-bg-hover-2)'
                        textColor="gray"
                        onClick={handleCloseSwal}
                    />
                    <OptionButton
                        text='บันทึก'
                        type='submit'
                        disabled={!file}
                        bgColor='var(--options-btn-bg-color-5)'
                        hoverColor='var(--options-btn-bg-hover-5)'
                    />
                </Grid>
            </Grid>
        </form>
    )
}

const ImagePreview = ({ file, index, setImageList }) => {
    const { errorHandler } = useErrorHandler();

    const [currentFile, setCurrentFile] = useState(file);

    useEffect(() => {
        const getImagePreview = async () => {
            try {
                const res = await fileServices.getImagePreview(file);
                // console.log(res);
                if (res.status === 200) {
                    const imagePreview = res.data.data;
                    setCurrentFile(prev => ({ ...prev, imagePreview }));
                    setImageList(prev => {
                        const currentRow = prev[index];
                        currentRow.file.imagePreview = imagePreview;

                        prev[index] = currentRow;

                        return [...prev];
                    });
                }
            } catch (error) {
                console.log(error);
                const { status, data } = error.response;
                errorHandler(
                    'error: getImagePreview',
                    status,
                    data.message
                );
            }
        }

        !currentFile.imagePreview && getImagePreview();
    }, []);

    return (
        <img src={currentFile.imagePreview || placeholderImage} width="100%" alt="" />
    )
}

const CustomTableHeader = ({ children }) => {
    return (
        <TableCell
            align="center"
            sx={{
                bgcolor: 'var(--options-btn-bg-color-1)',
                color: 'white',
                fontWeight: '700'
            }}
        >
            {children}
        </TableCell>
    )
}

const CustomTableBody = ({ children }) => {
    return (
        <TableCell align="center" sx={{ textOverflow: 'ellipsis' }}>
            {children}
        </TableCell>
    )
}