import React, { useEffect, useMemo, useState } from 'react'
import './DashboardLayout.css'

import { AppBar, Avatar, Box, CssBaseline, IconButton, Toolbar, Typography } from '@mui/material';
import { Menu } from '@mui/icons-material'

import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

import Sidebar from '../../components/Sidebar/Sidebar';

import { sidebarMenu } from '../../constants/sidebarMenu';

import useAuth from '../../hooks/useAuth';

const DRAWER_WIDTH = 240;

function DashboardLayout() {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const { userID, userData } = useAuth();

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

    const goToProfile = () => navigate('/profile');

    const title = useMemo(() => {
        return sidebarMenu.find(currentMenu => pathname.includes(currentMenu.path))
    }, [pathname]);

    useEffect(() => window.scrollTo(0, 0));

    if (!userID) return <Navigate to="/login" replace />

    return (
        <Box className="dashboard-layout" sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                elevation={0}
                sx={{
                    width: { md: `calc(100% - ${DRAWER_WIDTH}px)` },
                    ml: { md: `${DRAWER_WIDTH}px` },
                }}
            >
                <Toolbar className="toolbar">
                    <div className="left-section">
                        <IconButton
                            color="inherit"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { md: 'none' } }}
                        >
                            <Menu />
                        </IconButton>
                        <Typography variant="h6" noWrap component="div" className="title">
                            {pathname.includes('form') && "เพิ่ม/แก้ไข "}{title?.menuName}
                        </Typography>
                    </div>
                    <div className="right-section">
                        <IconButton onClick={goToProfile}>
                            <Avatar src={userData?.photo?.imagePreview} alt="" />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>

            <Sidebar mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />

            <Box
                component="main"
                sx={{ flexGrow: 1, p: 2, width: `calc(100% - ${DRAWER_WIDTH}px)`, bgcolor: 'black' }}
            >
                <Toolbar />
                <Box sx={{ borderRadius: 4, p: 2, bgcolor: 'white', minHeight: '100vh' }}>
                    <Outlet />
                </Box>
            </Box>
        </Box>
    )
}

export default DashboardLayout