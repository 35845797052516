import { useAxios } from '../guard/axiosConfig'

export const flightImageServices = {
    createFlightImage,
    updateFlightImage,
    getAllFlightImageByFlyingArea,
    getAllFlightImage,
    getAllFlyingAreaWithFlightImage,
    deleteFlightImageById,
    deleteFlightImageByFlyingAreaId,
    getMangroveForest,
}

const route = '/flightImage';

function createFlightImage(data, setPercent) {
    const formData = new FormData();
    formData.append('data', JSON.stringify({ ...data }));
    formData.append('file', data.file);

    return useAxios.post(`${route}/createFlightImage`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
            setPercent(percentCompleted)
        }
    });
}

function updateFlightImage(data, setPercent) {
    const formData = new FormData();
    formData.append('data', JSON.stringify({ ...data }));
    formData.append('file', data.file);
    return useAxios.put(`${route}/updateFlightImage`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
            setPercent(percentCompleted)
        }
    });
}

function getAllFlightImageByFlyingArea(flyingAreaId) {
    return useAxios.get(`${route}/getAllFlightImageByFlyingArea/${flyingAreaId}`);
}

function getAllFlightImage(filters) {
    return useAxios.get(`${route}/getAllFlightImage`, { params: filters });
}

function getAllFlyingAreaWithFlightImage(filters) {
    return useAxios.get(`${route}/getAllFlyingAreaWithFlightImage`, { params: filters });
}

function deleteFlightImageById(id) {
    return useAxios.delete(`${route}/deleteFlightImageById/${id}`);
}

function deleteFlightImageByFlyingAreaId(id) {
    return useAxios.delete(`${route}/deleteFlightImageByFlyingAreaId/${id}`);
}

function getMangroveForest(filters) {
    return useAxios.get(`${route}/getMangroveForest`, { params: filters });
}