import { useCallback, useEffect, useState } from 'react'

import { missionServices } from '../services/missionServices';

import useErrorHandler from './useErrorHandler';

function useMission(filters = {}, dependency = []) {
    const { errorHandler } = useErrorHandler();

    const [missionList, setMissionList] = useState([]);
    const [rowCount, setRowCount] = useState(0);

    const getAllMissions = useCallback(async (currentFilters) => {
        try {
            const res = await missionServices.getAllMissions(currentFilters);
            console.log("getAllMissions => ", res);
            if (res.status === 200) {
                setMissionList(res.data.data);
                setRowCount(res.data.rowCount);
            }
        } catch (error) {
            console.log(error);
            errorHandler('error: getAllMissions', error.response.status);
        }
    }, []);

    useEffect(() => {
        getAllMissions(filters);
    }, dependency);

    return { missionList, rowCount, getAllMissions };
}

export default useMission