import { useCallback, useEffect, useState } from 'react'

import { targetTypeServices } from '../services/targetTypeServices';

import useErrorHandler from './useErrorHandler';

function useTargetType(filters = {}, dependency = [], onSuccess) {
    const { errorHandler } = useErrorHandler();

    const [targetTypeList, setTargetTypeList] = useState([]);
    const [rowCount, setRowCount] = useState(0);

    const getAllTargetType = useCallback(async (currentFilters) => {
        try {
            const res = await targetTypeServices.getAllTargetType(currentFilters);
            console.log("getAllTargetType => ", res);
            if (res.status === 200) {
                const data = res.data.data;
                onSuccess ? onSuccess(data, setTargetTypeList) : setTargetTypeList(data);
                setRowCount(res.data.rowCount);
            }
        } catch (error) {
            console.log(error);
            errorHandler('error: getAllTargetType', error.response.status);
        }
    }, []);

    useEffect(() => {
        getAllTargetType(filters);
    }, dependency);

    return { targetTypeList, setTargetTypeList, rowCount, getAllTargetType };
}

export default useTargetType