import { useCallback, useEffect, useState } from 'react'

import { userServices } from '../services/userServices';

import useErrorHandler from './useErrorHandler';

function useUser(filters = {}, dependency = []) {
    const { errorHandler } = useErrorHandler();

    const [userList, setUserList] = useState([]);
    const [rowCount, setRowCount] = useState(0);

    const getAllUser = useCallback(async (currentFilters) => {
        try {
            const res = await userServices.getAllUser(currentFilters);
            console.log("getAllUser => ", res);
            if (res.status === 200) {
                setUserList(res.data.data);
                setRowCount(res.data.rowCount);
            }
        } catch (error) {
            console.log(error);
            errorHandler('error: getAllUser', error.response.status);
        }
    }, []);

    useEffect(() => {
        getAllUser(filters);
    }, dependency);

    return { userList, rowCount };
}

export default useUser