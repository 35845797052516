import React, { useState } from "react";

import { FormControl, Grid, MenuItem, Select, Typography } from "@mui/material";

import DroneCard from "./DroneCard";

import DronePopup from "./DronePopup";

import useDrone from "../../../hooks/useDrone";

function DroneStatus() {
  const { droneList, lastUpdateTime } = useDrone({ withPhoto: 1 });
  const [filter, setFilter] = React.useState("10");

  const handleFilterChange = (event) => {
    console.log(event.target.value);
    setFilter(event.target.value);
  };

  const [openPopup, setOpenPopup] = useState(false);
  const [popupData, setPopupData] = useState(null); // state สำหรับเก็บข้อมูลสำหรับแสดงใน popup

  const handlePopupOpen = (drone) => {
    setOpenPopup(true);
    handlePopupData(drone);
  };

  const handlePopupClose = () => {
    setOpenPopup(false);
    handlePopupData(null);
  };

  const handlePopupData = (drone) => {
    setPopupData(drone);
  }

  // sort drones according to filter
  const sortDrones = () => {
    switch (filter) {
      case 20:
        return droneList.filter((drone) => drone.drone_status === "ready");
      case 30:
        return droneList.filter((drone) => drone.drone_status === "busy");
      case 40:
        return droneList.filter(
          (drone) => drone.drone_status === "unavailable"
        );
      default:
        return droneList;
    }
  };

  return (
    <div className="wrapper" style={{ margin: '50px 50px 0px 50px' }}>
      <div className="header" style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            background: "#0499b3",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography sx={{ padding: "20px", color: "white" }}>
            อัปเดทล่าสุด{" "}
            {lastUpdateTime && (
              <>
                {new Date(lastUpdateTime).toLocaleDateString("th-TH", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}{" "}
                {new Date(lastUpdateTime).toLocaleTimeString()}
              </>
            )}
          </Typography>
        </div>
        <FormControl
          sx={{
            width: "30%",
            borderRadius: "10px",
            background: "white",
            height: "100%",
          }}
        >
          <Select
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            value={filter}
            onChange={handleFilterChange}
            sx={{ width: "100%", borderRadius: "10px" }}
          >
            <MenuItem value={10}>ทั้งหมด</MenuItem>
            <MenuItem value={20}>พร้อมใช้งาน</MenuItem>
            <MenuItem value={30}>กำลังดำเนินการ</MenuItem>
            <MenuItem value={40}>ไม่พร้อมใช้งาน</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="card-section" style={{ marginTop: "20px" }}>
        <Grid container spacing={5}>
          {sortDrones().map((drone) => (
            <Grid item xs={4} key={drone.id}>
              <div className="Card">
                <DroneCard cardData={drone} onClick={() => handlePopupOpen(drone)} />
              </div>
            </Grid>
          ))}
          {openPopup && <DronePopup onClose={() => handlePopupClose()} droneData={popupData} />}
        </Grid>
      </div>
    </div>
  );
}
export default DroneStatus;
