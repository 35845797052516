import { createContext, useState, useEffect, useCallback } from "react";

import { userServices } from "../services/userServices";

import { useNavigate } from "react-router-dom";
import { base64ImageToFile, errorHandler } from "../utils";

export const UserContext = createContext(null);

const AUTH_ERROR_CODE = [401, 403, 404, 500];

export const UserProvider = ({ children }) => {
  const [userID, setUserID] = useState(localStorage.getItem("userID") || null);
  const [userData, setUserData] = useState(null);

  const [userNationalMaskedId, setUserNationalMaskedId] = useState(null);
  const navigate = useNavigate();

  const maskNationalId = (username) => {
    if (username.length >= 11) {
      const maskedDigits = username.substring(0, 9).replace(/\d/g, "*");
      return maskedDigits + username.substring(9);
    } else {
      return username;
    }
  };


  const fetchUserData = useCallback(async () => {
    try {
      console.log('fetch');

      if (!userID) {
        console.log('userData not found please login.');
        return;
      }

      const response = await userServices.getUserById(userID);

      if (response.status !== 200) {
        throw new Error("Failed to fetch user data");
      }

      console.log('userdata', response.data.data);
      const user_data = response.data.data;
      user_data.photo = user_data.photo && {
        ...user_data.photo,
        data: user_data.photo?.imagePreview
          ? base64ImageToFile(user_data.photo.imagePreview, user_data.photo.originalname)
          : null,
      };

      setUserNationalMaskedId(maskNationalId(user_data.username));

      setUserData(user_data);

    } catch (error) {
      console.log(error);
      const { status, data } = error.response;
      errorHandler('error: getUserById', status, data.message, false, () => {
        if (AUTH_ERROR_CODE.includes(status)) {
          logout();
        }
      });
    }
  }, [userID]);

  useEffect(() => {
    if (!userID) {
      localStorage.clear("userID");
    } else {
      localStorage.setItem("userID", userID);
      fetchUserData();
    }
  }, [userID]);

  const logout = async () => {
    try {
      const res = await userServices.logout(userID);
      if (res.status === 200) {
        setUserID(null);
        setUserData(null);
        navigate("/login");
      }
    } catch (error) {
      console.log(error);
      errorHandler('error: logout', error.response.status);
    }
  }

  return (
    <UserContext.Provider value={{ userID, setUserID, userData, logout, userNationalMaskedId, setUserData, fetchUserData }}>
      {children}
    </UserContext.Provider>
  );
};