import React, { useCallback, useEffect, useRef, useState } from 'react'

import { useLocation } from 'react-router-dom';

import useErrorHandler from '../../../hooks/useErrorHandler';
import useFile from '../../../hooks/useFile';
import useMapFilters from '../../../hooks/useMapFilters'

import { Button, IconButton, ListItem, ListItemText, Typography } from '@mui/material';
import { Download, Info } from '@mui/icons-material';

import { surveyResultServices } from '../../../services/surveyResultServices';

import { access_token, username } from '../../../constants/mapConstants';

import { Marker, Popup, TileLayer, useMap } from 'react-leaflet';
import KML from 'react-leaflet-kml'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'

const CustomSwal = withReactContent(Swal);

function SeaCoastLayer() {
    const location = useLocation();
    const isMobile = location.pathname.includes('mapForMobile');

    const map = useMap();

    const { errorHandler } = useErrorHandler();
    const { downloadFile } = useFile();
    const { filters } = useMapFilters();

    const ref = useRef([]);

    const [data, setData] = useState(null);
    const [fileType, setFileType] = useState('kmz');
    const [mapData, setMapData] = useState([]);

    const getMapData = useCallback(async (currentFilters) => {
        try {
            const res = await surveyResultServices.getMapData({ ...currentFilters, checkStatus: 1 });
            console.log(res);
            if (res.status === 200) {
                const resData = res.data.data;
                if (resData) {
                    const resFileType = resData.fileType;
                    const surveyResultReport = resData.SurveyResultReport;
                    const surveyResultImages = resData.SurveyResultImages;

                    setFileType(resFileType);
                    setData(surveyResultReport);

                    if (surveyResultImages.length) {
                        if (resFileType === 'kmz') {
                            const parser = new DOMParser();

                            setMapData(surveyResultImages.map(surveyResultImage => {
                                const kmlData = parser.parseFromString(surveyResultImage.mapData.kml, "text/xml");
                                return { ...surveyResultImage, kml: kmlData }
                            }))
                        }

                        if (resFileType === 'tif') {
                            setMapData(surveyResultImages);
                        }

                        map.flyTo(surveyResultImages[0].mapData.center, 13);
                    }

                    handleAdditionalDataClick(surveyResultReport);
                }
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: getMapData',
                status,
                data.message
            );
        }
    }, []);

    useEffect(() => {
        CustomSwal.close();
        setData(null);
        setMapData([]);
        getMapData(filters);

        return () => CustomSwal.close();
    }, [filters]);

    const handleAdditionalDataClick = (currentData) => {
        const { image_file, report_file } = currentData;
        console.log(image_file?.filename);
        console.log(report_file?.filename);
        CustomSwal.fire({
            title: 'ข้อมูลเพิ่มเติม',
            backdrop: false,
            position: isMobile ? 'top-right' : 'center-right',
            confirmButtonText: 'ปิด',
            customClass: {
                popup: isMobile ? 'mobile-popup' : 'info-popup',
            },
            html: (
                <div>
                    {image_file ? (
                        <img src={image_file.imagePreview} width='80%' alt='' />
                    ) : (
                        <Typography>ไม่พบรูปภาพ</Typography>
                    )}

                    <Typography textAlign="left" sx={{ fontWeight: 700 }}>ไฟล์รายงาน</Typography>
                    {report_file ? (
                        <ListItem
                            secondaryAction={
                                <IconButton edge="end" onClick={() => handleDownloadReport(report_file)}>
                                    <Download />
                                </IconButton>
                            }
                        >
                            <ListItemText primary={report_file.originalname} />
                        </ListItem>
                    ) : (
                        <Typography>ไม่พบรายงาน</Typography>
                    )}
                </div>
            ),
        })
    }

    const handleMobileDownload = (file) => {
        const link = document.createElement("a");
        link.href = `${window.location.origin}/download/${file.filename}`;
        document.body.appendChild(link);
        link.click();
    }

    const handleDownloadReport = async (file) => {
        if (isMobile) return handleMobileDownload(file);

        await downloadFile(file);
    }

    return (
        <div>
            {fileType === 'tif' && mapData.map(item => (
                <TileLayer
                    key={item.id}
                    url={`https://api.mapbox.com/v4/${username}.${item.mapData.mapId}/{z}/{x}/{y}.png?access_token=${access_token}`}
                />
            ))}

            {fileType === 'kmz' && mapData.map((item, i) =>
                <KML key={item.id} kml={item.kml} eventHandlers={{ click: () => { ref.current[i].openPopup() } }}>
                    <Marker opacity={0} ref={(el) => ref.current[i] = el} position={item.mapData.center}>
                        <Popup>
                            {item.image?.imagePreview ? (
                                <img src={item.image.imagePreview} width={180} alt="" />
                            ) : (
                                <Typography>ไม่พบรูปภาพ</Typography>
                            )}
                        </Popup>
                    </Marker>
                </KML>
            )}

            <Button
                disabled={!data}
                variant='contained'
                startIcon={<Info />}
                sx={{
                    position: 'fixed',
                    bottom: '10px',
                    left: '10px',
                    zIndex: 6000,
                    bgcolor: 'var(--options-btn-bg-color-1)',
                    '&:hover': { bgcolor: 'var(--options-btn-bg-hover-1)' },
                }}
                onClick={() => handleAdditionalDataClick(data)}
            >
                ดูข้อมูลเพิ่มเติม
            </Button>
        </div>
    )
}

export default SeaCoastLayer

