import React, { useEffect, useState } from 'react'
import './PositionInfo.css'

import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { Grid, InputAdornment, TextField, Typography } from '@mui/material'

import { surveyPositionInfoServices } from '../../../../services/surveyPositionInfoServices';

import useErrorHandler from '../../../../hooks/useErrorHandler';

import Swal from 'sweetalert2';
import OptionButton from '../../../../components/OptionButton/OptionButton';

function PositionInfo() {
    const { code } = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const { errorHandler } = useErrorHandler();

    const [isNewPosition, setIsNewPosition] = useState(false);
    const [positionInfo, setPositionInfo] = useState({
        postionName: '',
        date: '',
        latitude: '',
        longitude: '',
    });

    useEffect(() => {
        const getSurveyPositionInfoBySurveyPositionCode = async () => {
            try {
                const res = await surveyPositionInfoServices.getSurveyPositionInfoBySurveyPositionCode(searchParams.get("surveyPositionCode"));
                console.log('getSurveyPositionInfoBySurveyPositionCode => ', res);
                if (res.status === 200) {
                    const data = res.data.data;
                    if (data) {
                        setPositionInfo({
                            postionName: data.survey_position_name,
                            date: data.date_taken,
                            latitude: data.latitude,
                            longitude: data.longitude,
                        });
                    } else {
                        setIsNewPosition(true);
                    }
                }
            } catch (error) {
                console.log(error);
                const { status, data } = error.response;
                errorHandler(
                    'error: getSurveyPositionInfoBySurveyPositionCode',
                    status,
                    data.message
                );
            }
        }

        searchParams.has("surveyPositionCode") && getSurveyPositionInfoBySurveyPositionCode();
    }, [searchParams])

    const goToDashboard = () => navigate('/dashboard/manage-coral');

    const handleDataChange = ({ target }) => {
        const { name, value } = target;
        console.log(name);
        console.log(value);

        setPositionInfo(prev => ({
            ...prev,
            [name]: value
        }));
    }

    const handlePositionInfoSubmit = (e) => {
        e.preventDefault();
        const data = {
            survey_position_code: searchParams.get("surveyPositionCode"),
            survey_position_name: positionInfo.postionName,
            date_taken: positionInfo.date,
            latitude: positionInfo.latitude,
            longitude: positionInfo.longitude,
        }
        console.log(data);

        if (!code || isNewPosition) {
            console.log('create');
            createSurveyPositionInfo(data);
        } else {
            console.log('update');
            updateSurveyPositionInfo(data);
        }
    }

    const createSurveyPositionInfo = async (data) => {
        try {
            const res = await surveyPositionInfoServices.createSurveyPositionInfo(data);
            console.log(res);
            if (res.status === 201) {
                setIsNewPosition(false);
                Swal.fire({
                    title: "บันทึกข้อมูลตำแหน่งสำรวจสำเร็จ",
                    icon: "success",
                });
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: createSurveyPositionInfo',
                status,
                data.message
            );
        }
    }

    const updateSurveyPositionInfo = async (data) => {
        try {
            const res = await surveyPositionInfoServices.updateSurveyPositionInfo(data);
            console.log(res);
            if (res.status === 201) {
                Swal.fire({
                    title: "อัปเดตข้อมูลตำแหน่งสำรวจสำเร็จ",
                    icon: "success",
                });
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: updateSurveyPositionInfo',
                status,
                data.message
            );
        }
    }

    return (
        <form onSubmit={handlePositionInfoSubmit} className="position-info">
            <Grid container rowGap={2} p={2}>
                <Grid container item xs={12} columnGap={3}>
                    <Grid item xs={2} display="flex" alignItems="center">
                        <Typography className="form-label">ชื่อตำแหน่งสำรวจ</Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            fullWidth
                            required
                            size="small"
                            name="postionName"
                            value={positionInfo.postionName}
                            onChange={handleDataChange}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} columnGap={3}>
                    <Grid item xs={2} display="flex" alignItems="center">
                        <Typography className="form-label">วันที่ถ่าย</Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            fullWidth
                            required
                            size="small"
                            name="date"
                            type="date"
                            value={positionInfo.date}
                            onChange={handleDataChange}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} columnGap={3}>
                    <Grid item xs={2} display="flex" alignItems="center">
                        <Typography className="form-label">พิกัด</Typography>
                    </Grid>
                    <Grid container item xs={8} columnGap={1}>
                        <TextField
                            required
                            size="small"
                            name="latitude"
                            value={positionInfo.latitude}
                            onChange={handleDataChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">lat</InputAdornment>
                                )
                            }}
                        />
                        <TextField
                            required
                            size="small"
                            name="longitude"
                            value={positionInfo.longitude}
                            onChange={handleDataChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">lng</InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <div className="btn">
                        <OptionButton
                            text="บันทึก"
                            type="submit"
                            bgColor="var(--options-btn-bg-color-5)"
                            hoverColor="var(--options-btn-bg-hover-5)"
                        />
                        <OptionButton
                            text="ยกเลิก"
                            bgColor="var(--options-btn-bg-color-2)"
                            hoverColor="var(--options-btn-bg-hover-2)"
                            textColor="gray"
                            onClick={goToDashboard}
                        />
                    </div>
                </Grid>
            </Grid>
        </form>
    )
}

export default PositionInfo