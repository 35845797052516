import React from 'react'

import { Button } from '@mui/material'

function OptionButton({ 
    children, disabled, input, type, bgColor, 
    hoverColor, text, textColor = 'white', startIcon, onClick 
}) {
    return (
        <Button 
            disabled={disabled}
            type={type || "button"}
            variant="contained"
            component={input ? "label" : "button"}
            startIcon={startIcon}
            onClick={onClick}
            sx={{ 
                bgcolor: bgColor,
                '&:hover': { bgcolor: hoverColor },
                color: textColor,
                borderRadius: "12px",
            }} 
        >
            {children ? children : text}
        </Button>
    )
}

export default OptionButton