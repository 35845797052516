import React, { useEffect } from 'react'

import { useSearchParams } from 'react-router-dom';

import { userServices } from '../../services/userServices';

import useAuth from '../../hooks/useAuth';
import useErrorHandler from '../../hooks/useErrorHandler';

import UavMap from '../UavMap/UavMap'

function MapForMobile() {
    const [searchParams] = useSearchParams();

    const { userData, setUserID } = useAuth();
    const { errorHandler } = useErrorHandler();

    useEffect(() => {
        const login = async () => {
            try {
                const res = await userServices.loginForMap({ id: searchParams.get("id") });
                console.log('logingForMap => ', res);
                if (res.status === 200) {
                    setUserID(res.data.data);
                }
            } catch (error) {
                console.log("error", error);
                const { status, data } = error.response;
                errorHandler(
                    'error: loginForMap',
                    status,
                    data.message
                );
            }
        }

        searchParams.get("id") && login();
    }, [searchParams.get("id")]);



    if (userData?.id) {
        return <UavMap />
    }

    return (
        <div>LOADING....</div>
    )
}

export default MapForMobile