import { useCallback, useEffect, useState } from 'react'

import { droneServices } from '../services/droneServices';

import useErrorHandler from './useErrorHandler';

function useDrone(filters = {}, dependency = []) {
    const { errorHandler } = useErrorHandler();

    const [droneList, setDroneList] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [lastUpdateTime, setLastUpdateTime] = useState("");

    const getAllDrone = useCallback(async (currentFilters) => {
        try {
            const res = await droneServices.getAllDrone(currentFilters);
            console.log("getAllDrone => ", res);
            if (res.status === 200) {
                setDroneList(res.data.data);
                setRowCount(res.data.rowCount);
                setLastUpdateTime(res.data.lastUpdateTime);
            }
        } catch (error) {
            console.log(error);
            errorHandler('error: getAllDrone', error.response.status);
        }
    }, []);

    useEffect(() => {
        getAllDrone(filters);
    }, dependency);

    return { droneList, rowCount, lastUpdateTime };
}

export default useDrone