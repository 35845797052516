import React, { useEffect, useMemo, useState } from 'react'
import './FlightInfo.css'

import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { Box, Checkbox, Chip, Divider, FormControl, Grid, InputAdornment, ListItemText, MenuItem, Select, TextField, Typography } from '@mui/material'

import OptionButton from '../../../../components/OptionButton/OptionButton'

import { openStreetMapConfig, thaiCenter } from '../../../../constants/mapConstants'

import { MapContainer, Marker, TileLayer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

import { flightInfoServices } from '../../../../services/flightInfoServices'
import { flyingAreaServices } from '../../../../services/flyingAreaServices'

import Swal from 'sweetalert2'
import utm from 'utm-latlng'

import useErrorHandler from '../../../../hooks/useErrorHandler'
import useFlightDataList from '../../../../hooks/useFlightDataList'

const utmToLatLng = new utm();
const customMarker = new L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40]
});

const flightStatusList = [
    {
        label: "เตรียมการบิน",
        value: "prepare",
    },
    {
        label: "อยู่ระหว่างบิน",
        value: "inflight",
    },
    {
        label: "จบภารกิจสมบูรณ์",
        value: "complete",
    },
    {
        label: "จบภารกิจไม่สมบูรณ์",
        value: "incomplete",
    },
    {
        label: "ยกเลิกเที่ยวบิน",
        value: "cancel",
    },
];

function FlightInfo() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const { droneList, flightTeamList, missionList, userList } = useFlightDataList();
    const { errorHandler } = useErrorHandler();

    const [flyingBaseList, setFlyingBaseList] = useState([]);
    const [isNewFlight, setIsNewFlight] = useState(false);
    const [flightInfo, setFlightInfo] = useState({
        flightStatus: "",
        missionId: "",
        droneId: "",
        flightTeam: "",
        leaderId: "",
        members: [],
        note: "",
        flyingBase: "",
        flightDate: "",
        takeoffTime: "",
        landingTime: "",
        flightAltitude: "",
        flightSpeed: "",
        flightDuration: "",
        patrolAreaSize: "",
        rain: "",
        wind: "",
        visibility: "",
        other: "",
    });

    const currentPosition = useMemo(() => {
        if (!flightInfo.flyingBase) return null;

        const currentFlyingBase = flyingBaseList.find(flyingBase => flyingBase.id === flightInfo.flyingBase);

        if (!currentFlyingBase) return null;

        const position = utmToLatLng.convertUtmToLatLng(
            currentFlyingBase.utm_x,
            currentFlyingBase.utm_y,
            currentFlyingBase.utm_zone,
            'N'
        );

        return [position.lat, position.lng];
    }, [flyingBaseList, flightInfo.flyingBase]);

    useEffect(() => {
        const getFlyingAreaById = async () => {
            try {
                const res = await flyingAreaServices.getFlyingAreaById(searchParams.get("flyingArea"));
                console.log(res);
                if (res.status === 200) {
                    const data = res.data.data;
                    data.FlyingBases.length && setFlyingBaseList([...data.FlyingBases]);
                }
            } catch (error) {
                console.log(error);
                const { status, data } = error.response;
                errorHandler(
                    'error: getFlyingAreaById',
                    status,
                    data.message
                );
            }
        }

        searchParams.has("flyingArea") && getFlyingAreaById();
    }, [searchParams])

    useEffect(() => {
        const getFlightInfoByCode = async () => {
            try {
                const res = await flightInfoServices.getFlightInfoByCode(searchParams.get("flightCode"));
                console.log('getFlightInfoByCode => ', res);
                if (res.status === 200) {
                    const data = res.data.data;
                    if (data) {
                        setFlightInfo({
                            flightStatus: data.flight_status,
                            missionId: data.MissionId,
                            droneId: data.drone_code,
                            flightTeam: data.flying_team,
                            leaderId: data.leader_id,
                            members: data.Members.map(member => member.id),
                            note: data.note,
                            flyingBase: data.flying_base || "",
                            flightDate: data.flight_date || "",
                            takeoffTime: data.takeoff_time || "",
                            landingTime: data.landing_time || "",
                            flightAltitude: data.flight_altitude,
                            flightSpeed: data.flight_speed,
                            flightDuration: data.flight_duration,
                            patrolAreaSize: data.patrol_area_size,
                            rain: data.rain,
                            wind: data.wind,
                            visibility: data.visibility,
                            other: data.other,
                        });
                    } else {
                        setIsNewFlight(true);
                    }
                }
            } catch (error) {
                console.log(error);
                const { status, data } = error.response;
                errorHandler(
                    'error: getFlightInfoByCode',
                    status,
                    data.message
                );
            }
        }

        searchParams.has("flightCode") && getFlightInfoByCode();
    }, [searchParams])

    const goToDashboard = () => navigate('/dashboard/flight-plan');

    const handleChange = ({ target }) => {
        setFlightInfo(prev => {
            return {
                ...prev,
                [target.name]: target.value
            }
        })
    }

    const handleFlightInfoSubmit = async (e) => {
        e.preventDefault();
        const data = {
            flight_code: searchParams.get("flightCode"),
            missionId: flightInfo.missionId,
            flight_status: flightInfo.flightStatus,
            drone_code: flightInfo.droneId,
            flying_team: flightInfo.flightTeam,
            leader_id: flightInfo.leaderId,
            Members: flightInfo.members,
            note: flightInfo.note,
            flying_base: flightInfo.flyingBase,
            flight_date: flightInfo.flightDate || null,
            takeoff_time: flightInfo.takeoffTime || null,
            landing_time: flightInfo.landingTime || null,
            flight_altitude: Number(flightInfo.flightAltitude),
            flight_speed: Number(flightInfo.flightSpeed),
            flight_duration: Number(flightInfo.flightDuration),
            patrol_area_size: Number(flightInfo.patrolAreaSize),
            rain: flightInfo.rain,
            wind: flightInfo.wind,
            visibility: flightInfo.visibility,
            other: flightInfo.other,
        }
        console.log(data);

        if (!id || isNewFlight) {
            console.log('create');
            createFlightInfo(data);
        } else {
            console.log('update');
            updateFilghtInfo(data);
        }
    }

    const createFlightInfo = async (data) => {
        try {
            const res = await flightInfoServices.createFlightInfo(data);
            console.log(res);
            if (res.status === 201) {
                setIsNewFlight(false);
                Swal.fire({
                    title: "บันทึกข้อมูลเที่ยวบินสำเร็จ",
                    icon: "success",
                });
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: createFlightInfo',
                status,
                data.message
            );
        }
    }

    const updateFilghtInfo = async (data) => {
        try {
            const res = await flightInfoServices.updateFilghtInfo(data);
            console.log(res);
            if (res.status === 201) {
                Swal.fire({
                    title: "อัปเดตข้อมูลเที่ยวบินสำเร็จ",
                    icon: "success",
                });
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: updateFilghtInfo',
                status,
                data.message
            );
        }
    }

    return (
        <form onSubmit={handleFlightInfoSubmit}>
            <Grid
                container item xs={12}
                alignItems="flex-start"
                rowGap={2}
                className="flight-info"
            >
                <Grid container item xs={6} display="flex" alignItems="center" gap={2}>
                    <Grid container item xs={12} gap={1}>
                        <GridHeader>
                            <Typography>ประเภทภารกิจ</Typography>
                        </GridHeader>
                        <GridForm>
                            <FormControl fullWidth size="small">
                                <Select
                                    required
                                    displayEmpty
                                    name="missionId"
                                    value={flightInfo.missionId}
                                    onChange={handleChange}
                                >
                                    {missionList.map(mission =>
                                        <MenuItem
                                            key={mission.id}
                                            value={mission.id}
                                            disabled={!mission.table_status}
                                        >
                                            {mission.mission_name}
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </GridForm>
                    </Grid>
                    <Grid container item xs={12} gap={1}>
                        <GridHeader>
                            <Typography>สถานะเที่ยวบิน</Typography>
                        </GridHeader>
                        <GridForm>
                            <FormControl fullWidth size="small">
                                <Select
                                    required
                                    displayEmpty
                                    name="flightStatus"
                                    value={flightInfo.flightStatus}
                                    onChange={handleChange}
                                >
                                    {flightStatusList.map(status =>
                                        <MenuItem
                                            key={status.label}
                                            value={status.value}
                                        >
                                            {status.label}
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </GridForm>
                    </Grid>
                    <Grid container item xs={12} gap={1}>
                        <GridHeader>
                            <Typography>อากาศยาน</Typography>
                        </GridHeader>
                        <GridForm>
                            <FormControl fullWidth size="small">
                                <Select
                                    required
                                    displayEmpty
                                    name="droneId"
                                    value={flightInfo.droneId}
                                    onChange={handleChange}
                                >
                                    {droneList.map(drone =>
                                        <MenuItem
                                            key={drone.id}
                                            value={drone.id}
                                        >
                                            {drone.drone_code}
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </GridForm>
                    </Grid>
                    <Grid container item xs={12} gap={1}>
                        <GridHeader>
                            <Typography>ทีมบิน</Typography>
                        </GridHeader>
                        <GridForm>
                            <FormControl fullWidth size="small">
                                <Select
                                    required
                                    displayEmpty
                                    name="flightTeam"
                                    value={flightInfo.flightTeam}
                                    onChange={handleChange}
                                >
                                    {flightTeamList.map(team =>
                                        <MenuItem
                                            key={team.id}
                                            value={team.id}
                                            disabled={!team.table_status}
                                        >
                                            {team.team_name}
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </GridForm>
                    </Grid>
                    <Grid container item xs={12} gap={1}>
                        <GridHeader>
                            <Typography>หัวหน้าทีมบิน</Typography>
                        </GridHeader>
                        <GridForm>
                            <FormControl fullWidth size="small">
                                <Select
                                    displayEmpty
                                    name="leaderId"
                                    value={flightInfo.leaderId}
                                    onChange={handleChange}
                                >
                                    {userList.map(user =>
                                        <MenuItem
                                            key={user.id}
                                            value={user.id}
                                            disabled={!user.table_status}
                                        >
                                            {`${user.first_name} ${user.last_name}`}
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </GridForm>
                    </Grid>
                    <Grid container item xs={12} gap={1}>
                        <GridHeader>
                            <Typography>สมาชิก</Typography>
                        </GridHeader>
                        <GridForm>
                            <FormControl fullWidth size="small">
                                <Select
                                    multiple
                                    name="members"
                                    value={flightInfo.members}
                                    onChange={handleChange}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((id) => {
                                                const currentUser = userList.find(user => user.id === id);

                                                if (!currentUser) return;

                                                return (
                                                    <Chip
                                                        key={currentUser.id}
                                                        label={`${currentUser.first_name} ${currentUser.last_name}`}
                                                    />
                                                )
                                            })}
                                        </Box>
                                    )}
                                >
                                    {userList.map(user =>
                                        <MenuItem
                                            key={user.id}
                                            value={user.id}
                                            disabled={!user.table_status}
                                        >
                                            <Checkbox checked={Boolean(flightInfo.members.find(id => id === user.id))} />
                                            <ListItemText primary={`${user.first_name} ${user.last_name}`} />
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </GridForm>
                    </Grid>
                    <Grid container item xs={12} gap={1}>
                        <GridHeader>
                            <Typography>หมายเหตุ</Typography>
                        </GridHeader>
                        <GridForm>
                            <TextField
                                fullWidth
                                name="note"
                                value={flightInfo.note}
                                onChange={handleChange}
                                size="small"
                                placeholder="ระบุหมายเหตุ"
                            />
                        </GridForm>
                    </Grid>
                </Grid>

                <Grid container item xs={6} display="flex" alignItems="center" gap={2}>
                    <Grid container item xs={12} gap={1}>
                        <GridHeader>
                            <Typography>ฐานบิน</Typography>
                        </GridHeader>
                        <GridForm>
                            <FormControl fullWidth size="small">
                                <Select
                                    required
                                    displayEmpty
                                    name="flyingBase"
                                    value={flightInfo.flyingBase}
                                    onChange={handleChange}
                                >
                                    {flyingBaseList.map(flyingBase =>
                                        <MenuItem
                                            key={flyingBase.id}
                                            value={flyingBase.id}
                                        >
                                            {flyingBase.base_code}
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </GridForm>
                    </Grid>
                    <Grid container item xs={12} gap={1}>
                        <GridHeader alignItems="flex-start">
                            <Typography>มุมมองแผนที่</Typography>
                        </GridHeader>
                        <GridForm>
                            <MapContainer
                                key={currentPosition}
                                center={currentPosition || thaiCenter}
                                zoom={10}
                                style={{ height: '200px' }}
                            >
                                <TileLayer {...openStreetMapConfig} />
                                {currentPosition && <Marker icon={customMarker} position={currentPosition} />}
                            </MapContainer>
                        </GridForm>
                    </Grid>
                </Grid>

                <Grid item xs={12} className="summary">
                    <Divider variant="fullWidth" sx={{ border: "4px solid var(--options-btn-bg-color-1)" }} />
                    <Typography className="summary-header">สรุปภาพรวมเที่ยวบิน</Typography>
                </Grid>

                <Grid container item xs={12} rowGap={3} mt={3}>
                    <Grid container item xs={12} paddingX={4}>
                        <Grid container item xs={4} gap={1}>
                            <GridHeader>
                                <Typography>วันที่ขึ้นบิน</Typography>
                            </GridHeader>
                            <GridForm>
                                <TextField
                                    fullWidth
                                    size="small"
                                    type="date"
                                    name="flightDate"
                                    value={flightInfo.flightDate}
                                    onChange={handleChange}
                                />
                            </GridForm>
                        </Grid>
                        <Grid container item xs={4} gap={1}>
                            <GridHeader>
                                <Typography>เวลาขึ้นบิน</Typography>
                            </GridHeader>
                            <GridForm>
                                <TextField
                                    fullWidth
                                    size="small"
                                    type="time"
                                    name="takeoffTime"
                                    value={flightInfo.takeoffTime}
                                    onChange={handleChange}
                                />
                            </GridForm>
                        </Grid>
                        <Grid container item xs={4} gap={1}>
                            <GridHeader>
                                <Typography>เวลาลงจอด</Typography>
                            </GridHeader>
                            <GridForm>
                                <TextField
                                    fullWidth
                                    size="small"
                                    type="time"
                                    name="landingTime"
                                    value={flightInfo.landingTime}
                                    onChange={handleChange}
                                />
                            </GridForm>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} columnGap={1} justifyContent="center">
                        <Grid container item xs={6.4} columns={13} rowGap={2} className="summary-form">
                            <Typography className="header">พารามิเตอร์การบิน</Typography>
                            <Grid container item xs={12} gap={1}>
                                <GridHeader>
                                    <Typography>ความสูงการบิน</Typography>
                                </GridHeader>
                                <GridForm>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        name="flightAltitude"
                                        value={flightInfo.flightAltitude}
                                        onChange={handleChange}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    เมตร
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </GridForm>
                            </Grid>
                            <Grid container item xs={12} gap={1}>
                                <GridHeader>
                                    <Typography>ความเร็วการบิน</Typography>
                                </GridHeader>
                                <GridForm>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        name="flightSpeed"
                                        value={flightInfo.flightSpeed}
                                        onChange={handleChange}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    เมตร/วินาที
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </GridForm>
                            </Grid>
                            <Grid container item xs={12} gap={1}>
                                <GridHeader>
                                    <Typography>ระยะเวลาการบิน</Typography>
                                </GridHeader>
                                <GridForm>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        name="flightDuration"
                                        value={flightInfo.flightDuration}
                                        onChange={handleChange}
                                    />
                                </GridForm>
                            </Grid>
                            <Grid container item xs={12} gap={1}>
                                <GridHeader>
                                    <Typography>ขนาดพื้นที่ลาดตระเวน</Typography>
                                </GridHeader>
                                <GridForm>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        name="patrolAreaSize"
                                        value={flightInfo.patrolAreaSize}
                                        onChange={handleChange}
                                    />
                                </GridForm>
                            </Grid>
                        </Grid>

                        <Grid container item xs={6.4} columns={13} rowGap={2} className="summary-form">
                            <Typography className="header">พารามิเตอร์การบิน</Typography>
                            <Grid container item xs={12} gap={1}>
                                <GridHeader>
                                    <Typography>ฝน</Typography>
                                </GridHeader>
                                <GridForm>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        name="rain"
                                        value={flightInfo.rain}
                                        onChange={handleChange}
                                    />
                                </GridForm>
                            </Grid>
                            <Grid container item xs={12} gap={1}>
                                <GridHeader>
                                    <Typography>ลม</Typography>
                                </GridHeader>
                                <GridForm>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        name="wind"
                                        value={flightInfo.wind}
                                        onChange={handleChange}
                                    />
                                </GridForm>
                            </Grid>
                            <Grid container item xs={12} gap={1}>
                                <GridHeader>
                                    <Typography>ทัศนวิสัย</Typography>
                                </GridHeader>
                                <GridForm>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        name="visibility"
                                        value={flightInfo.visibility}
                                        onChange={handleChange}
                                    />
                                </GridForm>
                            </Grid>
                            <Grid container item xs={12} gap={1}>
                                <GridHeader>
                                    <Typography>อื่นๆ</Typography>
                                </GridHeader>
                                <GridForm>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        name="other"
                                        value={flightInfo.other}
                                        onChange={handleChange}
                                    />
                                </GridForm>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <div className="btn">
                        <OptionButton
                            text="บันทึก"
                            type="submit"
                            bgColor="var(--options-btn-bg-color-5)"
                            hoverColor="var(--options-btn-bg-hover-5)"
                        />
                        <OptionButton
                            text="ยกเลิก"
                            bgColor="var(--options-btn-bg-color-2)"
                            hoverColor="var(--options-btn-bg-hover-2)"
                            textColor="gray"
                            onClick={goToDashboard}
                        />
                    </div>
                </Grid>
            </Grid>
        </form>
    )
}

export default FlightInfo

const GridHeader = ({ children, alignItems }) => {
    return (
        <Grid
            item xs={4}
            display="flex"
            alignItems={alignItems || "center"}
            justifyContent="flex-end"
            sx={{ "& *": { fontWeight: "700" } }}
        >
            {children}
        </Grid>
    )
}

const GridForm = ({ children }) => {
    return (
        <Grid item xs={7}>
            {children}
        </Grid>
    )
}