import { useAxios } from "../guard/axiosConfig"

export const surveyPositionServices = {
    createSurveyPosition,
    getAllSurveyPositionBySurveyAreaCode,
    getSurveyPositionResolveInfoBySurveyAreaCode,
}

const route = '/surveyPosition';

function createSurveyPosition(data) {
    return useAxios.post(`${route}/createSurveyPosition`, data);
}

function getAllSurveyPositionBySurveyAreaCode(code) {
    return useAxios.get(`${route}/getAllSurveyPositionBySurveyAreaCode/${code}`);
}

function getSurveyPositionResolveInfoBySurveyAreaCode(code) {
    return useAxios.get(`${route}/getSurveyPositionResolveInfoBySurveyAreaCode/${code}`);
}