import React, { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { Close, CloudUpload } from '@mui/icons-material';
import { Grid, IconButton, Typography } from '@mui/material';

import DragAndDrop from '../../../components/DragAndDrop/DragAndDrop';
import OptionButton from '../../../components/OptionButton/OptionButton';

import { surveyResultReportServices } from '../../../services/surveyResultReportServices';

import Swal from 'sweetalert2';

import useErrorHandler from '../../../hooks/useErrorHandler';

const reportFileTypes = { 'application/pdf': ['.ppt', '.xls', '.doc', '.pdf'] };
const imageFileTypes = { 'image/png': ['.jpeg', '.jpg', '.png'] };

function AdditionalInfo() {
    const { id } = useParams();
    const navigate = useNavigate();

    const { errorHandler } = useErrorHandler();

    const [surveyResultReport, setSurveyResultReport] = useState({
        reportFile: null,
        imageFile: null,
    });
    const [isFileChange, setIsFileChange] = useState({ reportFile: false, imageFile: false });
    const [isNewReport, setIsNewReport] = useState(false);

    useEffect(() => {
        const getResultReportBySurveyResultId = async () => {
            try {
                const res = await surveyResultReportServices.getResultReportBySurveyResultId(id);
                console.log('getResultReportBySurveyResultId => ', res);
                if (res.status === 200) {
                    const data = res.data.data;
                    if (data) {
                        setSurveyResultReport({
                            reportFile: data.report_file ? {
                                data: true,
                                originalname: data.report_file.originalname
                            } : null,
                            imageFile: data.image_file ? {
                                data: true,
                                originalname: data.image_file.originalname
                            } : null,
                        });
                    } else {
                        setIsNewReport(true);
                    }
                }
            } catch (error) {
                console.log(error);
                const { status, data } = error.response;
                errorHandler(
                    'error: getResultReportBySurveyResultId',
                    status,
                    data.message
                );
            }
        }

        id && getResultReportBySurveyResultId();
    }, [id]);

    const goToDashboard = () => navigate('/dashboard/survey-result');

    const handleFileChange = (acceptedFiles, fieldName) => {
        console.log(acceptedFiles);
        if (acceptedFiles.length === 0) return;

        setIsFileChange(prev => ({
            ...prev,
            [fieldName]: true,
        }));
        setSurveyResultReport(prev => ({
            ...prev,
            [fieldName]: {
                data: acceptedFiles[0],
                originalname: acceptedFiles[0].name,
            }
        }));
    };

    const handleDeleteFile = (fieldName) => {
        setIsFileChange(prev => ({
            ...prev,
            [fieldName]: true,
        }));
        setSurveyResultReport(prev => ({
            ...prev,
            [fieldName]: null
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            surveyResultReport: {
                surveyResultId: id,
                hasNewReportFile: isFileChange.reportFile,
                hasNewImageFile: isFileChange.imageFile,
            },
            reportFile: surveyResultReport.reportFile?.data,
            imageFile: surveyResultReport.imageFile?.data,
        }

        console.log(data);

        if (!id || isNewReport) {
            console.log('create');
            createSurveyResultReport(data);
        } else {
            console.log('update');
            updateSurveyResultReport(data);
        }
    }

    const createSurveyResultReport = async (data) => {
        try {
            const res = await surveyResultReportServices.createSurveyResultReport(data);
            console.log('createSurveyResultReport => ', res);
            if (res.status === 201) {
                setIsNewReport(false);
                Swal.fire({
                    title: "บันทึกข้อมูลสำเร็จ",
                    icon: "success",
                });
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: createSurveyResultReport',
                status,
                data.message
            );
        }
    }

    const updateSurveyResultReport = async (data) => {
        try {
            const res = await surveyResultReportServices.updateSurveyResultReport(data);
            console.log('updateSurveyResultReport => ', res);
            if (res.status === 201) {
                Swal.fire({
                    title: "อัปเดตข้อมูลสำเร็จ",
                    icon: "success",
                });
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: updateSurveyResultReport',
                status,
                data.message
            );
        }
    }

    return (
        <form id="additional-info" onSubmit={handleSubmit} style={{ paddingBlock: '18px' }}>
            <Grid container item xs={12} className="file-upload">
                <Grid item xs={6} px={3}>
                    <div className="header">
                        <CloudUpload className="icon" />
                        <Typography className="header-text">
                            อัปโหลดไฟล์รายงาน (1 ไฟล์)
                        </Typography>
                    </div>
                    <DragAndDrop
                        fileTypes={reportFileTypes}
                        maxFiles={1}
                        onDrop={(files) => handleFileChange(files, 'reportFile')}
                    >
                        {surveyResultReport.reportFile && (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography>{surveyResultReport.reportFile.originalname}</Typography>
                                <IconButton onClick={() => handleDeleteFile('reportFile')}>
                                    <Close />
                                </IconButton>
                            </div>
                        )}
                    </DragAndDrop>
                </Grid>
                <Grid item xs={6} px={3}>
                    <div className="header">
                        <CloudUpload className="icon" />
                        <Typography className="header-text">
                            อัปโหลดรูปภาพประกอบ (1 ไฟล์)
                        </Typography>
                    </div>
                    <DragAndDrop
                        fileTypes={imageFileTypes}
                        maxFiles={1}
                        onDrop={(files) => handleFileChange(files, 'imageFile')}
                    >
                        {surveyResultReport.imageFile && (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography>{surveyResultReport.imageFile.originalname}</Typography>
                                <IconButton onClick={() => handleDeleteFile('imageFile')}>
                                    <Close />
                                </IconButton>
                            </div>
                        )}
                    </DragAndDrop>
                </Grid>
            </Grid>
            <Grid item xs={12} mt={2}>
                <div className="btn">
                    <OptionButton
                        text="บันทึก"
                        type="submit"
                        bgColor="var(--options-btn-bg-color-5)"
                        hoverColor="var(--options-btn-bg-hover-5)"
                    />
                    <OptionButton
                        text="ยกเลิก"
                        bgColor="var(--options-btn-bg-color-2)"
                        hoverColor="var(--options-btn-bg-hover-2)"
                        textColor="gray"
                        onClick={goToDashboard}
                    />
                </div>
            </Grid>
        </form>
    )
}

export default AdditionalInfo