import React, { useEffect, useState } from "react";

import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

import { userGroupServices } from "../../services/userGroupServices";

import { useNavigate, useParams } from "react-router-dom";
import OptionButton from "../../components/OptionButton/OptionButton";

import useErrorHandler from "../../hooks/useErrorHandler";

const roleList = {
  USER: {
    label: "ผู้ใช้งาน",
    value: "USER",
  },
  ADMIN: {
    label: "ผู้ดูแลระบบ",
    value: "ADMIN",
  },
  SUPER_ADMIN: {
    label: "SUPER_ADMIN",
    value: "SUPER_ADMIN",
  },
};

function ManageUserGroupForm() {
  const { errorHandler } = useErrorHandler();
  const [groupData, setGroupData] = useState({
    group_name: "",
    role: "",
  });

  const { id } = useParams();

  const navigate = useNavigate();

  const getGroupById = async (id) => {
    try {
      const res = await userGroupServices.getGroupById(id);
      // console.log('groupdata', res);
      if (res.status === 200) {
        setGroupData(res.data);
      }
      console.log('groupdata', res.data);
    } catch (error) {
      console.log(error);
      const { status, data } = error.response;
      errorHandler(
        'error: getGroupById',
        status,
        data.message,
        false,
        goToDashboard
      );
    }
  };

  useEffect(() => {
    id && getGroupById(id);
  }, [id]);

  const goToDashboard = () => navigate("/dashboard/manage-group");

  const handleDataChange = ({ target }) => {
    setGroupData((prev) => {
      return {
        ...prev,
        [target.name]: target.value,
      };
    });
    console.log('groupData.role:', groupData.role);
  };

  const createGroup = async (data) => {
    try {
      const res = await userGroupServices.createGroup(data);
      console.log(res);
      if (res.status === 201) {
        console.log("user created");
        goToDashboard();
      }
    } catch (error) {
      console.log(error);
      const { status, data } = error.response;
      errorHandler(
        'error: createGroup',
        status,
        data.message
      );
    }
  };

  const updateGroup = async (data) => {
    try {
      const res = await userGroupServices.updateGroup({ id, ...data });
      console.log(res);
      if (res.status === 200) {
        console.log("user updated");
        goToDashboard();
      }
    } catch (error) {
      console.log(error);
      const { status, data } = error.response;
      errorHandler(
        'error: updateGroup',
        status,
        data.message
      );
    }
  };

  const handleSaveClick = (e) => {
    e.preventDefault();
    const data = {
      ...groupData,
    };
    console.log(data);

    if (!id) {
      createGroup(data);
    } else {
      updateGroup(data);
    }
  };

  return (
    <div>
      <form onSubmit={handleSaveClick}>
        <Grid container direction="column" item xs={6} gap={2}>
          <Typography className="title">ชื่อกลุ่มผู้ใช้</Typography>
          <TextField
            name="group_name"
            value={groupData.group_name}
            onChange={handleDataChange}
            size="small"
          />

          <Typography className="title">สิทธิ์</Typography>

          <Grid item container>
            <RadioGroup
              value={groupData.role || ''}
              name="role"
              sx={{ display: "flex", flexDirection: "row" }}
              onChange={handleDataChange}
            >
              {Object.keys(roleList).map((role) => {
                const value = roleList[role].value;
                const label = roleList[role].label;

                return (
                  <FormControlLabel
                    key={role}
                    value={value}
                    control={<Radio />}
                    label={label}
                    disabled={value === "SUPER_ADMIN" || groupData.role === "SUPER_ADMIN"}
                  />
                );
              })}
            </RadioGroup>


          </Grid>

          <Grid item container justifyContent="flex-start" gap={2}>
            <Grid item>
              <OptionButton
                text="Save"
                type="submit"
                bgColor="var(--options-btn-bg-color-5)"
                hoverColor="var(--options-btn-bg-hover-5)"
              />
            </Grid>
            <Grid item>
              <OptionButton
                text="Cancel"
                bgColor="var(--options-btn-bg-color-2)"
                hoverColor="var(--options-btn-bg-hover-2)"
                textColor="gray"
                onClick={goToDashboard}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default ManageUserGroupForm;
