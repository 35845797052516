import React, { useState } from 'react';
import './KMZUploadForm.css';

import { Box, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';

import FileUploadButton from '../../../../../components/FileUploadButton/FileUploadButton';
import OptionButton from '../../../../../components/OptionButton/OptionButton';

import { useNavigate, useParams } from 'react-router-dom';

import { surveyResultImageServices } from '../../../../../services/surveyResultImageServices';

import useErrorHandler from '../../../../../hooks/useErrorHandler';
import useMapInfo from '../../../../../hooks/useMapInfo';

import Swal from 'sweetalert2';

const uploadTypeList = {
    single: {
        label: 'ทีละรายการ',
        value: 'single',
    },
    multiple: {
        label: 'หลายรายการ',
        value: 'multiple',
    }
}

const KMZUploadForm = () => {
    const [uploadType, setUploadType] = useState('single');

    const handleUploadTypeChange = ({ target }) => setUploadType(target.value);

    return (
        <Box className="kmz-upload-form">
            <Grid item xs={12}>
                <FormLabel
                    sx={{
                        fontWeight: 700,
                        color: 'var(--primary-text-color)',
                    }}
                >
                    ประเภทการอัปโหลด
                </FormLabel>
                <RadioGroup
                    row
                    name="uploadType"
                    value={uploadType}
                    onChange={handleUploadTypeChange}
                >
                    {Object.keys(uploadTypeList)
                        .map(type => {
                            const value = uploadTypeList[type].value;
                            const label = uploadTypeList[type].label;
                            return (
                                <FormControlLabel
                                    key={type}
                                    value={value}
                                    control={<Radio />}
                                    label={label}
                                    sx={{ color: 'var(--secondary-text-color)' }}
                                />
                            )
                        })
                    }
                </RadioGroup>
            </Grid>

            {uploadType === 'single' && <SingleUploadForm />}
            {uploadType === 'multiple' && <MultipleUploadForm />}

        </Box>
    )
}

export default KMZUploadForm

const MultipleUploadForm = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const { errorHandler } = useErrorHandler();
    const { setFileList } = useMapInfo();

    const [surveyResultImage, setSurveyResultImage] = useState({
        maps: null,
        images: null,
    });

    const goToDashboard = () => navigate('/dashboard/survey-result');

    const handleFileChange = (acceptedFiles, fieldName) => {
        console.log(acceptedFiles);

        setSurveyResultImage(prev => ({
            ...prev,
            [fieldName]: acceptedFiles
        }));
    };

    const clearForm = () => {
        setSurveyResultImage({
            maps: null,
            images: null,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            surveyResultImage: {
                surveyResultId: id,
            },
            mapFile: surveyResultImage.maps?.data,
            imageFile: surveyResultImage.images?.data,
        }
        console.log(data);

        Swal.fire({
            title: `กำลังบันทึกไฟล์`,
            allowOutsideClick: false,
            didOpen: async () => {
                Swal.showLoading();
                await createSurveyResultKmzAndImageZipFile(data);
            }
        });
    }

    const createSurveyResultKmzAndImageZipFile = async (data) => {
        try {
            const res = await surveyResultImageServices.createSurveyResultKmzAndImageZipFile(data);
            console.log('createSurveyResultKmzAndImageZipFile => ', res);
            if (res.status === 201) {
                const resData = res.data;

                Swal.fire({
                    title: "บันทึกไฟล์สำเร็จ",
                    icon: "success",
                }).then((result) => {
                    if (result.isConfirmed) {
                        setFileList(prev => ([...prev, ...resData]));
                        clearForm();
                    }
                });
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: createSurveyResultKmzAndImageZipFile',
                status,
                data.message
            );
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <Typography color='red'>*หมายเหตุ ชื่อไฟล์รูปต้องตรงกับชื่อไฟล์ KML KMZ เช่น rayong15.jpg และ rayong15.kml</Typography>
            <Grid container item xs={12} rowGap={1} className="file-upload">
                <Grid item xs={12}>
                    <FileUploadButton
                        key={surveyResultImage.maps}
                        fileTypeText="ZIP (.KML .KMZ)"
                        mimeType="application/zip"
                        file={surveyResultImage.maps}
                        setFile={(file) => handleFileChange(file, 'maps')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FileUploadButton
                        key={surveyResultImage.images}
                        fileTypeText="ZIP (รูปภาพ)"
                        mimeType="application/zip"
                        file={surveyResultImage.images}
                        setFile={(file) => handleFileChange(file, 'images')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <div className="btn">
                        <OptionButton
                            text="บันทึก"
                            disabled={!(surveyResultImage.maps && surveyResultImage.images)}
                            type="submit"
                            bgColor="var(--options-btn-bg-color-5)"
                            hoverColor="var(--options-btn-bg-hover-5)"
                        />
                        <OptionButton
                            text="ยกเลิก"
                            bgColor="var(--options-btn-bg-color-2)"
                            hoverColor="var(--options-btn-bg-hover-2)"
                            textColor="gray"
                            onClick={goToDashboard}
                        />
                    </div>
                </Grid>
            </Grid>
        </form>
    )
}

const SingleUploadForm = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const { errorHandler } = useErrorHandler();
    const { setFileList } = useMapInfo();

    const [surveyResultImage, setSurveyResultImage] = useState({
        map: null,
        image: null,
    });

    const goToDashboard = () => navigate('/dashboard/survey-result');

    const handleFileChange = (acceptedFiles, fieldName) => {
        console.log(acceptedFiles);
        setSurveyResultImage(prev => ({
            ...prev,
            [fieldName]: acceptedFiles
        }));
    };

    const clearForm = () => {
        setSurveyResultImage({
            map: null,
            image: null,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            surveyResultImage: {
                surveyResultId: id,
            },
            mapFile: surveyResultImage.map?.data,
            imageFile: surveyResultImage.image?.data,
        }
        console.log(data);

        Swal.fire({
            title: `กำลังบันทึกไฟล์`,
            allowOutsideClick: false,
            didOpen: async () => {
                Swal.showLoading();
                await createSurveyResultKmzWithImageFile(data);
            }
        });
    }

    const createSurveyResultKmzWithImageFile = async (data) => {
        try {
            const res = await surveyResultImageServices.createSurveyResultKmzWithImageFile(data);
            console.log('createSurveyResultKmzWithImageFile => ', res);
            if (res.status === 201) {
                const resData = res.data;
                Swal.fire({
                    title: "บันทึกไฟล์สำเร็จ",
                    icon: "success",
                }).then((result) => {
                    if (result.isConfirmed) {
                        setFileList(prev => ([...prev, { ...resData }]));
                        clearForm();
                    }
                });
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: createSurveyResultKmzWithImageFile',
                status,
                data.message
            );
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid container item xs={12} rowGap={1} className="file-upload">
                <Grid item xs={12}>
                    <FileUploadButton
                        key={surveyResultImage.map}
                        fileTypeText="(.KML .KMZ)"
                        mimeType=".kml, .kmz"
                        file={surveyResultImage.map}
                        setFile={(file) => handleFileChange(file, 'map')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FileUploadButton
                        key={surveyResultImage.image}
                        fileTypeText="(รูปภาพ)"
                        mimeType=".png, .jpg, .jpeg"
                        file={surveyResultImage.image}
                        setFile={(file) => handleFileChange(file, 'image')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <div className="btn">
                        <OptionButton
                            text="บันทึก"
                            disabled={!surveyResultImage.map}
                            type="submit"
                            bgColor="var(--options-btn-bg-color-5)"
                            hoverColor="var(--options-btn-bg-hover-5)"
                        />
                        <OptionButton
                            text="ยกเลิก"
                            bgColor="var(--options-btn-bg-color-2)"
                            hoverColor="var(--options-btn-bg-hover-2)"
                            textColor="gray"
                            onClick={goToDashboard}
                        />
                    </div>
                </Grid>
            </Grid>
        </form>
    )
}