import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import { userServices } from "../../services/userServices";

import "./Login.css";

import {
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
  Grid,
  Button,
  Divider,
  IconButton,
} from "@mui/material";

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import useAuth from "../../hooks/useAuth";

import { errorHandler } from "../../utils";

function Login() {
  const navigate = useNavigate();

  const { userID, setUserID } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { email, password };
    login(data);
  };

  const login = async (data) => {
    try {
      const res = await userServices.login(data);
      if (res.status === 200) {
        setUserID(res.data.data);
        navigate("/calendar");
      }
    } catch (error) {
      console.log("error", error);
      const { status, data } = error.response;
      errorHandler(
        'error: login',
        status,
        data.message
      );
    }
  }

  const loginGuest = () => login({ email: "GUEST" });

  if (userID) return <Navigate to="/" replace />

  return (
    <div
      className="login-page-wrapper"
      style={{ backgroundImage: `url(/bg-login.png)` }}
    >
      <form onSubmit={handleSubmit} className="login-page">
        <div className="login-form">
          <div className="logo-images">
            <img
              src="logo-default.png"
              style={{ width: "300px" }}
              alt=""
            />
          </div>
          <Divider
            flexItem
            orientation="horizontal"
            variant="middle"
            sx={{ backgroundColor: "#666666", marginTop: '16px' }}
          />
          <div
            className="title-section"
            style={{ display: "flex", alignItems: "center" }}
          >
            <img
              src="drone-logo.png"
              style={{
                width: "200px",
                aspectRatio: "3/2",
                objectFit: "contain",
              }}
              alt=""
            />
            <div className="title-label">
              <Typography
                sx={{
                  color: "#FFFFFF",
                  textAlign: "start",
                  fontSize: '1.2rem'
                }}
              >
                ระบบจัดเก็บข้อมูล
              </Typography>
              <Typography sx={{ textAlign: "center", color: "#FFFFFF", fontSize: '1.2rem' }}>
                และการแสดงผลข้อมูล
              </Typography>
            </div>
          </div>

          <Grid
            container
            className="form-section"
            sx={{
              flexDirection: "column",
              color: "#FFFFFF",
            }}
          >
            <Grid item xs={12}>
              <Typography variant="h3">เข้าสู่ระบบ</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                placeholder="เลขประจำตัวประชาชน"
                variant="outlined"
                fullWidth
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ marginBottom: "20px", marginTop: "20px" }}
              />
              <TextField
                placeholder="รหัสผ่าน"
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                fullWidth
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={handleTogglePassword} edge="end" style={{ marginRight: '8px' }}>
                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  ),
                }}
              />
              <div className="option">
                <FormControlLabel
                  control={
                    <Checkbox sx={{ color: "#FFFFFF" }} defaultChecked />
                  }
                  label="จดจำฉัน"
                />
                <Button size="small" sx={{ marginLeft: 'auto', color: 'white' }}>ลืมรหัสผ่าน</Button>
              </div>
              <Button
                variant="contained"
                type="submit"
                fullWidth
                style={{ background: "#fa4137", height: '45px', fontSize: '1.2rem' }}
              >
                เข้าสู่ระบบ
              </Button>
              <Button
                variant="contained"
                // type="submit"
                fullWidth
                onClick={loginGuest}
                style={{ background: "#004aad", marginTop: '20px', height: '45px', fontSize: '1.2rem' }}
              >
                บุคคลทั่วไป
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>
    </div>
  );
}

export default Login;
