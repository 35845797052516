import { useAxios } from "../guard/axiosConfig"

export const areaBoundaryServices = {
    getAllAreaBoundary,
    getAllAreaBoundaryForMap,
    getAreaBoundaryById,
    createAreaBoundary,
    updateAreaBoundary,
    updateAreaBoundaryStatus,
    deleteAreaBoundaryById,
}

const route = '/areaBoundary';

function getAllAreaBoundary(filters) {
    return useAxios.get(`${route}/getAllAreaBoundary`, { params: filters });
}

function getAllAreaBoundaryForMap(filters) {
    return useAxios.get(`${route}/getAllAreaBoundaryForMap`, { params: filters });
}

function getAreaBoundaryById(id) {
    return useAxios.get(`${route}/getAreaBoundaryById/${id}`);
}

function createAreaBoundary(data) {
    const formData = new FormData();
    formData.append('data', JSON.stringify({ ...data }));
    formData.append('file', data.file);

    return useAxios.post(`${route}/createAreaBoundary`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}

function updateAreaBoundary(data) {
    const formData = new FormData();
    formData.append('data', JSON.stringify({ ...data }));
    formData.append('file', data.file);

    return useAxios.put(`${route}/updateAreaBoundary`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}

function updateAreaBoundaryStatus(data) {
    return useAxios.put(`${route}/updateAreaBoundaryStatus`, data);
}

function deleteAreaBoundaryById(id) {
    return useAxios.delete(`${route}/deleteAreaBoundaryById/${id}`);
}