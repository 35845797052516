import React, { useLayoutEffect, useState } from 'react'

import { useSearchParams } from 'react-router-dom';

import { Box, Tab, Tabs } from '@mui/material';

import ManageDroneSize from './ManageDroneSize/ManageDroneSize'
import ManageDroneType from './ManageDroneType/ManageDroneType';
import ManageTargetType from './ManageTargetType/ManageTargetType';
import ManageMission from './ManageMission/ManageMission';
import ManageFlightImage from './ManageFlightImage/ManageFlightImage';
import ManageCategory from './ManageCategory/ManageCategory';

const overviewTabs = [
    {
        element: <ManageTargetType />,
        label: "ประเภทพื้นที่เป้าหมาย",
    },
    {
        element: <ManageDroneType />,
        label: "ประเภทอากาศยาน",
    },
    {
        element: <ManageDroneSize />,
        label: "ขนาดอากาศยาน",
    },
    {
        element: <ManageMission />,
        label: "ประเภทภารกิจ",
    },
    {
        element: <ManageFlightImage />,
        label: "ภาพถ่ายทางอากาศ",
    },
    {
        element: <ManageCategory />,
        label: "หมวดหมู่",
    },
]

function Overview() {
    const [searchParams, setSearchParams] = useSearchParams();
    
    const [tabIndex, setTabIndex] = useState(0);

    useLayoutEffect(() => {
        if (searchParams.has("tab")) {
            const currentTabIndex = overviewTabs.findIndex(tab => tab.label === searchParams.get("tab"));
            setTabIndex(currentTabIndex);
        }
    }, [searchParams])

    const handleTabChange = (event, newValue) => setTabIndex(newValue);
    
    const handleTabClick = (tabName) => {
        setSearchParams(prev => {
            prev.set("tab", tabName);
            prev.set("page", 1);
            return prev;
        });
    }

    return (
        <div>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs 
                        variant="scrollable"
                        scrollButtons="auto"
                        value={tabIndex} 
                        onChange={handleTabChange}
                    >
                        {overviewTabs.map(route => (
                            <Tab 
                                key={route.label} 
                                label={route.label} 
                                sx={{ fontWeight: 700 }} 
                                onClick={() => handleTabClick(route.label)} 
                            />
                        ))}
                    </Tabs>
                </Box>

                {overviewTabs.map((route, index) => (
                    <TabPanel key={route.label} tabIndex={tabIndex} index={index}>
                        {route.element}
                    </TabPanel>
                ))}
            </Box>
        </div>
    )
}

export default Overview

function TabPanel(props) {
    const { children, tabIndex, index } = props;
  
    return tabIndex === index && (
        <Box sx={{ p: 3 }}>
            {children}
        </Box>
    );
  }