import React, { useEffect, useState } from 'react'
import './ManageAircraftTeamForm.css'

import { 
    Box, Checkbox, Chip, FormControl, Grid, 
    InputLabel, ListItemText, MenuItem, Select, TextField 
} from '@mui/material'

import { useNavigate, useParams } from 'react-router';

import { flightTeamServices } from '../../services/flightTeamServices';

import OptionButton from '../../components/OptionButton/OptionButton';

import useUser from '../../hooks/useUser';
import useErrorHandler from '../../hooks/useErrorHandler';

function ManageAircraftTeamForm() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [team, setTeam] = useState({
        teamName: '',
        leaderId: '',
        members: [],
    });

    const { errorHandler } = useErrorHandler();
    const { userList } = useUser();

    useEffect(() => {
        const getFlightTeamById = async () => {
            try {
                const res = await flightTeamServices.getFlightTeamById(id);
                console.log(res);
                if (res.status === 200) {
                    const data = res.data.data;
                    setTeam({
                        teamName: data.team_name,
                        leaderId: data.LeaderId,
                        members: data.members.map(member => member.id),
                    });
                }
            } catch (error) {
                console.log(error);
                const { status, data } = error.response;
                errorHandler(
                    'error: getFlightTeamById',
                    status,
                    data.message,
                    false,
                    goToDashboard
                );
            }
        }

        id && getFlightTeamById();
    }, [id]);

    const handleTeamChange = ({ target }) => {
        setTeam(prev => {
            return {
                ...prev,
                [target.name]: target.value,
            }
        });
    }

    const handleSaveClick = (e) => {
        e.preventDefault();
        const data = {
            team_name: team.teamName,
            leaderId: team.leaderId,
            members: team.members,
        }
        console.log(data);

        if (!id) {
            handleCreateFlightTeam(data);
        } else {
            handleUpdateFlightTeam(data);
        }
    }

    const goToDashboard = () => navigate('/dashboard/manage-aircraft-team');

    const handleCreateFlightTeam = async (data) => {
        try {
            const res = await flightTeamServices.createFlightTeam(data);
            console.log(res);
            if (res.status === 201) {
                console.log('create success');
                goToDashboard();
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: createFlightTeam',
                status,
                data.message
            );
        }
    }
    
    const handleUpdateFlightTeam = async (data) => {
        try {
            const res = await flightTeamServices.updateFlightTeamById(id, data);
            console.log(res);
            if (res.status === 201) {
                console.log('update success');
                goToDashboard();
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: updateFlightTeamById',
                status,
                data.message
            );
        }
    }

    return (
        <form onSubmit={handleSaveClick}>
            <Grid container className="manage-aircraft-team-form">
                <Grid container item xs={6} gap={2}>
                    <GridForm>
                        <TextField 
                            fullWidth 
                            required
                            placeholder="ชื่อทีมบิน" 
                            name="teamName"
                            value={team.teamName}
                            onChange={handleTeamChange}
                        />
                    </GridForm>
                    <GridForm>
                        <div style={{ display: 'flex' }}>
                            <FormControl fullWidth>
                                <InputLabel id="leader-name">หัวหน้าทีมบิน</InputLabel>
                                <Select 
                                    required
                                    displayEmpty
                                    labelId="leader-name" 
                                    label="หัวหน้าทีมบิน"
                                    name="leaderId"
                                    value={team.leaderId}
                                    onChange={handleTeamChange}
                                >
                                    {userList.map(user => 
                                        <MenuItem 
                                            key={user.id} 
                                            value={user.id} 
                                            disabled={!user.table_status}
                                        >
                                            {`${user.first_name} ${user.last_name}`}
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </div>
                    </GridForm>
                    <GridForm>
                        <div style={{ display: 'flex' }}>
                            <FormControl fullWidth>
                                <InputLabel id="team-member">สมาชิกทีมบิน</InputLabel>
                                <Select 
                                    required
                                    multiple
                                    labelId="team-member" 
                                    label="สมาชิกทีมบิน"
                                    name="members"
                                    value={team.members}
                                    onChange={handleTeamChange}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((id) => {
                                                const currentUser = userList.find(user => user.id === id);
                                                
                                                if (!currentUser) return;

                                                return (
                                                    <Chip 
                                                        key={currentUser.id} 
                                                        label={`${currentUser.first_name} ${currentUser.last_name}`}
                                                    />
                                                )
                                            })}
                                        </Box>
                                    )}
                                >
                                    {userList.map(user => 
                                        <MenuItem 
                                            key={user.id} 
                                            value={user.id} 
                                            disabled={!user.table_status}
                                        >
                                            <Checkbox checked={Boolean(team.members.find(id => id === user.id))} />
                                            <ListItemText primary={`${user.first_name} ${user.last_name}`} />
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </div>
                    </GridForm>
                    <Grid item xs={6}>
                        <div className="btn">
                            <OptionButton 
                                text="Save"
                                type="submit"
                                bgColor="var(--options-btn-bg-color-5)"
                                hoverColor="var(--options-btn-bg-hover-5)"
                            />
                            <OptionButton 
                                text="Cancel"
                                bgColor="var(--options-btn-bg-color-2)"
                                hoverColor="var(--options-btn-bg-hover-2)"
                                textColor="gray"
                                onClick={goToDashboard}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}

export default ManageAircraftTeamForm

const GridForm = ({ children }) => {
    return (
        <Grid item xs={10} sx={{ bgcolor: '#FAFAFA', borderRadius: 3, p: 3 }}>
            {children}
        </Grid>
    )
}