import { useAxios } from "../guard/axiosConfig"

export const kmlServices = {
    getKmlGeoJson,
    getKmlFile,
}

const route = '/kml';

function getKmlGeoJson(file) {
    const formData = new FormData();
    formData.append('file', file);

    return useAxios.post(`${route}/getKmlGeoJson`, formData, {
        headers: { 
            'Content-Type': 'multipart/form-data',
            'Cache-Control': 'max-age=3600',
        }
    });
}

function getKmlFile(filename) {
    return useAxios.get(`${route}/getKmlFile/${filename}`, {
        responseType: 'blob'
    });
}