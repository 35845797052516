import { useAxios } from "../guard/axiosConfig"

export const userServices = {
    createUser,
    getAllUser,
    getUserById,
    updateUser,
    deleteUserById,
    login,
    loginForMap,
    logout,
    updateStatusById,
    changePassword
}

const route = '/user';

// function createUser(data) {
//     return useAxios.post(`${route}/createUser`, data);
// }

function createUser(data) {
    const formData = new FormData();
    formData.append('data', JSON.stringify({ ...data }));

    const photo = data.photo;
    formData.append('photos', photo);
    console.log('datas', photo);

    return useAxios.post(`${route}/createUser`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}

function getAllUser(filters) {
    return useAxios.get(`${route}/getAllUser`, { params: filters });
}

function getUserById(id) {
    return useAxios.get(`${route}/getUserById/${id}`);
}

function updateUser(data) {
    const formData = new FormData();
    formData.append('data', JSON.stringify({ ...data }));

    const photo = data.photo;
    formData.append('photos', photo);
    console.log('datas', photo);

    return useAxios.post(`${route}/updateUser`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}

function deleteUserById(id) {
    return useAxios.delete(`${route}/deleteUserById/${id}`);
}

function login(data) {
    return useAxios.post(`${route}/login`, data);
}

function loginForMap(data) {
    return useAxios.post(`${route}/loginForMap`, data);
}

function logout(id) {
    return useAxios.get(`${route}/logout/${id}`);
}

function updateStatusById(data) {
    return useAxios.patch(`${route}/updateStatusById/${data.id}`);
}

function changePassword(data) {
    return useAxios.post(`${route}/changePassword`, data);
}