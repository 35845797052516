import React, { useState } from "react";
import "./Profile.css";

import { Avatar, Typography, Button } from "@mui/material";

import useAuth from "../../../hooks/useAuth";

import EditProfilePanel from "./EditProfilePanel";
import EditPasswordPanel from "./ChangePasswordPanel";

function Profile() {
  const { userID, userData, logout } = useAuth();

  const [editProfileOpen, setEditProfileOpen] = useState(false);

  const [editPasswordOpen, setEditPasswordOpen] = useState(false);


  const handleEditProfileClick = () => {
    setEditProfileOpen(true);
  };

  const handleCloseEditProfile = () => {
    setEditProfileOpen(false);
  };

  const handleEditPasswordClick = () => {
    setEditPasswordOpen(true);
  };

  const handleCloseEditPassword = () => {
    setEditPasswordOpen(false);
  };

  return (
    <div className="Profile">
      <div className="Profile-box">
        {userData && (
          <Avatar
            alt="Profile Picture"
            src={userData.photo?.imagePreview}
            sx={{
              width: "20%",
              height: "auto",
              aspectRatio: "1/1",
              border: "2px solid gray",
              borderRadius: "50%",
              marginTop: "auto",
              marginBottom: "auto",
              marginLeft: "5%",
              marginRight: "5%",
            }}
          />
        )}
        {/* </div> */}

        {userData && (
          <div className="user-info" style={{ color: "#707070 " }}>
            <Typography sx={{ lineHeight: "2", fontSize: "1vw" }}>
              ข้อมูลผู้ใช้งาน
            </Typography>
            <Typography sx={{ lineHeight: "2", fontSize: "1.5vw" }}>
              เลขประจำตัวประชาชน: {userData.username}
            </Typography>
            <Typography sx={{ fontSize: "1.5vw" }}>
              กลุ่มผู้ใช้งาน: {userData.group.group_name}
            </Typography>
            <Typography sx={{ fontSize: "1.5vw" }}>
              ชื่อ-นามสกุล: {`${userData.first_name} ${userData.last_name}`}
            </Typography>
            <Typography sx={{ fontSize: "1.5vw" }}>
              สำนักงาน: {userData.office_unit}
            </Typography>
            <Typography sx={{ fontSize: "1.5vw" }}>
              หน่วยงาน: {userData.institute}
            </Typography>
            <Typography sx={{ fontSize: "1.5vw" }}>
              ตำแหน่ง: {userData.position}
            </Typography>
            <Typography sx={{ fontSize: "1.5vw" }}>
              E-mail: {userData.email}
            </Typography>
            <Typography sx={{ fontSize: "1.5vw" }}>
              เบอร์ติดต่อ: {userData.tel_info}
            </Typography>
          </div>
        )}

        <div className="button-section">
          <Button
            variant="contained"
            fullWidth
            sx={{
              width: "100%",
              borderRadius: "20px",
              background: "#004aad",
              height: "100%",
            }}
            onClick={handleEditProfileClick}
          >
            แก้ไขข้อมูล
          </Button>
          <Button
            variant="contained"
            fullWidth
            sx={{
              width: "100%",
              borderRadius: "20px",
              background: "#004aad",
              height: "100%",
              backgroundColor: "#fa4137",
              "&:hover": {
                backgroundColor: "#ff5a4e",
              },
              "&:active": {
                backgroundColor: "#e6362d",
              },
            }}
            onClick={handleEditPasswordClick}
          >
            เปลี่ยนรหัสผ่าน
          </Button>
          <Button
            variant="contained"
            fullWidth
            sx={{
              width: "100%",
              borderRadius: "20px",
              background: "#004aad",
              height: "100%",
            }}
            onClick={() => logout(userID)}
          >
            ออกจากระบบ
          </Button>
        </div>
      </div>
      {editProfileOpen && (
        <EditProfilePanel callback={handleCloseEditProfile} />
      )}

       {editPasswordOpen && (
        <EditPasswordPanel callback={handleCloseEditPassword}/>
      )}
    </div>
  );
}

export default Profile;
