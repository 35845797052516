import React, { useEffect, useState, useRef } from 'react'
import './ManageCategory.css'

import { useSearchParams } from 'react-router-dom';

import { Box, FormControlLabel, FormLabel, Grid, InputAdornment, Modal, Radio, RadioGroup, TextField, Typography } from '@mui/material';

import DataTable from '../../../components/DataTable/DataTable';
import DeleteButton from '../../../components/TableButton/DeleteButton';
import StatusButton from '../../../components/TableButton/StatusButton';
import OptionButton from '../../../components/OptionButton/OptionButton';
import OverviewEditButton from '../../../components/OverviewEditButton/OverviewEditButton';
import ExcelButton from '../../../components/ExcelButton/ExcelButton';

import { categoryServices } from '../../../services/categoryServices';

import useCategory from '../../../hooks/useCategory';
import useDataTable from '../../../hooks/useDataTable';
import useErrorHandler from '../../../hooks/useErrorHandler';

const columns = [
    {
        field: 'row_num',
        headerName: 'ลำดับ',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        flex: 0.4,
    },
    {
        field: 'category_name',
        headerName: 'หมวดหมู่',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        flex: 1,
    },
    {
        field: 'table_status',
        headerName: 'สถานะ',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        flex: 0.3,
        renderCell: (param) => <StatusButton data={param.row} service={categoryServices.updateStatus} />
    },
    {
        field: 'edit',
        headerName: 'แก้ไข',
        headerAlign: 'center',
        type: 'actions',
        sortable: false,
        flex: 0.3,
        renderCell: (param) => (
            <OverviewEditButton
                id={param.row.id}
                modalTitle={modalTitle}
                fieldName="category_name"
                findById={categoryServices.getCategoryById}
                service={categoryServices.updateCategory}
            />
        )
    },
    {
        field: 'delete',
        type: 'actions',
        sortable: false,
        flex: 0.3,
        renderCell: (param) => <DeleteButton id={param.row.id} service={categoryServices.deleteCategoryById} />
    },
];

const initFilters = {
    search: '',
    dateFrom: '',
    dateTo: '',
}

const modalTitle = 'หมวดหมู่';

const areaTypeList = [
    { text: 'ชายฝั่งทะเล', value: 'sea-coast' },
    { text: 'ป่าชายเลน', value: 'mangrove-forest' },
]

function ManageCategory() {
    const { paginationModel, resetPage } = useDataTable();

    const [filters, setFilters] = useState(initFilters);
    const [paginationfilters, setPaginationFilters] = useState(initFilters);

    const [openModal, setOpenModal] = useState(false);

    const { categoryList: rows, rowCount, getAllCategory } = useCategory(
        { ...paginationfilters, ...paginationModel },
        [paginationModel.page, paginationfilters]
    );

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const handleFilterChange = ({ target }) => {
        const { name, value } = target;
        console.log(value);

        setFilters(prev => {
            return {
                ...prev,
                [name]: value,
            }
        });
    }

    const handleSearchClick = () => {
        console.log(filters);
        setPaginationFilters({ ...filters });
        resetPage();
    }

    const handleClearClick = () => {
        setFilters({ ...initFilters });
        setPaginationFilters({ ...initFilters });
        resetPage();
    }

    const handleAddClick = async () => {
        await getAllCategory({ ...paginationfilters, ...paginationModel });
        handleOpenModal();
    }

    return (
        <div className="manage-category">
            <Grid container className="filters" gap={1}>
                <Grid container item md={12} display="flex" alignItems="center">
                    <Grid item md={1}>
                        <Typography>ค้นหา</Typography>
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            fullWidth
                            size="small"
                            name="search"
                            value={filters.search}
                            onChange={handleFilterChange}
                        />
                    </Grid>
                </Grid>
                <Grid container item md={12} display="flex" alignItems="center">
                    <Grid item md={1}>
                        <Typography>วันที่เพิ่ม</Typography>
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            fullWidth
                            type="date"
                            size="small"
                            name="dateFrom"
                            value={filters.dateFrom}
                            onChange={handleFilterChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Box>From</Box>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            fullWidth
                            type="date"
                            size="small"
                            name="dateTo"
                            value={filters.dateTo}
                            onChange={handleFilterChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Box>To</Box>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid container className="options">
                <Grid item md={1}></Grid>
                <Grid
                    item xs={12} md={6}
                    sx={{
                        '& button': { width: '25%' },
                        '& :not(:nth-of-type(1))': { ml: 2 }
                    }}
                >
                    <OptionButton
                        text='ค้นหา'
                        bgColor='var(--options-btn-bg-color-1)'
                        hoverColor='var(--options-btn-bg-hover-1)'
                        onClick={handleSearchClick}
                    />
                    <OptionButton
                        text='ล้าง'
                        bgColor='var(--options-btn-bg-color-2)'
                        hoverColor='var(--options-btn-bg-hover-2)'
                        textColor='gray'
                        onClick={handleClearClick}
                    />
                    <OptionButton
                        text='เพิ่ม'
                        bgColor='var(--options-btn-bg-color-1)'
                        hoverColor='var(--options-btn-bg-hover-1)'
                        onClick={handleAddClick}
                    />
                </Grid>

                <ExcelButton modelName="Category" importCallback={handleClearClick} />
            </Grid>

            <DataTable
                columns={columns}
                rows={rows}
                rowCount={rowCount}
                pageProps={paginationModel}
            />

            {openModal && (
                <FormModal
                    openModal={openModal}
                    onClose={handleCloseModal}
                    fieldName="category_name"
                    service={categoryServices.createCategory}
                    onDataChange={() => getAllCategory({ ...paginationfilters, ...paginationModel })}
                />
            )}
        </div>
    )
}

export default ManageCategory

const FormModal = ({ findById, service, fieldName, openModal, onClose, onDataChange }) => {
    const inputRef = useRef();
    const [searchParams, setSearchParams] = useSearchParams();
    const [areaType, setAreaType] = useState('sea-coast');

    const { errorHandler } = useErrorHandler();

    useEffect(() => {
        const getDataById = async () => {
            try {
                const res = await findById(searchParams.get("id"));
                console.log(res);
                if (res.status === 200) {
                    inputRef.current.value = res.data.data[fieldName];
                    setAreaType(res.data.data.area_type);
                }
            } catch (error) {
                console.log(error);
                const { status, data } = error.response;
                errorHandler(
                    `error: ${findById.name}`,
                    status,
                    data.message,
                    false,
                    handleCloseModal
                );
            }
        }

        if (searchParams.has("id")) {
            searchParams.get("id") && getDataById();
        }
    }, [searchParams]);

    const handleCloseModal = () => {
        const currentParams = Object.fromEntries([...searchParams]);
        delete currentParams.id
        setSearchParams({ ...currentParams });
        onClose();
    }

    const handleAreaTypeChange = ({ target }) => setAreaType(target.value);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            area_type: areaType,
            [fieldName]: inputRef.current.value,
        }
        console.log(data);

        if (searchParams.has("id")) {
            data.id = searchParams.get("id");
        }

        try {
            const res = await service(data);
            console.log(res);
            if (res.status === 201) {
                console.log('created/updated');
                onDataChange(res.data.data);
                handleCloseModal();
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                `error: ${service.name}`,
                status,
                data.message
            );
        }
    }

    return (
        <Modal className="form-modal" open={openModal} onClose={handleCloseModal}>
            <Box className="content" sx={{ boxShadow: 24 }}>
                <Typography variant="h6" component="h2">
                    {modalTitle}
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        required
                        fullWidth
                        size="small"
                        inputRef={inputRef}
                    />
                    <div style={{ marginTop: '8px' }}>
                        <FormLabel
                            sx={{
                                fontWeight: 700,
                                color: 'var(--primary-text-color)',
                            }}
                        >
                            ประเภทพื้นที่
                        </FormLabel>
                        <RadioGroup
                            row
                            name="areaType"
                            value={areaType}
                            onChange={handleAreaTypeChange}
                        >
                            {areaTypeList.map(type => {
                                return (
                                    <FormControlLabel
                                        key={type.value}
                                        value={type.value}
                                        control={<Radio />}
                                        label={type.text}
                                        sx={{ color: 'var(--secondary-text-color)' }}
                                    />
                                )
                            })}
                        </RadioGroup>
                    </div>
                    <div className="button">
                        <OptionButton
                            text='Cancel'
                            bgColor='var(--options-btn-bg-color-2)'
                            hoverColor='var(--options-btn-bg-hover-2)'
                            textColor="gray"
                            onClick={handleCloseModal}
                        />
                        <OptionButton
                            text='Save'
                            type='submit'
                            bgColor='var(--options-btn-bg-color-5)'
                            hoverColor='var(--options-btn-bg-hover-5)'
                        />
                    </div>
                </form>
            </Box>
        </Modal>
    )
}