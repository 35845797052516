import { useAxios } from "../guard/axiosConfig";

export const flightTeamServices = {
    getAllFlightTeam,
    createFlightTeam,
    getFlightTeamById,
    updateFlightTeamById,
    updateStatus,
    deleteFlightTeamById,
}

const route = '/flightTeams';

function getAllFlightTeam(filters) {
    return useAxios.get(`${route}/getAllFlightTeam`, { params: filters });
}

function createFlightTeam(data) {
    return useAxios.post(`${route}/createFlightTeam`, data);
}

function getFlightTeamById(id) {
    return useAxios.get(`${route}/getFlightTeamById/${id}`);
}

function updateFlightTeamById(id, data) {
    return useAxios.put(`${route}/updateFlightTeamById/${id}`, data);
}

function updateStatus(data) {
    return useAxios.put(`${route}/updateStatus`, data);
}

function deleteFlightTeamById(id) {
    return useAxios.delete(`${route}/deleteFlightTeamById/${id}`);
}