import { useAxios } from "../guard/axiosConfig"

export const excelServices = {
    exportExcel,
    importExcel,
}

const route = '/excel';

function exportExcel(model) {
    return useAxios.get(`${route}/export/${model}`, {
        responseType: 'blob'
    });
}

function importExcel(data) {
    const formData = new FormData();
    formData.append('modelName', data.modelName);
    formData.append('sheetName', data.sheetName);
    formData.append('file', data.file);

    return useAxios.post(`${route}/import`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}