import React from 'react'

import { useGridApiContext } from '@mui/x-data-grid';

import { IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';

import useErrorHandler from '../../hooks/useErrorHandler';

import Swal from 'sweetalert2';

function DeleteButton({ service, id, keyName = 'id' }) {
    const { errorHandler } = useErrorHandler();

    const apiRef = useGridApiContext();

    const handleDeleteClick = async () => {
        Swal.fire({
            title: `คุณต้องการลบข้อมูลหรือไม่?`,
            text: 'เมื่อยืนยันแล้วจะไม่สามารถกู้คืนได้',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: 'var(--options-btn-bg-color-5)',
            confirmButtonText: 'ยืนยันการลบ',
            cancelButtonColor: 'var(--options-btn-bg-color-6)',
            cancelButtonText: 'ยกเลิกการลบ',
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: `กำลังลบข้อมูล`,
                    allowOutsideClick: false,
                    didOpen: async () => {
                        Swal.showLoading();
                        await deleteData();
                    }
                });
            }
        });
    };

    const deleteData = async () => {
        try {
            const res = await service(id);
            console.log(res);
            if (res.status === 200) {
                Swal.fire({
                    title: "ลบข้อมูลสำเร็จ",
                    icon: "success",
                }).then((result) => {
                    if (result.isConfirmed) {
                        removeDeletedRow();
                    }
                });
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                `error: ${service.name}`,
                status,
                data.message
            );
        }
    }

    const removeDeletedRow = () => apiRef.current.updateRows([{ [keyName]: id, _action: 'delete' }]);

    return (
        <IconButton onClick={handleDeleteClick}>
            <Delete />
        </IconButton>
    )
}

export default DeleteButton