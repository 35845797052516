import { useAxios } from '../guard/axiosConfig'

export const flightReportFileServices = {
    createFlightReportFile,
    updateFlightReportFile,
    deleteFlighReportFileById,
    getByFlightCode,
}

const route = '/flightReportFile';

function createFlightReportFile(data) {
    const formData = new FormData();
    formData.append('data', JSON.stringify({ ...data }));
    formData.append('file', data.file);

    return useAxios.post(`${route}/createFlightReportFile`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}

function updateFlightReportFile(data) {
    const formData = new FormData();
    formData.append('data', JSON.stringify({ ...data }));
    formData.append('file', data.file);
    return useAxios.put(`${route}/updateFlightReportFile`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}

function deleteFlighReportFileById(reportId) {
    return useAxios.delete(`${route}/deleteFlighReportFileById/${reportId}`);
}

function getByFlightCode(flightCode) {
    return useAxios.get(`${route}/getByFlightCode/${flightCode}`);
}