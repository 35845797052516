import "./App.css";

import { Link, Navigate, Route, Routes } from "react-router-dom";

import DashboardLayout from "./pages/DashboardLayout/DashboardLayout";
import HomePage from "./pages/HomePage/HomePage";
import Login from "./pages/Login/Login";
import MapForMobile from "./pages/MapForMobile/MapForMobile";

import { dashboardRoutes, homeRoutes } from "./constants/appRoutes";

import useAuth from "./hooks/useAuth";

import { ThemeProvider, createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: [
      'Noto Sans Thai',
      'sans-serif'
    ].join(',')
  }
});

function App() {
  const { userData } = useAuth();

  const homeIndexRoute = homeRoutes.find((route) => route.index);
  const dashboardIndexRoute = dashboardRoutes.find((route) => route.index);

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route path="/" element={<HomePage />}>
          <Route
            index
            element={<Navigate to={`${homeIndexRoute?.path}`} replace />}
          />

          {homeRoutes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Route>

        {
          userData?.group?.role !== "USER" && (
            <Route path="/dashboard" element={<DashboardLayout />}>
              <Route
                index
                element={
                  <Navigate to={`/dashboard${dashboardIndexRoute?.path}`} replace />
                }
              />

              {dashboardRoutes.map((route) => (
                <Route
                  key={route.path}
                  path={`/dashboard${route.path}`}
                  element={route.element}
                />
              ))}
            </Route>
          )
        }

        <Route path="/mapForMobile" element={<MapForMobile />} />
        <Route path="*" element={<>Page Not Found <Link to="/">HomePage</Link></>} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
