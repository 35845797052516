import React, { useEffect, useState } from 'react'
import "./FlightRoute.css"

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Grid, IconButton, Typography } from '@mui/material';
import { Close, CloudUpload } from '@mui/icons-material';

import DragAndDrop from '../../../../components/DragAndDrop/DragAndDrop'
import OptionButton from '../../../../components/OptionButton/OptionButton';

import { flightPathServices } from '../../../../services/flightPathServices';

import { openStreetMapConfig, thaiCenter } from '../../../../constants/mapConstants';

import Swal from 'sweetalert2';
import KML from 'react-leaflet-kml'
import { MapContainer, TileLayer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'

import useErrorHandler from '../../../../hooks/useErrorHandler';
import useFile from '../../../../hooks/useFile';
import useKmlGeoJson from '../../../../hooks/useKmlGeoJson';

const fileTypes = { "application/vnd": [".kml", ".kmz"] };

function FlightRoute() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [file, setFile] = useState(null);
    const [isNewFlight, setIsNewFlight] = useState(false);

    const { errorHandler } = useErrorHandler();
    const { createFile } = useFile();
    const {
        kml, setKml,
        center, setCenter
    } = useKmlGeoJson(file);

    useEffect(() => {
        const getByFlightCode = async () => {
            try {
                const res = await flightPathServices.getByFlightCode(searchParams.get("flightCode"));
                console.log("getByFlightCode => ", res);
                if (res.status === 200) {
                    const data = res.data.data;

                    if (!data) return setIsNewFlight(true);

                    if (data.area_boundary_file) {
                        const fileData = await createFile(data.area_boundary_file);
                        setFile(fileData);
                    }
                }
            } catch (error) {
                console.log(error);
                const { status, data } = error.response;
                errorHandler(
                    'error: getByFlightCode',
                    status,
                    data.message
                );
            }
        }

        searchParams.has("flightCode") && getByFlightCode();
    }, [searchParams]);

    const goToDashboard = () => navigate('/dashboard/flight-plan');

    const handleFileChange = (acceptedFiles) => {
        console.log(acceptedFiles);
        if (acceptedFiles.length === 0) return;

        setFile({
            data: acceptedFiles[0],
            originalname: acceptedFiles[0].name,
        });
    };

    const handleDeleteFile = () => {
        setFile(null);
        setCenter(thaiCenter);
        setKml(null);
    };

    const handleSaveClick = () => {
        const data = {
            flightPath: {
                flight_code: searchParams.get("flightCode"),
            },
            file: file?.data,
        }
        console.log(data);

        if (!id || isNewFlight) {
            createFlightPath(data);
        } else {
            updateFlightPath(data);
        }
    }

    const createFlightPath = async (data) => {
        try {
            const res = await flightPathServices.createFlightPath(data);
            console.log(res);
            if (res.status === 201) {
                setIsNewFlight(false);
                Swal.fire({
                    title: "บันทึกเส้นทางบินสำเร็จ",
                    icon: "success",
                });
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: createFlightPath',
                status,
                data.message
            );
        }
    }

    const updateFlightPath = async (data) => {
        try {
            const res = await flightPathServices.updateFlightPath(data);
            console.log(res);
            if (res.status === 201) {
                Swal.fire({
                    title: "อัปเดตเส้นทางบินสำเร็จ",
                    icon: "success",
                });
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: updateFlightPath',
                status,
                data.message
            );
        }
    }

    return (
        <Grid container rowGap={3} className="flight-route">
            <Grid item xs={6}>
                <div className="upload-header">
                    <CloudUpload className="icon" />
                    <Typography className="header-text">
                        อัปโหลดเส้นทางบิน (ไฟล์ชนิด .KML .KMZ)
                    </Typography>
                </div>
                <DragAndDrop fileTypes={fileTypes} maxFiles={1} onDrop={handleFileChange}>
                    {file && (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography>{file.originalname}</Typography>
                            <IconButton onClick={handleDeleteFile}>
                                <Close />
                            </IconButton>
                        </div>
                    )}
                </DragAndDrop>
            </Grid>
            <Grid item xs={6}>
                {
                    file && (
                        <MapContainer center={center} zoom={10} style={{ height: '100%', margin: '0 16px' }} key={center.toString()}>
                            <TileLayer {...openStreetMapConfig} />
                            {kml && <KML kml={kml} />}
                        </MapContainer>
                    )
                }
            </Grid>

            <Grid item xs={12}>
                <div className="btn">
                    <OptionButton
                        text="บันทึก"
                        bgColor="var(--options-btn-bg-color-5)"
                        hoverColor="var(--options-btn-bg-hover-5)"
                        onClick={handleSaveClick}
                    />
                    <OptionButton
                        text="ยกเลิก"
                        bgColor="var(--options-btn-bg-color-2)"
                        hoverColor="var(--options-btn-bg-hover-2)"
                        textColor="gray"
                        onClick={goToDashboard}
                    />
                </div>
            </Grid>
        </Grid>
    )
}

export default FlightRoute