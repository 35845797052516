import React, { useEffect, useMemo, useState } from 'react'
import './ManageCoralForm.css'

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { FormControl, Grid, MenuItem, Select, Tab, Tabs, TextField, Typography } from '@mui/material'
import { Add } from '@mui/icons-material';

import OptionButton from '../../components/OptionButton/OptionButton';

import PositionInfo from './Tabs/PositionInfo/PositionInfo';
import PositionImage from './Tabs/PositionImage/PositionImage';

import { surveyAreaServices } from '../../services/surveyAreaServices';
import { surveyPositionServices } from '../../services/surveyPositionServices';

import useAreaBoundary from '../../hooks/useAreaBoundary';
import useErrorHandler from '../../hooks/useErrorHandler';

import Swal from 'sweetalert2';

function ManageCoralForm() {
    const { code } = useParams();
    const navigate = useNavigate();

    const [isEdit, setIsEdit] = useState(false);
    const [surveyArea, setSurveyArea] = useState({
        surveyAreaCode: '',
        areaName: '',
        areaBoundaryId: '',
    });

    const { areaBoundaryList } = useAreaBoundary();
    const { errorHandler } = useErrorHandler();

    const isEditSurveyAreaForm = (!surveyArea.surveyAreaCode || isEdit);

    useEffect(() => {
        const getSurveyAreaByCode = async () => {
            try {
                const res = await surveyAreaServices.getSurveyAreaByCode(code);
                console.log(res);
                if (res.status === 200) {
                    const data = res.data.data;
                    setSurveyArea({
                        surveyAreaCode: data.survey_area_code,
                        areaName: data.survey_area_name,
                        areaBoundaryId: data.AreaBoundaryId,
                    });
                }
            } catch (error) {
                console.log(error);
                const { status, data } = error.response;
                errorHandler(
                    'error: getSurveyAreaByCode',
                    status,
                    data.message,
                    false,
                    goToDashboard
                );
            }
        }

        code && getSurveyAreaByCode();
    }, [code]);

    const goToDashboard = () => navigate('/dashboard/manage-coral');
    const handleEditClick = () => setIsEdit(true);

    const handleDataChange = ({ target }) => {
        const { name, value } = target;
        setSurveyArea(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const pushFormID = (id) => navigate(`/dashboard/manage-coral-form/${id}`);

    const handleSubmitClick = (e) => {
        console.log('handleSubmitClick');
        e.preventDefault();
        const data = {
            survey_area_name: surveyArea.areaName,
            areaBoundaryId: surveyArea.areaBoundaryId,
        }
        console.log(data);

        if (!code) {
            createSurveyArea(data);
        } else {
            updateSurveyArea(data);
        }
    }

    const createSurveyArea = async (data) => {
        try {
            const res = await surveyAreaServices.createSurveyArea(data);
            console.log(res);
            if (res.status === 201) {
                Swal.fire({
                    title: "บันทึกพื้นที่สำรวจสำเร็จ",
                    icon: "success",
                }).then((result) => {
                    if (result.isConfirmed) {
                        pushFormID(res.data.survey_area_code);
                    }
                });
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: createSurveyArea',
                status,
                data.message
            );
        }
    }

    const updateSurveyArea = async (data) => {
        try {
            const res = await surveyAreaServices.updateSurveyArea({ ...data, survey_area_code: code });
            console.log(res);
            if (res.status === 201) {
                Swal.fire({
                    title: "แก้ไขพื้นที่สำรวจสำเร็จ",
                    icon: "success",
                }).then((result) => {
                    if (result.isConfirmed) {
                        setIsEdit(false);
                    }
                });
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: updateSurveyArea',
                status,
                data.message
            );
        }
    }

    return (
        <div className="manage-coral-form">
            <Grid container gap={3}>
                <form onSubmit={handleSubmitClick} style={{ width: '100%' }} className="survey-area-form">
                    <Grid container item xs={12} columnGap={1} justifyContent="flex-end">
                        <OptionButton
                            disabled={isEditSurveyAreaForm}
                            text="แก้ไข"
                            onClick={handleEditClick}
                            bgColor="var(--options-btn-bg-color-5)"
                            hoverColor="var(--options-btn-bg-hover-5)"
                        />
                        <OptionButton
                            disabled={!isEditSurveyAreaForm}
                            text="บันทึก"
                            type="submit"
                            bgColor="var(--options-btn-bg-color-5)"
                            hoverColor="var(--options-btn-bg-hover-5)"
                        />
                        {/* {isEditSurveyAreaForm ? (
                            <OptionButton
                                text="บันทึก"
                                type="submit"
                                bgColor="var(--options-btn-bg-color-5)"
                                hoverColor="var(--options-btn-bg-hover-5)"
                            />
                        ) : (
                            <OptionButton
                                text="แก้ไข"
                                onClick={handleEditClick}
                                bgColor="var(--options-btn-bg-color-5)"
                                hoverColor="var(--options-btn-bg-hover-5)"
                            />
                        )} */}
                    </Grid>
                    <Grid container item xs={12} columnGap={3}>
                        <Grid item xs={3} display="flex" alignItems="center" justifyContent="center">
                            <Typography className="form-label">จังหวัด</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth size="small">
                                <Select
                                    required
                                    displayEmpty
                                    readOnly={!isEditSurveyAreaForm}
                                    name="areaBoundaryId"
                                    value={surveyArea.areaBoundaryId}
                                    onChange={handleDataChange}
                                >
                                    {areaBoundaryList.map(areaBoundary =>
                                        <MenuItem
                                            key={areaBoundary.id}
                                            value={areaBoundary.id}
                                            disabled={!areaBoundary.table_status}
                                        >
                                            {areaBoundary.target_area}
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} columnGap={3}>
                        <Grid item xs={3} display="flex" alignItems="center" justifyContent="center">
                            <Typography className="form-label">ชื่อพื้นที่สำรวจ</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                required
                                InputProps={{
                                    readOnly: !isEditSurveyAreaForm
                                }}
                                size="small"
                                name="areaName"
                                value={surveyArea.areaName}
                                onChange={handleDataChange}
                            />
                        </Grid>
                    </Grid>
                </form>

                {!isEditSurveyAreaForm && <PositionSection surveyAreaCode={surveyArea.surveyAreaCode} />}

            </Grid>
        </div>
    )
}

export default ManageCoralForm

const PositionSection = ({ surveyAreaCode }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const { errorHandler } = useErrorHandler();

    const [postionDataList, setPostionDataList] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);

    useEffect(() => {
        if (searchParams.has("tabIndex")) {
            setTabIndex(Number(searchParams.get("tabIndex")));
        }
    }, []);

    useEffect(() => {
        if (postionDataList.length) {
            const surveyPositionCode = postionDataList[tabIndex].survey_position_code;
            const currentParams = Object.fromEntries([...searchParams]);
            setSearchParams({ ...currentParams, surveyPositionCode, tabIndex });
        }
    }, [postionDataList]);

    useEffect(() => {
        const getAllSurveyPositionBySurveyAreaCode = async () => {
            try {
                const res = await surveyPositionServices.getAllSurveyPositionBySurveyAreaCode(surveyAreaCode);
                console.log(res);
                if (res.status === 200) {
                    setPostionDataList([...res.data.data]);
                }
            } catch (error) {
                console.log(error);
                const { status, data } = error.response;
                errorHandler(
                    'error: getAllSurveyPositionBySurveyAreaCode',
                    status,
                    data.message
                );
            }
        }

        getAllSurveyPositionBySurveyAreaCode();
    }, []);

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
        const surveyPositionCode = postionDataList[newValue].survey_position_code;
        const currentParams = Object.fromEntries([...searchParams]);
        setSearchParams({ ...currentParams, surveyPositionCode, tabIndex: newValue });
    };

    const handleAddPostionClick = async () => {
        try {
            const res = await surveyPositionServices.createSurveyPosition({ surveyAreaCode });
            console.log(res);
            if (res.status === 201) {
                setPostionDataList(prev => {
                    return [...prev, res.data]
                });
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: createSurveyPosition',
                status,
                data.message
            );
        }
    }

    return (
        <Grid container item xs={12}>
            <Grid item xs={12} display="flex" alignItems="center">
                <OptionButton
                    bgColor="var(--options-btn-bg-color-1)"
                    hoverColor="var(--options-btn-bg-hover-1)"
                    onClick={handleAddPostionClick}
                >
                    <Add />ตำแหน่งสำรวจ
                </OptionButton>
                <Tabs
                    variant="scrollable"
                    scrollButtons="auto"
                    value={tabIndex}
                    onChange={handleTabChange}
                >
                    {postionDataList.map((postionData, index) => (
                        <Tab
                            key={postionData.id || index}
                            label={index + 1}
                            sx={{ fontWeight: 700 }}
                        />
                    ))}
                </Tabs>
            </Grid>

            <Grid item xs={12}>
                {postionDataList.map(
                    (postionData, index) => (
                        <TabPanel
                            key={postionData.id || index}
                            tabIndex={tabIndex}
                            index={index}
                        />
                    )
                )}
            </Grid>

        </Grid>
    )
}

const dataTabs = [
    {
        tabName: "ข้อมูลตำแหน่ง",
        tabContent: <PositionInfo />,
    },
    {
        tabName: "ข้อมูลรูปภาพ",
        tabContent: <PositionImage />,
    },
]

const TabPanel = ({ tabIndex, index }) => {
    const [dataTabIndex, setDataTabIndex] = useState(0);
    const tabContent = useMemo(() => {
        return dataTabs[dataTabIndex].tabContent;
    }, [dataTabIndex]);

    const handledataTabIndexChange = (event, newValue) => setDataTabIndex(newValue);

    return tabIndex === index && (
        <>
            <Tabs
                variant="fullWidth"
                value={dataTabIndex}
                onChange={handledataTabIndexChange}
            >
                {dataTabs.map((tab) => (
                    <Tab
                        key={tab.tabName}
                        label={tab.tabName}
                        sx={{ fontWeight: 700 }}
                    />
                ))}
            </Tabs>

            {tabContent}
        </>
    )
}