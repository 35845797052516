import React, { useState } from 'react';
import './FlightPlan.css';

import { Box, FormControl, Grid, InputAdornment, MenuItem, Select, TextField, Typography } from '@mui/material';

import { useNavigate } from 'react-router-dom';

import moment from 'moment';

import DataTable from '../../components/DataTable/DataTable';
import OptionButton from '../../components/OptionButton/OptionButton';
import DeleteButton from '../../components/TableButton/DeleteButton';
import EditButton from '../../components/TableButton/EditButton';
import StatusButton from '../../components/TableButton/StatusButton';
import ExcelButton from '../../components/ExcelButton/ExcelButton';

import { flightPlanServices } from '../../services/flightPlanServices';

import useAreaBoundary from '../../hooks/useAreaBoundary';
import useDataTable from '../../hooks/useDataTable';
import useFlightPlan from '../../hooks/useFlightPlan';

const columns = [
    {
        field: 'row_num',
        headerName: 'ลำดับ',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
    },
    {
        field: 'flight_mission_code',
        headerName: 'รหัสแผนบิน',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        flex: 1,
    },
    {
        field: 'target_area',
        headerName: 'พื้นที่เป้าหมาย',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        sortable: false,
        valueGetter: (param) => param.row.FlyingArea.AreaBoundary.target_area
    },
    {
        field: 'flightDate',
        headerName: 'วันที่บิน',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        sortable: false,
        valueGetter: (param) => moment(param.row.start_date).format('DD/MM/yyyy')
    },
    {
        field: 'flight_plan_status',
        headerName: 'สถานะ',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        sortable: false,
        renderCell: (param) => <PlanStatus status={param.value} />
    },
    {
        field: 'table_status',
        headerName: 'สถานะ',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        renderCell: (param) => <StatusButton data={param.row} service={flightPlanServices.updateStatus} />
    },
    {
        field: 'edit',
        headerName: 'แก้ไข',
        headerAlign: 'center',
        type: 'actions',
        sortable: false,
        renderCell: (param) => <EditButton id={param.row.id} formURL="flight-plan-form" />
    },
    {
        field: 'delete',
        type: 'actions',
        sortable: false,
        renderCell: (param) => <DeleteButton id={param.row.id} service={flightPlanServices.deleteFlightMissionPlanById} />
    },
];

const initFilters = {
    search: '',
    areaBoundaryId: '',
    dateFrom: '',
    dateTo: '',
}

const flightStatusList = [
    {
        label: 'ยังไม่ดำเนินการ',
        value: 'notimplemented',
    },
    {
        label: 'กำลังดำเนินการ',
        value: 'inprogress',
    },
    {
        label: 'เสร็จสิ้นภารกิจ',
        value: 'complete',
    },
    {
        label: 'ล้มเหลว',
        value: 'failed',
    },
    {
        label: 'ไม่ระบุ',
        value: 'notspecified',
    },
]

function FlightPlan() {
    const navigate = useNavigate();

    const { paginationModel, resetPage } = useDataTable();
    const { areaBoundaryList } = useAreaBoundary();

    const [filters, setFilters] = useState(initFilters);
    const [paginationfilters, setPaginationFilters] = useState(initFilters);

    const { flightPlanList: rows, rowCount } = useFlightPlan(
        { ...paginationfilters, ...paginationModel },
        [paginationModel.page, paginationfilters]
    );

    const handleAddClick = () => navigate('/dashboard/flight-plan-form');

    const handleFilterChange = ({ target }) => {
        const { name, value } = target;
        console.log(value);

        setFilters(prev => {
            return {
                ...prev,
                [name]: value,
            }
        });
    }

    const handleSearchClick = () => {
        console.log(filters);
        setPaginationFilters({ ...filters });
        resetPage();
    }

    const handleClearClick = () => {
        setFilters({ ...initFilters });
        setPaginationFilters({ ...initFilters });
        resetPage();
    }

    return (
        <div className="flight-plan">
            <Grid container className="filters" gap={1}>
                <Grid container item md={12} display="flex" alignItems="center">
                    <Grid item md={1}>
                        <Typography>ค้นหา</Typography>
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            fullWidth
                            size="small"
                            name="search"
                            value={filters.search}
                            onChange={handleFilterChange}
                        />
                    </Grid>
                </Grid>
                <Grid container item md={12} display="flex" alignItems="center">
                    <Grid item md={1}>
                        <Typography>พื้นที่</Typography>
                    </Grid>
                    <Grid item md={6}>
                        <FormControl fullWidth size="small">
                            <Select
                                required
                                name="areaBoundaryId"
                                value={filters.areaBoundaryId}
                                onChange={handleFilterChange}
                            >
                                {areaBoundaryList.map(areaBoundary =>
                                    <MenuItem
                                        key={areaBoundary.id}
                                        value={areaBoundary.id}
                                        disabled={!areaBoundary.table_status}
                                    >
                                        {areaBoundary.target_area}
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container item md={12} display="flex" alignItems="center">
                    <Grid item md={1}>
                        <Typography>วันที่เพิ่ม</Typography>
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            fullWidth
                            type="date"
                            size="small"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Box>From</Box>
                                    </InputAdornment>
                                ),
                            }}
                            name="dateFrom"
                            value={filters.dateFrom}
                            onChange={handleFilterChange}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            fullWidth
                            type="date"
                            size="small"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Box>To</Box>
                                    </InputAdornment>
                                ),
                            }}
                            name="dateTo"
                            value={filters.dateTo}
                            onChange={handleFilterChange}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid container className="options">
                <Grid item md={1}></Grid>
                <Grid
                    item xs={12} md={6}
                    sx={{
                        '& button': { width: '25%' },
                        '& :not(:nth-of-type(1))': { ml: 2 }
                    }}
                >
                    <OptionButton
                        text='ค้นหา'
                        bgColor='var(--options-btn-bg-color-1)'
                        hoverColor='var(--options-btn-bg-hover-1)'
                        onClick={handleSearchClick}
                    />
                    <OptionButton
                        text='ล้าง'
                        bgColor='var(--options-btn-bg-color-2)'
                        hoverColor='var(--options-btn-bg-hover-2)'
                        textColor='gray'
                        onClick={handleClearClick}
                    />
                    <OptionButton
                        text='เพิ่ม'
                        bgColor='var(--options-btn-bg-color-1)'
                        hoverColor='var(--options-btn-bg-hover-1)'
                        onClick={handleAddClick}
                    />
                </Grid>

                <ExcelButton modelName="FlightMissionPlan" importCallback={handleClearClick} />
            </Grid>

            <DataTable
                columns={columns}
                rows={rows}
                rowCount={rowCount}
                pageProps={paginationModel}
            />
        </div>
    )
}

export default FlightPlan

const PlanStatus = ({ status }) => {
    const currentStatus = flightStatusList.find(fs => fs.value === status);
    return (
        <div className={`plan-status ${currentStatus.value}`}>
            {currentStatus.label}
        </div>
    )
}