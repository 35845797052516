import { useAxios } from "../guard/axiosConfig"

export const droneTypeServices = {
    createDroneType,
    getAllDroneType,
    getDroneTypeById,
    updateStatus,
    updateDroneType,
    deleteDroneTypeById,
}

const route = '/droneType';

function createDroneType(data) {
    return useAxios.post(`${route}/createDroneType`, data);
}

function getAllDroneType(filters) {
    return useAxios.get(`${route}/getAllDroneType`, { params: filters });
}

function getDroneTypeById(id) {
    return useAxios.get(`${route}/getDroneTypeById/${id}`);
}

function updateStatus(data) {
    return useAxios.put(`${route}/updateStatus`, data);
}

function updateDroneType(data) {
    return useAxios.put(`${route}/updateDroneType`, data);
}

function deleteDroneTypeById(id) {
    return useAxios.delete(`${route}/deleteDroneTypeById/${id}`);
}