import { useAxios } from '../guard/axiosConfig'

export const flightPlanServices = {
    getAllFlightMissionPlan,
    createFlightMissionPlan,
    getFlightMissionPlanById,
    updateStatus,
    updateFlightMissionPlan,
    deleteFlightMissionPlanById,
}

const route = '/flightMissionPlan';

function getAllFlightMissionPlan(filters) {
    return useAxios.get(`${route}/getAllFlightMissionPlan`, { params: filters });
}

function createFlightMissionPlan(data) {
    const formData = new FormData();
    formData.append('data', JSON.stringify({ ...data }));
    formData.append('file', data.file);

    return useAxios.post(`${route}/createFlightMissionPlan`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}

function getFlightMissionPlanById(id) {
    return useAxios.get(`${route}/getFlightMissionPlanById/${id}`);
}

function updateStatus(data) {
    return useAxios.post(`${route}/updateStatus`, data);
}

function updateFlightMissionPlan(data) {
    const formData = new FormData();
    formData.append('data', JSON.stringify({ ...data }));
    formData.append('file', data.file);

    return useAxios.post(`${route}/updateFlightMissionPlan`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}

function deleteFlightMissionPlanById(id) {
    return useAxios.delete(`${route}/deleteFlightMissionPlanById/${id}`);
}