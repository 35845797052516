import React, { useState } from "react";

import {
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";

import useAuth from "../../../hooks/useAuth";
import useErrorHandler from "../../../hooks/useErrorHandler";

import Swal from "sweetalert2";

import { userServices } from "../../../services/userServices";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const EditPasswordPanel = ({ callback }) => {
  const { userData } = useAuth();
  const { errorHandler } = useErrorHandler();

  const handleClose = () => {
    callback();
  };

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [password, setPassword] = useState({
    current_password: '',
    new_password: '',
    confirm_password: '',
  });

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);

  const handleToggleCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const [showNewPassword, setShowNewPassword] = useState(false);

  const handleToggleNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleDataChange = ({ target }) => {
    console.log('target', target.name, 'val', target.value);
    setPassword((prev) => {
      return {
        ...prev,
        [target.name]: target.value,
      };
    });
  };

  const handleSaveClick = (e) => {
    e.preventDefault();
    console.log('password', password);
    const data = {
      ...password,
      id: userData.id
    }

    if (password.new_password !== password.confirm_password) {
      Swal.fire({
        title: "พบข้อผิดพลาด",
        text: 'รหัสผ่านใหม่และรหัสผ่านที่ยืนยันไม่ตรงกัน',
        icon: "error",
      });
      return;
    }
    updatePassword(data);
  };

  const updatePassword = async (data) => {
    try {
      const res = await userServices.changePassword(data);
      console.log(res);
      if (res.status === 201) {
        // fetchUserData();
        Swal.fire({ title: "เปลี่ยนรหัสผ่านสำเร็จ", icon: "success" });
        handleClose();
      }
    } catch (error) {
      console.log(error);
      const { status, data } = error.response;
      errorHandler(
        'error: changePassword',
        status,
        data.message
      );
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <form onSubmit={handleSaveClick} style={{ zIndex: 1 }}>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="flex-start"
          gap={2}
          style={{
            width: "70%",
            height: "70%",
            backgroundColor: "white",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "10px",
            padding: "30px",
            overflow: 'auto'
          }}
        >
          <Grid item xs={12}>
            <Typography
              sx={{
                lineHeight: "2",
                textAlign: "center",
                fontSize: "2vw",
                fontWeight: "bold",
              }}
            >
              แก้ไขข้อมูลผู้ใช้งาน
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              sx={{
                lineHeight: "2",
                fontSize: "2vw",
                fontWeight: "bold",
              }}
            >
              รหัสผ่านปัจจุบัน
            </Typography>
            <TextField
              fullWidth
              required
              name="current_password"
              type={showCurrentPassword ? "text" : "password"}
              placeholder="*********"
              onChange={handleDataChange}
              size="small"
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={handleToggleCurrentPassword}
                    edge="end"
                    style={{ marginRight: "8px" }}
                  >
                    {showCurrentPassword ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                lineHeight: "2",
                fontSize: "2vw",
                fontWeight: "bold",
              }}
            >
              รหัสผ่านใหม่
            </Typography>
            <TextField
              fullWidth
              required
              name="new_password"
              type={showNewPassword ? "text" : "password"}
              placeholder="*********"
              onChange={handleDataChange}
              size="small"
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={handleToggleNewPassword}
                    edge="end"
                    style={{ marginRight: "8px" }}
                  >
                    {showNewPassword ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                lineHeight: "2",
                fontSize: "2vw",
                fontWeight: "bold",
              }}
            >
              ยืนยันรหัสผ่าน
            </Typography>
            <TextField
              fullWidth
              required
              name="confirm_password"
              type={showConfirmPassword ? "text" : "password"}
              placeholder="*********"
              onChange={handleDataChange}
              size="small"
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={handleToggleConfirmPassword}
                    edge="end"
                    style={{ marginRight: "8px" }}
                  >
                    {showConfirmPassword ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                ),
              }}
            />
          </Grid>

          <Grid container item xs={12} direction="row" justifyContent="center">
            <Grid item xs={6}>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  width: "100%", // ปรับค่าตามที่ต้องการ
                  marginRight: "10px",
                  borderRadius: "30px",
                  backgroundColor: "#004aad",
                  fontSize: "1.5vw", // ปรับค่าตามที่ต้องการ
                  fontWeight: "bold", // ปรับค่าตามที่ต้องการ
                }}
              >
                ยืนยันรหัสผ่าน
              </Button>
            </Grid>

            <Grid item xs={6}>
              <Button
                variant="contained"
                onClick={handleClose}
                sx={{
                  width: "100%", // ปรับค่าตามที่ต้องการ
                  marginLeft: "10px",
                  borderRadius: "30px",
                  backgroundColor: "#fa4137",
                  fontSize: "1.5vw", // ปรับค่าตามที่ต้องการ
                  fontWeight: "bold", // ปรับค่าตามที่ต้องการ
                  "&:hover": {
                    backgroundColor: "#ff5a4e",
                  },
                  "&:active": {
                    backgroundColor: "#e6362d",
                  },
                }}
              >
                ยกเลิก
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default EditPasswordPanel;
