import React, { useCallback, useEffect, useState } from 'react'

import { flightPathServices } from '../../../services/flightPathServices';

import { useMap } from 'react-leaflet';

import KML from 'react-leaflet-kml'
import Swal from 'sweetalert2';

import useMapFilters from '../../../hooks/useMapFilters';

function FlightRouteLayer() {
    const map = useMap();
    
    const [kmlList, setKmlList] = useState([]);

    const { filters } = useMapFilters();

    const getAllFlightPath = useCallback((currentFilters) => {
        setKmlList([]);
        console.table(currentFilters);
        try {
            const eventSource = flightPathServices.getAllFlightPath({ ...currentFilters, checkStatus: 1 });

            eventSource.onmessage = (event) => {
                const data = JSON.parse(event.data);
                const parser = new DOMParser();
                setKmlList(prev => {
                    const kmlData = parser.parseFromString(data.kml, "text/xml");
                    return [...prev, { ...data, kml: kmlData }];
                });
            };

            eventSource.addEventListener('close', () => {
                console.log('Connection closed from server');
                eventSource.close();
            })

            eventSource.onerror = () => {
                console.log('event source error');
                eventSource.close();
            }

            return eventSource;
        } catch (error) {
            console.log(error);
            Swal.fire({
                title: "พบข้อผิดพลาด",
                text: "getAllFlightPath error",
                icon: "error",
            });
        }
    }, []);

    useEffect(() => {
        const eventSource = getAllFlightPath(filters);

        return () => {
            console.log('disconnected');
            eventSource?.close();
        };
    }, [filters]);

    useEffect(() => {
        if (kmlList.length === 1) {
            map.flyTo(kmlList[0].center, 10);
        }
    }, [kmlList]);

    return (
        <div>
            {kmlList.map(data => (
                <KML key={data.id} kml={data.kml} />
            ))}
        </div>
    )
}

export default FlightRouteLayer