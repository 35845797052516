import React, { useState } from 'react'

import {
    Button, Card, CardContent, CardHeader,
    Collapse, FormControl, MenuItem, Select,
    Tab, Tabs, Typography
} from '@mui/material';
import { Menu } from '@mui/icons-material';

import { useLocation, useSearchParams } from 'react-router-dom';

import useMapFilters from '../../hooks/useMapFilters';

const areaTypeList = [
    { text: 'ชายฝั่งทะเล', value: 'sea-coast' },
    { text: 'ป่าชายเลน', value: 'mangrove-forest' },
]

function MapControlPanel({ handleLayerClick, optionMenu }) {
    const location = useLocation();
    const isMobile = location.pathname.includes('mapForMobile');
    const [searchParams] = useSearchParams({ mapLayer: 'ชายฝั่งทะเล' });

    const { setFilters } = useMapFilters();

    const [expanded, setExpanded] = useState(!isMobile);
    const [tabIndex, setTabIndex] = useState(optionMenu.findIndex(menu => menu.label === searchParams.get('mapLayer')));

    const handleExpandClick = () => setExpanded(!expanded);

    const handleTabChange = (event, newValue) => setTabIndex(newValue);

    const handleFilterChange = ({ target }) => {
        const { name, value } = target;
        console.log(value);

        setFilters(prev => {
            return {
                ...prev,
                [name]: value,
            }
        });
    }

    return (
        <Card
            sx={{ width: expanded ? 'auto' : 'fit-content' }}
            className="options"
        >
            <CardHeader
                sx={{ p: 0, '& .MuiCardHeader-avatar': { mr: 0 } }}
                avatar={
                    <Button onClick={handleExpandClick} size="large" sx={{ color: "gray" }}>
                        <Menu />
                    </Button>
                }
            />

            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent sx={{ p: 1 }} className="layers">
                    <Tabs
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile
                        value={tabIndex}
                        onChange={handleTabChange}
                    >
                        {optionMenu.map(
                            (option, index) =>
                                <Tab
                                    key={option.label}
                                    sx={{ fontWeight: 700 }}
                                    label={option.label}
                                    onClick={() => handleLayerClick(option.label)}
                                />
                        )}
                    </Tabs>
                </CardContent>
                <CardContent className="content-container">

                    <AreaBoundaryFilter handleFilterChange={handleFilterChange} />

                    {searchParams.get("mapLayer") === "ปะการัง" && (
                        <CoralIslandFilter handleFilterChange={handleFilterChange} />
                    )}

                    {["ชายฝั่งทะเล", "ป่าชายเลน"].includes(searchParams.get("mapLayer")) && (
                        <CategoryFilter handleFilterChange={handleFilterChange} />
                    )}

                    {searchParams.get("mapLayer") === "เส้นทางบิน" && (
                        <MissionFilter handleFilterChange={handleFilterChange} />
                    )}
                </CardContent>
            </Collapse>
        </Card>
    )
}

export default MapControlPanel

const CategoryFilter = ({ handleFilterChange }) => {
    const [searchParams] = useSearchParams({ mapLayer: 'ชายฝั่งทะเล' });
    const areaType = areaTypeList.find(type => type.text === searchParams.get("mapLayer")).value;
    const { filters, categoryList } = useMapFilters();

    return (
        <div className="category">
            <Typography className="header">หมวดหมู่</Typography>
            <FormControl fullWidth size="small">
                <Select
                    name="category"
                    value={filters.category}
                    onChange={handleFilterChange}
                >
                    {categoryList.filter(item => item.area_type === areaType)
                        .map(category =>
                            <MenuItem
                                key={category.id}
                                value={category.id}
                                disabled={!category.table_status}
                            >
                                {category.category_name}
                            </MenuItem>
                        )
                    }
                    <MenuItem value="">ทั้งหมด</MenuItem>
                </Select>
            </FormControl>
        </div>
    )
}

const AreaBoundaryFilter = ({ handleFilterChange }) => {
    const { filters, areaBoundaryList } = useMapFilters();

    return (
        <div className="area-boundary">
            <Typography className="header">เลือกพื้นที่</Typography>
            <FormControl fullWidth size="small">
                <Select
                    name="areaBoundaryId"
                    value={filters.areaBoundaryId}
                    onChange={handleFilterChange}
                >
                    {areaBoundaryList.map(areaBoundary =>
                        <MenuItem
                            key={areaBoundary.id}
                            value={areaBoundary.id}
                            disabled={!areaBoundary.table_status}
                        >
                            {areaBoundary.target_area}
                        </MenuItem>
                    )}
                    <MenuItem value="">ทั้งหมด</MenuItem>
                </Select>
            </FormControl>
        </div>
    )
}

const MissionFilter = ({ handleFilterChange }) => {
    const { filters, missionList } = useMapFilters();

    return (
        <div className="mission">
            <Typography className="header">เลือกภารกิจ</Typography>
            <FormControl fullWidth size="small">
                <Select
                    name="missionId"
                    value={filters.missionId}
                    onChange={handleFilterChange}
                >
                    {missionList.map(mission =>
                        <MenuItem
                            key={mission.id}
                            value={mission.id}
                            disabled={!mission.table_status}
                        >
                            {mission.mission_name}
                        </MenuItem>
                    )}
                    <MenuItem value="">ทั้งหมด</MenuItem>
                </Select>
            </FormControl>
        </div>
    )
}

const CoralIslandFilter = ({ handleFilterChange }) => {
    const { filters, islandList } = useMapFilters();

    return (
        <div className="island-area">
            <Typography className="header">พื้นที่สำรวจ</Typography>
            <FormControl fullWidth size="small">
                <Select
                    name="islandId"
                    value={filters.islandId}
                    onChange={handleFilterChange}
                >
                    {islandList.map(island =>
                        <MenuItem
                            key={island.survey_area_code}
                            value={island.survey_area_code}
                            disabled={!island.table_status}
                        >
                            {island.survey_area_name}
                        </MenuItem>
                    )}
                    <MenuItem value="">ทั้งหมด</MenuItem>
                </Select>
            </FormControl>
        </div>
    )
}