import React, { useEffect, useState } from 'react'
import "./FlightReportFile.css"

import { useSearchParams } from 'react-router-dom';

import {
    Box, FormControl, Grid, IconButton, MenuItem,
    Paper, Select, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, TextField, Typography
} from '@mui/material'
import { Delete, FileDownload } from '@mui/icons-material';

import moment from "moment";

import OptionButton from '../../../../components/OptionButton/OptionButton';

import useAuth from '../../../../hooks/useAuth'
import useErrorHandler from '../../../../hooks/useErrorHandler';
import useFile from '../../../../hooks/useFile';
import useFlightDataList from '../../../../hooks/useFlightDataList'

import { flightReportFileServices } from '../../../../services/flightReportFileServices';

import { formatFileSize } from '../../../../utils';

moment.locale("en");

const initFileInfo = {
    organizer: "",
    dateInformation: "",
    referenceNumber: "",
}
const initFile = {
    data: null,
    originalname: "",
}

function FlightReportFile() {
    const [searchParams] = useSearchParams();

    const { userData } = useAuth();
    const { userList } = useFlightDataList();
    const { errorHandler } = useErrorHandler();

    const [fileList, setFileList] = useState([]);
    const [fileInfo, setFileInfo] = useState(initFileInfo);
    const [file, setFile] = useState(initFile);

    useEffect(() => {
        const getByFlightCode = async () => {
            try {
                const res = await flightReportFileServices.getByFlightCode(searchParams.get("flightCode"));
                console.log("getByFlightCode => ", res);
                if (res.status === 200) {
                    const data = res.data.data || [];
                    setFileList(data.map(file => {
                        return {
                            id: file.id,
                            organizer: file.organizer,
                            dateInformation: file.date_information,
                            referenceNumber: file.reference_number,
                            user: file.user_id,
                            createdAt: file.createdAt,
                            file: file.file,
                        }
                    }));
                }
            } catch (error) {
                console.log(error);
                const { status, data } = error.response;
                errorHandler(
                    'error: getByFlightCode',
                    status,
                    data.message
                );
            }
        }

        searchParams.has("flightCode") && getByFlightCode();
    }, [searchParams]);

    const handleDataChange = ({ target }) => {
        setFileInfo(prev => {
            return {
                ...prev,
                [target.name]: target.value,
            }
        })
    }

    const handleFileChange = ({ target }) => {
        const files = target.files;
        console.log(files);
        if (files.length === 0) return;

        const currentFile = files[0];

        setFile({
            data: currentFile,
            originalname: currentFile.name,
        });
    };

    const clearInput = () => {
        setFileInfo(initFileInfo);
        setFile(initFile);
    }

    const handleAddFileClick = async (e) => {
        e.preventDefault();
        const data = {
            flightReportFile: {
                flight_code: searchParams.get("flightCode"),
                organizer: fileInfo.organizer,
                reference_number: fileInfo.referenceNumber,
                date_information: fileInfo.dateInformation,
                user_id: userData.id,
            },
            file: file?.data,
        }
        console.log(data);

        try {
            const res = await flightReportFileServices.createFlightReportFile(data);
            console.log("createFlightReportFile => ", res);
            if (res.status === 201) {
                const resData = res.data;
                setFileList(prev => {
                    return [
                        ...prev,
                        {
                            ...fileInfo,
                            user: userData.id,
                            id: resData.id,
                            createdAt: resData.createdAt,
                            file: resData.file,
                        },
                    ]
                });
                clearInput();
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: createFlightReportFile',
                status,
                data.message
            );
        }
    }

    return (
        <form onSubmit={handleAddFileClick}>
            <Grid container paddingX={4} rowGap={2} className="reports-file">
                <Grid container item xs={12} gap={1} alignItems="center" justifyContent="flex-end">
                    <Typography sx={{ fontWeight: 700 }}>ไฟล์</Typography>
                    <TextField disabled size="small" value={file?.originalname} />
                    <OptionButton
                        input
                        bgColor='var(--options-btn-bg-color-2)'
                        hoverColor='var(--options-btn-bg-hover-2)'
                        textColor='gray'
                    >
                        เลือก
                        <input
                            hidden
                            type="file"
                            onChange={handleFileChange}
                            accept=".pdf, .doc, .docx, .ppt, .pptx, .xsl, .xslx"
                        />
                    </OptionButton>
                    <OptionButton
                        text="เพิ่มไฟล์"
                        type="submit"
                        disabled={!file.data}
                        bgColor='var(--options-btn-bg-color-1)'
                        hoverColor='var(--options-btn-bg-hover-1)'
                    />
                </Grid>

                <Grid container item xs={12}>
                    <Grid container item xs={4} gap={1}>
                        <GridHeader>
                            <Typography>ผู้จัดทำ</Typography>
                        </GridHeader>
                        <GridForm>
                            <FormControl fullWidth size="small">
                                <Select
                                    required
                                    displayEmpty
                                    name="organizer"
                                    value={fileInfo.organizer}
                                    onChange={handleDataChange}
                                >
                                    {userList.map(user =>
                                        <MenuItem
                                            key={user.id}
                                            value={user.id}
                                            disabled={!user.table_status}
                                        >
                                            {`${user.first_name} ${user.last_name}`}
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </GridForm>
                    </Grid>
                    <Grid container item xs={4} gap={1}>
                        <GridHeader>
                            <Typography>วันที่ข้อมูล</Typography>
                        </GridHeader>
                        <GridForm>
                            <TextField
                                required
                                fullWidth
                                size="small"
                                type="date"
                                name="dateInformation"
                                value={fileInfo.dateInformation}
                                onChange={handleDataChange}
                            />
                        </GridForm>
                    </Grid>
                    <Grid container item xs={4} gap={1}>
                        <GridHeader>
                            <Typography>เลขที่อ้างอิง</Typography>
                        </GridHeader>
                        <GridForm>
                            <TextField
                                required
                                fullWidth
                                size="small"
                                name="referenceNumber"
                                value={fileInfo.referenceNumber}
                                onChange={handleDataChange}
                            />
                        </GridForm>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <FileTable rows={fileList} setFileList={setFileList} />
                </Grid>
            </Grid>
        </form>
    )
}

export default FlightReportFile

const FileTable = ({ rows, setFileList }) => {
    const { userList } = useFlightDataList();
    const { downloadFile } = useFile();
    const { errorHandler } = useErrorHandler();

    const handleDownloadClick = async (file) => await downloadFile(file);

    const handleDeleteClick = async (id) => {
        try {
            const res = await flightReportFileServices.deleteFlighReportFileById(id);
            console.log(res);
            if (res.status === 200) {
                setFileList(rows.filter(file => file.id !== id));
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: deleteFlighReportFileById',
                status,
                data.message
            );
        }
    }

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow>
                        <CustomTableHeader>ข้อมูล</CustomTableHeader>
                        <CustomTableHeader>ผู้จัดทำ</CustomTableHeader>
                        <CustomTableHeader>ข้อมูลอ้างอิง</CustomTableHeader>
                        <CustomTableHeader>ผู้ทำรายการ</CustomTableHeader>
                        <CustomTableHeader>แก้ไขรายการ</CustomTableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {rows.map((row, index) => {
                        const currentUser = userList.find(user => user.id === row.user);
                        const currentOrganizer = userList.find(user => user.id === row.organizer);
                        const currentDateInfo = moment(row.dateInformation).format('DD/MM/yyyy');
                        const currentCreatedAt = moment(row.createdAt).format('DD/MM/yyyy hh:mm A');
                        const currentFilename = row.file.originalname;
                        const filenameArr = currentFilename.split('.');
                        const fileExt = filenameArr.pop().toUpperCase();
                        const fileSize = formatFileSize(row.file.size);
                        return (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <CustomTableBody>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                            <Typography>
                                                {`${index + 1}. ข้อมูล`}
                                            </Typography>
                                            <Typography>{`${fileExt} ${fileSize}`}</Typography>
                                        </Box>
                                        <OptionButton
                                            bgColor="var(--options-btn-bg-color-5)"
                                            hoverColor="var(--options-btn-bg-hover-5)"
                                            onClick={() => handleDownloadClick(row.file)}
                                        >
                                            ดาวน์โหลด
                                            <FileDownload />
                                        </OptionButton>
                                    </Box>
                                </CustomTableBody>
                                <CustomTableBody>
                                    {`${currentOrganizer.first_name} ${currentOrganizer.last_name}`}
                                </CustomTableBody>
                                <CustomTableBody>{row.referenceNumber}<br />{currentDateInfo}</CustomTableBody>
                                <CustomTableBody>
                                    {`${currentUser?.first_name} ${currentUser?.last_name}`}<br />
                                    {currentCreatedAt}
                                </CustomTableBody>
                                <CustomTableBody>
                                    <IconButton onClick={() => handleDeleteClick(row.id)}>
                                        <Delete />
                                    </IconButton>
                                </CustomTableBody>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
            {rows.length === 0 && (
                <Box sx={{ height: 200, display: 'grid', placeItems: 'center' }}>
                    <Typography>ไม่พบข้อมูลไฟล์</Typography>
                </Box>
            )}
        </TableContainer>
    )
}

const CustomTableHeader = ({ children }) => {
    return (
        <TableCell
            align="center"
            sx={{
                bgcolor: 'var(--options-btn-bg-color-1)',
                color: 'white',
                fontWeight: '700'
            }}
        >
            {children}
        </TableCell>
    )
}

const CustomTableBody = ({ children }) => {
    return (
        <TableCell align="center">
            {children}
        </TableCell>
    )
}

const GridHeader = ({ children, alignItems }) => {
    return (
        <Grid
            item xs={4}
            display="flex"
            alignItems={alignItems || "center"}
            justifyContent="flex-end"
            sx={{ "& *": { fontWeight: "700" } }}
        >
            {children}
        </Grid>
    )
}

const GridForm = ({ children }) => {
    return (
        <Grid item xs={7}>
            {children}
        </Grid>
    )
}