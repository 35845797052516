import React from "react";

import Nav from "../../components/NavigationBar/NavigationBar";

import "./HomePage.css";

import { Navigate, Outlet } from "react-router-dom";

import useAuth from "../../hooks/useAuth";

function HomePage() {

  const { userID } = useAuth();

  if (!userID) return <Navigate to="/login" replace />

  return (
    <div className="homepage"  style={{ backgroundImage: `url(/bg-login.png)` }}>
        <div className="top-section" >
          <Nav />
        </div>
        <div className="mid">
          <Outlet />
        </div>
      </div>
  );
}

export default HomePage;
