import React, { useEffect, useState } from "react";
import './CoralLayer.css';

import { Backdrop, IconButton } from '@mui/material';
import { Close } from "@mui/icons-material";

import { Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'

import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

import useErrorHandler from "../../../hooks/useErrorHandler";
import useMapFilters from "../../../hooks/useMapFilters";

import ImageGallery from "react-image-gallery";

import { surveyAreaServices } from "../../../services/surveyAreaServices";
import { surveyPositionServices } from "../../../services/surveyPositionServices";
import { fileServices } from "../../../services/fileServices";

const customMarker = new L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40]
});

const placeholderImage = "https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png";

function CoralLayer() {
  const map = useMap();
  const [index, setIndex] = useState(-1);
  const [slideIndex, setSlideIndex] = useState(0);

  const { errorHandler } = useErrorHandler();
  const { filters, setFilters, setIslandList } = useMapFilters();

  const [data, setData] = useState([]);

  useEffect(() => {
    console.log("island id ", filters.islandId);
    const getSurveyPositionResolveInfoBySurveyAreaCode = async () => {
      try {
        const res = await surveyPositionServices.getSurveyPositionResolveInfoBySurveyAreaCode(filters.islandId);
        console.log("getSurveyPositionResolveInfoBySurveyAreaCode => ", res);
        if (res.status === 200) {
          const resData = res.data.data;
          setData(resData);

          if (resData.length) {
            const position = [resData[0].SurveyPositionInfo.latitude, resData[0].SurveyPositionInfo.longitude];
            map.flyTo(position, 13);
          }
        }
      } catch (error) {
        console.log(error);
        const { status, data } = error.response;
        errorHandler(
          'error: getSurveyPositionResolveInfoBySurveyAreaCode',
          status,
          data.message
        );
      }
    }

    filters.islandId && getSurveyPositionResolveInfoBySurveyAreaCode();
  }, [filters.islandId]);

  useEffect(() => {
    const getAllSurveyAreaByAreaBoundaryId = async () => {
      try {
        const res = await surveyAreaServices.getAllSurveyAreaByAreaBoundaryId(filters.areaBoundaryId);
        console.log("getAllSurveyAreaByAreaBoundaryId => ", res);
        if (res.status === 200) {
          setIslandList(res.data.data);
        }
      } catch (error) {
        console.log(error);
        const { status, data } = error.response;
        errorHandler(
          'error: getAllSurveyAreaByAreaBoundaryId',
          status,
          data.message
        );
      }
    }

    setIslandList([]);
    setData([]);
    setFilters(prev => ({ ...prev, islandId: '' }));
    filters.areaBoundaryId && getAllSurveyAreaByAreaBoundaryId();
  }, [filters.areaBoundaryId]);

  const getImagePreview = async (image, imageIndex) => {
    try {
      const res = await fileServices.getImagePreview(image);
      console.log(res);
      if (res.status === 200) {
        const imagePreview = res.data.data;
        setData(prev => {
          const currentData = prev[index];
          currentData.SurveyPositionImages[imageIndex].file.imagePreview = imagePreview;

          prev[index] = currentData;

          return [...prev];
        });
      }
    } catch (error) {
      console.log(error);
      const { status, data } = error.response;
      errorHandler(
        'error: getImagePreview',
        status,
        data.message
      );
    }
  }

  return (
    <div className="coral-layer">
      {data.map((position, index) => (
        <Marker
          key={position.id}
          icon={customMarker}
          position={[position.SurveyPositionInfo.latitude, position.SurveyPositionInfo.longitude]}
        >
          <CustomPopup
            info={position.SurveyPositionInfo}
            images={position.SurveyPositionImages}
            index={index}
            setIndex={setIndex}
            setData={setData}
          />
        </Marker>
      ))}

      {index !== -1 && (
        <Backdrop open sx={{ position: 'fixed', zIndex: 6000 }}>
          <IconButton
            sx={{ position: 'absolute', top: '8px', right: '8px', zIndex: 7000 }}
            onClick={() => {
              setIndex(-1);
              setSlideIndex(0);
            }}
          >
            <Close sx={{ color: 'white' }} />
          </IconButton>

          <div style={{ width: "90%" }}>
            <ImageGallery
              showFullscreenButton={false}
              showPlayButton={false}
              showThumbnails={false}
              startIndex={slideIndex}
              onSlide={(slideIndex) => {
                const currentImage = data[index].SurveyPositionImages[slideIndex].file;
                if (!currentImage.imagePreview) {
                  getImagePreview(currentImage, slideIndex);
                  setSlideIndex(slideIndex);
                }
              }}
              items={data[index].SurveyPositionImages.map((image) => ({
                original: image.file.imagePreview || placeholderImage,
                originalAlt: image.file.originalname,
                description: image.description,
              }))}
            />
          </div>
        </Backdrop>
      )}
    </div>
  )
}

export default CoralLayer;

const CustomPopup = ({ info, images, index, setIndex, setData }) => {
  const { errorHandler } = useErrorHandler();

  const [currentImages, setCurrentImages] = useState(images);

  useEffect(() => {
    const getImagePreview = async () => {
      try {
        const res = await fileServices.getImagePreview(currentImages[0].file);
        // console.log(res);
        if (res.status === 200) {
          const imagePreview = res.data.data;
          setCurrentImages(prev => {
            const currentImage = prev[0];
            currentImage.file.imagePreview = imagePreview;

            prev[0] = currentImage

            return [...prev];
          });
          setData(prev => {
            const currentData = prev[index];
            currentData.SurveyPositionImages[0].file.imagePreview = imagePreview;

            prev[index] = currentData;

            return [...prev];
          });
        }
      } catch (error) {
        console.log(error);
        const { status, data } = error.response;
        errorHandler(
          'error: getImagePreview',
          status,
          data.message
        );
      }
    }

    currentImages.length && !currentImages[0].file.imagePreview && getImagePreview();
  }, []);

  return (
    <Popup>
      {currentImages.length && (
        <img
          src={currentImages[0].file.imagePreview}
          width={180}
          alt=""
          onClick={() => setIndex(index)}
        />
      )}
      <p onClick={() => setIndex(index)}>
        ชื่อตำแหน่ง : {info.survey_position_name}
      </p>
    </Popup>
  )
}