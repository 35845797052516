import { useAxios } from "../guard/axiosConfig"

export const surveyResultReportServices = {
    getResultReportBySurveyResultId,
    createSurveyResultReport,
    updateSurveyResultReport,
    deleteSurveyResultReportById
}

const route = '/surveyResultReport';

function getResultReportBySurveyResultId(id) {
    return useAxios.get(`${route}/getResultReportBySurveyResultId/${id}`);
}

function createSurveyResultReport(data) {
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('reportFile', data.reportFile);
    formData.append('imageFile', data.imageFile);

    return useAxios.post(`${route}/createSurveyResultReport`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}

function updateSurveyResultReport(data) {
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('reportFile', data.reportFile);
    formData.append('imageFile', data.imageFile);

    return useAxios.put(`${route}/updateSurveyResultReport`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}

function deleteSurveyResultReportById(id) {
    return useAxios.delete(`${route}/deleteSurveyResultReportById/${id}`);
}