import React from 'react'
import './DragAndDrop.css'

import { Button, Typography } from '@mui/material'

import { useDropzone } from 'react-dropzone';

function DragAndDrop({ children, fileTypes, onDrop, maxFiles }) {
    const { getRootProps, getInputProps, open } = useDropzone({ 
        noClick: true, 
        accept: fileTypes,
        maxFiles,
        onDrop,
    });

    return (
        <>
            <div className="drag-and-drop" {...getRootProps()}>
                <input {...getInputProps()} />
                {
                    children ? (
                        children
                    ) : (
                        <Typography>
                            Drag and drop file(s) here or click button below
                        </Typography>
                    )
                }
            </div>
            <Button 
                fullWidth 
                variant="contained" 
                sx={{ 
                    mt: 1,
                    borderRadius: 6,
                    bgcolor: 'var(--options-btn-bg-color-5)',
                    '&:hover': { bgcolor: 'var(--options-btn-bg-hover-5)' },
                }}
                onClick={open}
            >
                CLICK TO UPLOAD FILE(S)
            </Button>
        </>
    )
}

export default DragAndDrop