import { useAxios } from "../guard/axiosConfig"

export const droneServices = {
    createDrone,
    getAllDrone,
    getDroneByDroneCode,
    updateStatus,
    updateDrone,
    deleteDroneById,
}

const route = '/drone';

function createDrone(data) {
    const formData = new FormData();
    formData.append('data', JSON.stringify({ ...data }));

    for (let index = 0; index < data.photos.length; index++) {
        const photo = data.photos[index];
        formData.append('photos', photo);
    }

    return useAxios.post(`${route}/createDrone`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}

function getAllDrone(filters) {
    return useAxios.get(`${route}/getAllDrone`, { params: filters });
}

function getDroneByDroneCode(droneCode) {
    return useAxios.get(`${route}/getDroneByDroneCode/${droneCode}`);
}

function updateStatus(data) {
    return useAxios.put(`${route}/updateStatus`, data);
}

function updateDrone(data) {
    const formData = new FormData();
    formData.append('data', JSON.stringify({ ...data }));

    if (data.photos) {
        for (let index = 0; index < data.photos.length; index++) {
            const photo = data.photos[index];
            formData.append('photos', photo);
        }
    }

    return useAxios.put(`${route}/updateDrone`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}

function deleteDroneById(id) {
    return useAxios.delete(`${route}/deleteDroneById/${id}`);
}