import axios from "axios"
import environments from "./environments";

const instance = axios.create({
    baseURL: environments.baseUrl,
    withCredentials: true,
    headers: { "Content-Type": "application/json" }
});

export const useAxios = instance;
