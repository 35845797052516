import React, { useState } from 'react'
import './ManageAircraftTeam.css'

import { Box, Grid, InputAdornment, TextField, Typography } from '@mui/material'

import { useNavigate } from 'react-router-dom';

import DataTable from '../../components/DataTable/DataTable'
import OptionButton from '../../components/OptionButton/OptionButton';
import DeleteButton from '../../components/TableButton/DeleteButton';
import EditButton from '../../components/TableButton/EditButton';
import StatusButton from '../../components/TableButton/StatusButton';
import ExcelButton from '../../components/ExcelButton/ExcelButton';

import { flightTeamServices } from '../../services/flightTeamServices';

import useDataTable from '../../hooks/useDataTable';
import useFlightTeam from '../../hooks/useFlightTeam';

const columns = [
    {
        field: 'row_num',
        headerName: 'ลำดับ',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
    },
    {
        field: 'team_name',
        headerName: 'ทีม',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        minWidth: 150,
        sortable: false,
    },
    {
        field: 'leader_name',
        headerName: 'หัวหน้าทีม',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        minWidth: 150,
        sortable: false,
        valueGetter: (param) => {
            const leader = param.row.Leader;
            return `${leader.first_name} ${leader.last_name}`
        }
    },
    {
        field: 'table_status',
        headerName: 'สถานะ',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        renderCell: (param) => <StatusButton data={param.row} service={flightTeamServices.updateStatus} />
    },
    {
        field: 'edit',
        headerName: 'แก้ไข',
        headerAlign: 'center',
        type: 'actions',
        sortable: false,
        renderCell: (param) => <EditButton id={param.row.id} formURL="manage-aircraft-team-form" />
    },
    {
        field: 'delete',
        type: 'actions',
        sortable: false,
        renderCell: (param) => <DeleteButton id={param.row.id} service={flightTeamServices.deleteFlightTeamById} />
    },
];

const initFilters = {
    search: '',
    dateFrom: '',
    dateTo: '',
}

function ManageAircraftTeam() {
    const navigate = useNavigate();

    const { paginationModel, resetPage } = useDataTable();

    const [filters, setFilters] = useState(initFilters);
    const [paginationfilters, setPaginationFilters] = useState(initFilters);

    const { flightTeamList: rows, rowCount } = useFlightTeam(
        { ...paginationfilters, ...paginationModel },
        [paginationModel.page, paginationfilters]
    );

    const handleFilterChange = ({ target }) => {
        const { name, value } = target;
        console.log(value);

        setFilters(prev => {
            return {
                ...prev,
                [name]: value,
            }
        });
    }

    const handleSearchClick = () => {
        console.log(filters);
        setPaginationFilters({ ...filters });
        resetPage();
    }

    const handleClearClick = () => {
        setFilters({ ...initFilters });
        setPaginationFilters({ ...initFilters });
        resetPage();
    }

    const handleAddClick = () => navigate('/dashboard/manage-aircraft-team-form');

    return (
        <div className="manage-aircraft-team">
            <Grid container className="filters" gap={1}>
                <Grid container item md={12} display="flex" alignItems="center">
                    <Grid item md={1}>
                        <Typography>ค้นหา</Typography>
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            fullWidth
                            size="small"
                            name="search"
                            value={filters.search}
                            onChange={handleFilterChange}
                        />
                    </Grid>
                </Grid>
                <Grid container item md={12} display="flex" alignItems="center">
                    <Grid item md={1}>
                        <Typography>วันที่เพิ่ม</Typography>
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            fullWidth
                            type="date"
                            size="small"
                            name="dateFrom"
                            value={filters.dateFrom}
                            onChange={handleFilterChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Box>From</Box>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            fullWidth
                            type="date"
                            size="small"
                            name="dateTo"
                            value={filters.dateTo}
                            onChange={handleFilterChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Box>To</Box>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid container className="options">
                <Grid item md={1}></Grid>
                <Grid
                    item xs={12} md={6}
                    sx={{
                        '& button': { width: '25%' },
                        '& :not(:nth-of-type(1))': { ml: 2 }
                    }}
                >
                    <OptionButton
                        text='ค้นหา'
                        bgColor='var(--options-btn-bg-color-1)'
                        hoverColor='var(--options-btn-bg-hover-1)'
                        onClick={handleSearchClick}
                    />
                    <OptionButton
                        text='ล้าง'
                        bgColor='var(--options-btn-bg-color-2)'
                        hoverColor='var(--options-btn-bg-hover-2)'
                        textColor='gray'
                        onClick={handleClearClick}
                    />
                    <OptionButton
                        text='เพิ่ม'
                        bgColor='var(--options-btn-bg-color-1)'
                        hoverColor='var(--options-btn-bg-hover-1)'
                        onClick={handleAddClick}
                    />
                </Grid>

                <ExcelButton modelName="FlightTeam" importCallback={handleClearClick} />
            </Grid>

            <DataTable
                columns={columns}
                rows={rows}
                rowCount={rowCount}
                pageProps={paginationModel}
            />
        </div>
    )
}

export default ManageAircraftTeam