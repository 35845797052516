import axios from "axios";

export const provinceServices = {
    getProvinceList,
}

const url = 'https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_province_with_amphure_tambon.json';

function getProvinceList() {
    return axios.get(url);
}