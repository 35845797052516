import React, { useRef } from 'react'
import './FileUploadButton.css'

import { BackupOutlined, Close } from '@mui/icons-material'
import { Box, IconButton, ListItem, ListItemText } from '@mui/material'

import OptionButton from '../OptionButton/OptionButton'

function FileUploadButton({ fileTypeText, mimeType, file, setFile }) {
    const inputRef = useRef();

    const handleFileChange = ({ target }) => {
        const currentFile = target.files;

        if (currentFile.length === 0) return;

        setFile({
            data: currentFile[0],
            originalname: currentFile[0].name,
        });
    }

    const handleDeleteFile = () => {
        inputRef.current.value = '';
        setFile(null);
    }

    return (
        <Box className="file-upload-button">
            <OptionButton
                input
                bgColor='var(--options-btn-bg-color-5)'
                hoverColor='var(--options-btn-bg-hover-5)'
                startIcon={<BackupOutlined />}
            >
                อัปโหลดไฟล์ {fileTypeText}
                <input
                    hidden
                    ref={inputRef}
                    type="file"
                    onChange={handleFileChange}
                    accept={mimeType}
                />
            </OptionButton>
            {file && (
                <ListItem
                    className="upload-filename"
                    secondaryAction={
                        <IconButton edge="end" onClick={handleDeleteFile}>
                            <Close />
                        </IconButton>
                    }
                >
                    <ListItemText primary={file?.originalname} />
                </ListItem>
            )}
        </Box>
    )
}

export default FileUploadButton