import React from 'react'

import { Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import { useNavigate } from 'react-router-dom';

function EditButton({ id, formURL }) {
    const navigate = useNavigate();

    const handleEditClick = () => navigate(`/dashboard/${formURL}/${id}`);

    return (
        <IconButton onClick={handleEditClick}>
            <Edit />
        </IconButton>
    )
}

export default EditButton