import { useAxios } from '../guard/axiosConfig'

export const flightInfoServices = {
    getFlightInfoByCode,
    createFlightInfo,
    updateFilghtInfo,
}

const route = '/flightInfo';

function getFlightInfoByCode(flightCode) {
    return useAxios.get(`${route}/getFlightInfoByCode/${flightCode}`);
}

function createFlightInfo(data) {
    return useAxios.post(`${route}/createFlightInfo`, data);
}

function updateFilghtInfo(data) {
    return useAxios.put(`${route}/updateFilghtInfo`, data);
}