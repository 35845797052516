import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import './SurveyResultForm.css';

import {
    FormControl, FormControlLabel, FormLabel,
    Grid, InputLabel, MenuItem, Radio,
    RadioGroup, Select, Tab, Tabs, Typography
} from '@mui/material';

import OptionButton from '../../components/OptionButton/OptionButton';
import AdditionalInfo from './Tabs/AdditionalInfo';
import MapInfo from './Tabs/MapInfo';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { surveyResultServices } from '../../services/surveyResultServices';

import useAreaBoundary from '../../hooks/useAreaBoundary';
import useCategory from '../../hooks/useCategory';
import useMapInfo from '../../hooks/useMapInfo';
import useErrorHandler from '../../hooks/useErrorHandler';

import SurveyResultMapInfoProvider from '../../contexts/SurveyResultMapInfoContext';

import Swal from 'sweetalert2';

const areaTypes = [
    { label: 'ชายฝั่งทะเล', value: 'sea-coast' },
    { label: 'ป่าชายเลน', value: 'mangrove-forest' },
]

const fileTypeList = {
    kmz: {
        value: 'kmz',
        label: 'KMZ',
    },
    tif: {
        value: 'tif',
        label: 'TIF',
    },
}

function SurveyResultForm() {
    const { id } = useParams();
    const navigate = useNavigate();

    const { errorHandler } = useErrorHandler();
    const { fileType, setFileType } = useMapInfo();

    const [surveyResult, setSurveyResult] = useState({
        id: '',
        areaBoundaryId: '',
        categoryId: '',
        areaType: 'sea-coast',
    });

    const { areaBoundaryList } = useAreaBoundary();
    const { categoryList } = useCategory();

    const [isEdit, setIsEdit] = useState(false);
    const isEditSurveyResultForm = (!surveyResult.id || isEdit);

    useEffect(() => {
        const getSurveyResultById = async (id) => {
            try {
                const res = await surveyResultServices.getSurveyResultById(id);
                console.log('getSurveyResultById => ', res);
                if (res.status === 200) {
                    const data = res.data.data;
                    setFileType(data.file_type);
                    setSurveyResult({
                        id: data.id,
                        areaBoundaryId: data.AreaBoundaryId,
                        categoryId: data.CategoryId,
                        areaType: data.area_type,
                    });
                }
            } catch (error) {
                console.log(error);
                const { status, data } = error.response;
                errorHandler(
                    'error: getSurveyResultById',
                    status,
                    data.message,
                    false,
                    goToDashboard
                );
            }
        }

        id && getSurveyResultById(id);
    }, [id]);

    const goToDashboard = () => navigate('/dashboard/survey-result');

    const handleEditClick = () => setIsEdit(true);

    const handleFileTypeChange = ({ target }) => setFileType(target.value);

    const handleSurveyResultChange = ({ target }) => {
        const { name, value } = target;

        setSurveyResult(prev => {
            return {
                ...prev,
                [name]: value,
            }
        });
    }

    const handleSaveClick = (e) => {
        e.preventDefault();
        const data = {
            areaBoundaryId: surveyResult.areaBoundaryId,
            categoryId: surveyResult.categoryId,
            area_type: surveyResult.areaType,
            file_type: fileType,
        }

        if (!id) {
            console.log(data);
            createSurveyResult(data);
        } else {
            const dataWithID = {
                ...data,
                id,
            }
            console.log(dataWithID);
            updateSurveyResult(dataWithID);
        }
    }

    const pushFormID = (id) => navigate(`/dashboard/survey-result-form/${id}`);

    const createSurveyResult = async (data) => {
        try {
            const res = await surveyResultServices.createSurveyResult(data);
            console.log(res);
            if (res.status === 201) {
                Swal.fire({
                    title: "บันทึกผลการสำรวจสำเร็จ",
                    icon: "success",
                }).then((result) => {
                    if (result.isConfirmed) {
                        pushFormID(res.data.id);
                    }
                });
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: createSurveyResult',
                status,
                data.message
            );
        }
    }

    const updateSurveyResult = async (data) => {
        try {
            const res = await surveyResultServices.updateSurveyResult(data);
            console.log(res);
            if (res.status === 201) {
                Swal.fire({
                    title: "แก้ไขผลการสำรวจสำเร็จ",
                    icon: "success",
                }).then((result) => {
                    if (result.isConfirmed) {
                        setIsEdit(false);
                    }
                });
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: updateSurveyResult',
                status,
                data.message
            );
        }
    }

    return (
        <Grid container className="survey-result-form">
            <form onSubmit={handleSaveClick} style={{ width: '100%' }} className="top-section">
                <Typography className="header">ข้อมูลพื้นฐาน</Typography>
                <Grid container item xs={12} columnGap={1} justifyContent="flex-end">
                    <OptionButton
                        disabled={isEditSurveyResultForm}
                        text="แก้ไข"
                        onClick={handleEditClick}
                        bgColor="var(--options-btn-bg-color-5)"
                        hoverColor="var(--options-btn-bg-hover-5)"
                    />
                    <OptionButton
                        disabled={!isEditSurveyResultForm}
                        text="บันทึก"
                        type="submit"
                        bgColor="var(--options-btn-bg-color-5)"
                        hoverColor="var(--options-btn-bg-hover-5)"
                    />
                </Grid>
                <Grid container item xs={12}>
                    <Grid item xs={12} px={3}>
                        <FormLabel
                            sx={{
                                fontWeight: 700,
                                color: 'var(--primary-text-color)',
                            }}
                        >
                            ประเภทพื้นที่
                        </FormLabel>
                        <RadioGroup
                            row
                            name="areaType"
                            value={surveyResult.areaType}
                            onChange={handleSurveyResultChange}
                        >
                            {areaTypes.map(type => {
                                return (
                                    <FormControlLabel
                                        key={type.value}
                                        disabled={!isEditSurveyResultForm}
                                        value={type.value}
                                        control={<Radio />}
                                        label={type.label}
                                        sx={{ color: 'var(--secondary-text-color)' }}
                                    />
                                )
                            })}
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12} px={3}>
                        <FormLabel
                            sx={{
                                fontWeight: 700,
                                color: 'var(--primary-text-color)',
                            }}
                        >
                            ประเภทไฟล์
                        </FormLabel>
                        <RadioGroup
                            row
                            name="fileType"
                            value={fileType}
                            onChange={handleFileTypeChange}
                        >
                            {Object.keys(fileTypeList)
                                .map(type => {
                                    const value = fileTypeList[type].value;
                                    const label = fileTypeList[type].label;
                                    return (
                                        <FormControlLabel
                                            key={type}
                                            disabled={!!id}
                                            value={value}
                                            control={<Radio />}
                                            label={label}
                                            sx={{ color: 'var(--secondary-text-color)' }}
                                        />
                                    )
                                })
                            }
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={6} p={3}>
                        <FormControl fullWidth>
                            <InputLabel id="target-area">จังหวัด</InputLabel>
                            <Select
                                required
                                displayEmpty
                                readOnly={!isEditSurveyResultForm}
                                labelId="target-area"
                                label="จังหวัด"
                                name="areaBoundaryId"
                                value={surveyResult.areaBoundaryId}
                                onChange={handleSurveyResultChange}
                            >
                                {areaBoundaryList.map(areaBoundary =>
                                    <MenuItem
                                        key={areaBoundary.id}
                                        value={areaBoundary.id}
                                        disabled={!areaBoundary.table_status}
                                    >
                                        {areaBoundary.target_area}
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6} p={3}>
                        <FormControl fullWidth>
                            <InputLabel id="category">หมวดหมู่</InputLabel>
                            <Select
                                required
                                displayEmpty
                                readOnly={!isEditSurveyResultForm}
                                labelId="category"
                                label="หมวดหมู่"
                                name="categoryId"
                                value={surveyResult.categoryId}
                                onChange={handleSurveyResultChange}
                            >
                                {categoryList.filter(item => item.area_type === surveyResult.areaType)
                                    .map(category =>
                                        <MenuItem
                                            key={category.id}
                                            value={category.id}
                                            disabled={!category.table_status}
                                        >
                                            {category.category_name}
                                        </MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </form>

            {!isEditSurveyResultForm && (
                <Grid item xs={12} p={1}>
                    <TabPanel />
                </Grid>
            )}
        </Grid>
    )
}

export default withSurveyResultMapInfoProvider(SurveyResultForm)

function withSurveyResultMapInfoProvider(WrappedComponent) {
    return (props) => (
        <SurveyResultMapInfoProvider>
            <WrappedComponent {...props} />
        </SurveyResultMapInfoProvider>
    );
}

const dataTabs = [
    {
        tabName: "ข้อมูลเพิ่มเติม",
        tabContent: <AdditionalInfo />,
    },
    {
        tabName: "ข้อมูลแผนที่",
        tabContent: <MapInfo />,
    },
]

const TabPanel = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [dataTabIndex, setDataTabIndex] = useState(0);
    const tabContent = useMemo(() => {
        return dataTabs[dataTabIndex].tabContent;
    }, [dataTabIndex]);

    useLayoutEffect(() => {
        if (searchParams.has("tab")) {
            const currentTabIndex = dataTabs.findIndex(tab => tab.tabName === searchParams.get("tab"));
            setDataTabIndex(currentTabIndex);
        }
    }, [searchParams])

    const handledataTabIndexChange = (event, newValue) => setDataTabIndex(newValue);

    const handleTabClick = (tabName) => setSearchParams({ tab: tabName });

    return (
        <>
            <Tabs
                variant="fullWidth"
                value={dataTabIndex}
                onChange={handledataTabIndexChange}
            >
                {dataTabs.map((tab) => (
                    <Tab
                        key={tab.tabName}
                        label={tab.tabName}
                        sx={{ fontWeight: 700 }}
                        onClick={() => handleTabClick(tab.tabName)}
                    />
                ))}
            </Tabs>

            {tabContent}
        </>
    )
}