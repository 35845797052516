import { createContext, useState } from 'react'

export const SurveyResultMapInfoContext = createContext();

function SurveyResultMapInfoProvider({ children }) {

    const [fileList, setFileList] = useState([]);
    const [fileType, setFileType] = useState('kmz');

    return (
        <SurveyResultMapInfoContext.Provider
            value={{
                fileList, setFileList,
                fileType, setFileType,
            }}
        >
            {children}
        </SurveyResultMapInfoContext.Provider>
    )
}

export default SurveyResultMapInfoProvider