import { createContext, useEffect, useState } from 'react'

import useAreaBoundary from '../hooks/useAreaBoundary';
import useCategory from '../hooks/useCategory';
import useMission from '../hooks/useMission';

export const MapFiltersContext = createContext();

const initFilters = {
    category: "",
    areaBoundaryId: "",
    missionId: "",
    islandId: "",
}

function MapFiltersProvider({ children }) {

    const [filters, setFilters] = useState(initFilters);

    const { areaBoundaryList } = useAreaBoundary();
    const { categoryList } = useCategory();
    const { missionList } = useMission();

    const [islandList, setIslandList] = useState([]);

    useEffect(() => {
        console.log(filters);
    }, [filters])

    const resetFilters = () => setFilters(initFilters);

    return (
        <MapFiltersContext.Provider
            value={{
                filters, setFilters, resetFilters,
                categoryList,
                areaBoundaryList,
                missionList,
                islandList, setIslandList,
            }}
        >
            {children}
        </MapFiltersContext.Provider>
    )
}

export default MapFiltersProvider