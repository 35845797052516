import "./NavigationBar.css";

import {
  AppBar,
  Avatar,
  IconButton,
  Toolbar,
  Typography,
  Button,
  Divider,
  Icon,
  Popover,
} from "@mui/material";
import { Settings } from "@mui/icons-material";

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";

function NavigationBar() {
  const navigate = useNavigate();

  const { userData, userID, logout } = useAuth();

  const [activeButton, setActiveButton] = useState(0);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? "profile-popover" : undefined;

  const buttons = [
    {
      id: 1,
      label: "ตำแหน่งภารกิจ",
      route: "/map",
      icon: "/Icon_Home-Map.png",
    },
    {
      id: 2,
      label: "สถานะอากาศยาน",
      route: "/drone",
      icon: "/Icon_Home-Drone.png",
    },
    {
      id: 3,
      label: "ปฏิทิน",
      route: "/calendar",
      icon: "/Icon_Home-Calendar.png",
    },
    {
      id: 4,
      label: "แผนและผลการบินสำรวจ",
      route: "/dashboard/flight-plan",
      icon: "/Icon_Home-Plan.png",
    },
  ];

  const handleMenuClick = (id) => {
    handleButtonClick(id);
    navigate(`${buttons.find((btn) => btn.id === id).route}`);
  };

  const handleButtonClick = () => {
    const activeButtonIndex = buttons.findIndex(
      (button) => button.route === currentRoute
    );
    setActiveButton(buttons[activeButtonIndex]?.id ?? -1);
  };

  const goToDashboard = () => navigate("/dashboard");

  const currentRoute = window.location.pathname;

  useEffect(() => {
    handleButtonClick();
  }, [currentRoute]);

  return (
    <div className="navigation">
      <div className="top-section">
        <AppBar
          component="nav"
          position="relative"
          sx={{
            background: "url(TT.svg) no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Toolbar sx={{ display: "flex" }}>
            <img
              src="logo-department-new.png"
              style={{
                width: "150px",
                aspectRatio: "3/2",
                objectFit: "contain",
              }}
              alt=""
            />
            <Divider
              flexItem
              orientation="vertical"
              variant="middle"
              sx={{ backgroundColor: "#666666" }}
            />
            <img
              src="mnre-logo.png"
              style={{
                width: "85px",
                aspectRatio: "3/2",
                objectFit: "contain",
              }}
              alt=""
            />
            <div
              className="info-section"
              style={{
                color: "#636363",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {buttons.map((button) => {
                if (
                  button.id === 4 &&
                  (!userData || userData.group.role === "USER")
                ) {
                  return null; // ไม่ render ปุ่มออกมาเลย
                }

                return (
                  <div
                    key={button.id}
                    className="info-item"
                    style={{ margin: "10px" }}
                  >
                    <Button
                      className={activeButton === button.id ? "active" : ""}
                      sx={{
                        color: "#fff",
                        fontSize: "1rem",
                        display: "flex",
                        alignItems: "center",
                      }}
                      startIcon={
                        <Icon>
                          <img
                            src={button.icon}
                            alt=""
                            style={{
                              width: "20px",
                              height: "20px",
                              objectFit: "contain",
                              display: "block",
                            }}
                          />
                        </Icon>
                      }
                      onClick={() => handleMenuClick(button.id)}
                    >
                      <Typography
                        sx={{
                          lineHeight: "2",
                          textAlign: "center",
                          fontSize: "1vw",
                          fontWeight: "bold",
                        }}
                      >
                        {button.label}
                      </Typography>
                    </Button>
                  </div>
                );
              })}
            </div>
            <div
              className="info-section"
              style={{
                marginLeft: "auto",
                height: "100%",
                width: "50%",
              }}
            >
              <img
                src="drone-logo.png"
                style={{
                  width: "80px",
                  height: "100%",
                  aspectRatio: "3/2",
                  objectFit: "cover",
                }}
                alt=""
              />
              <Typography
                sx={{
                  color: "#FFFFFF",
                  textAlign: "start",
                  lineHeight: "1.5",
                  paddingTop: "7px",
                  marginRight: "20px",
                  fontSize: "1vw",
                }}
              >
                ระบบจัดเก็บข้อมูล
                <br />
                และการแสดงผลข้อมูล
              </Typography>
              {userData ? (
                <IconButton onClick={handleOpenPopover}>
                  <Avatar
                    sx={{ width: 50, height: 50 }}
                    alt={userData.username}
                    src={userData?.username !== "GUEST" ? userData.photo?.imagePreview : undefined}
                  />
                </IconButton>
              ) : (
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  เข้าสู่ระบบ
                </Button>
              )}
            </div>
            <Popover
              id={popoverId}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <div
                style={{
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="user-info-section" style={{ display: "flex" }}>
                  {userData && (
                    <Avatar
                      sx={{ width: 50, height: 50 }}
                      alt={userData ? userData.username : ""}
                      src={userData?.username !== "GUEST" ? userData.photo?.imagePreview : undefined}
                    />
                  )}
                  <div className="user-info" style={{ marginLeft: "10px" }}>
                    <Typography sx={{ fontSize: "1.2rem" }}>
                      {userData ? userData.username : ""}
                    </Typography>
                    <Typography sx={{ fontSize: "1rem" }}>
                      {userData ? userData.first_name : ""}{" "}
                      {userData ? userData.last_name : ""}
                    </Typography>
                    {userData?.username !== "GUEST" && <Button
                      onClick={() => navigate("/profile")}
                      sx={{
                        color: "grey",
                        fontSize: "1rem",
                        paddingLeft: "-20px",
                      }}
                    >
                      ข้อมูลส่วนตัว
                    </Button>}
                  
                  </div>
                </div>

                {userData && userData.group.role !== "USER" && (
                  <Button
                    fullWidth
                    color="inherit"
                    onClick={goToDashboard}
                    style={{ color: "grey", marginRight: "auto" }}
                  >
                    <Settings />
                    <Typography sx={{ marginLeft: "25px", fontSize: "1.2rem" }}>
                      จัดการระบบ
                    </Typography>
                  </Button>
                )}
                <Button
                  onClick={() => logout(userID)}
                  sx={{
                    mt: 1,
                    color: "white",
                    background: "rgba(128, 128, 128, 0.5)",
                    fontSize: "1rem",
                    "&:hover": {
                      background: "rgba(128, 128, 128, 0.7)"
                    }
                  }}
                >
                  ออกจากระบบ
                </Button>
              </div>
            </Popover>
          </Toolbar>
        </AppBar>
      </div>
    </div>
  );
}

export default NavigationBar;
