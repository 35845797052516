import useAuth from './useAuth';

import { errorHandler as baseErrorHandler } from '../utils';

const AUTH_ERROR_CODE = [401, 403];

function useErrorHandler() {
    const { logout } = useAuth();

    const errorHandler = (defaultMessage, statusCode, customMessage, isToast = false, callback) => {
        if (AUTH_ERROR_CODE.includes(statusCode)) callback = logout;

        baseErrorHandler(defaultMessage, statusCode, customMessage, isToast, callback);
    }

    return { errorHandler };
}

export default useErrorHandler