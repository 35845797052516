import { useCallback, useEffect, useState } from 'react'

import { flyingAreaServices } from '../services/flyingAreaServices';

import useErrorHandler from './useErrorHandler';

function useFlyingArea(filters = {}, dependency = []) {
    const { errorHandler } = useErrorHandler();

    const [flyingAreaList, setFlyingAreaList] = useState([]);
    const [rowCount, setRowCount] = useState(0);

    const getAllFlyingArea = useCallback(async (currentFilters) => {
        try {
            const res = await flyingAreaServices.getAllFlyingArea(currentFilters);
            console.log("getAllFlyingArea => ", res);
            if (res.status === 200) {
                setFlyingAreaList(res.data.data);
                setRowCount(res.data.rowCount);
            }
        } catch (error) {
            console.log(error);
            errorHandler('error: getAllFlyingArea', error.response.status);
        }
    }, []);

    useEffect(() => {
        getAllFlyingArea(filters);
    }, dependency);

    return { flyingAreaList, rowCount };
}

export default useFlyingArea