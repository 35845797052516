import { useAxios } from "../guard/axiosConfig"

export const surveyPositionImageServices = {
    createSurveyPositionImage,
    getImageBySurveyPositionCode,
    updateSurveyPositionImage,
    deleteSurveyPositionImageById,
}

const route = '/surveyPositionImage';

function createSurveyPositionImage(data) {
    const formData = new FormData();
    formData.append('data', JSON.stringify({ ...data }));
    formData.append('file', data.file);

    return useAxios.post(`${route}/createSurveyPositionImage`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}

function getImageBySurveyPositionCode(code) {
    return useAxios.get(`${route}/getImageBySurveyPositionCode/${code}`);
}

function updateSurveyPositionImage(data) {
    const formData = new FormData();
    formData.append('data', JSON.stringify({ ...data }));
    formData.append('file', data.file);

    return useAxios.put(`${route}/updateSurveyPositionImage`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}

function deleteSurveyPositionImageById(id) {
    return useAxios.delete(`${route}/deleteSurveyPositionImageById/${id}`);
}