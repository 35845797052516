import { 
    ConnectingAirportsOutlined, EventNoteOutlined, 
    FeedOutlined, GroupsOutlined, MapOutlined, PersonOutline, 
    RoomOutlined, ImportContactsOutlined, WaterOutlined 
} from '@mui/icons-material'        

export const sidebarMenu = [
    {
        menuName: 'ภาคพื้นดิน',
        icon: <ImportContactsOutlined />,
        path: '/overview'
    },
    {
        menuName: 'เส้นเขตพื้นที่',
        icon: <MapOutlined />,
        path: '/manage-area-boundary'
    },
    {
        menuName: 'ทีมบิน',
        icon: <GroupsOutlined />,
        path: '/manage-aircraft-team'
    },
    {
        menuName: 'อากาศยาน',
        icon: <ConnectingAirportsOutlined />,
        path: '/manage-drone'
    },
    {
        menuName: 'พื้นที่เป้าหมาย',
        icon: <RoomOutlined />,
        path: '/manage-flight-area'
    },
    {
        menuName: 'แผนและรายงานการสำรวจ',
        icon: <FeedOutlined />,
        path: '/flight-plan'
    },
    {
        menuName: 'ผลการสำรวจ',
        icon: <FeedOutlined />,
        path: '/survey-result'
    },
    {
        menuName: 'สำรวจปะการัง',
        icon: <WaterOutlined />,
        path: '/manage-coral'
    },
    {
        menuName: 'จัดการสมาชิก',
        icon: <PersonOutline />,
        path: '/manage-user'
    },
    {
        menuName: 'จัดการกลุ่มผู้ใช้',
        icon: <GroupsOutlined />,
        path: '/manage-group'
    },
    {
        menuName: 'LOG การใช้งานของผู้ใช้งาน',
        icon: <EventNoteOutlined />,
        path: '/user-log'
    },
    {
        menuName: 'LOG การใช้งานของผู้ดูแลระบบ',
        icon: <EventNoteOutlined />,
        path: '/admin-log'
    },
]