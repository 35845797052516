import { useAxios } from "../guard/axiosConfig"

export const fileServices = {
    getImagePreview,
}

const route = '/file';

function getImagePreview(file) {
    return useAxios.get(`${route}/getImagePreview`, { params: file });
}