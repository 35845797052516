import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import "./Calendar.css";
import "moment/locale/th";
import { Button } from "@mui/material";

function CustomToolbar({ label, onNavigate, onView, view }) {
  // const [month, year] = label.split(" ");
  // const labelShow = `${month} ${parseInt(year) + 543}`;
  console.log(view);
  return (
    <div className="rbc-toolbar">
      <div className="rbc-btn-group">
        <Button
          type="button"
          className="rbc-btn-nav"
          style={{ borderRadius: "7px" }}
          onClick={() => onNavigate("PREV")}
        >
          <FaChevronLeft />
        </Button>
        <Button
          type="button"
          className="rbc-btn-nav"
          style={{ borderRadius: "7px" }}
          onClick={() => onNavigate("NEXT")}
        >
          <FaChevronRight />
        </Button>
      </div>
      <span className="rbc-btn-group">
        <Button
          type="button"
          style={{ borderRadius: "10px" }}
          className="rbc-btn rbc-btn-primary rbc-btn-today"
          onClick={() => onView("day")}
        >
          วันนี้
        </Button>
      </span>
      <span className="rbc-toolbar-label">{label}</span>
      
        <span className="rbc-btn-group">
          <Button
            type="button"
            className="rbc-btn rbc-btn-primary rbc-btn-today"
            style={{ borderRadius: "10px" }}
            onClick={() => onView("month")}
          >
            เดือน
          </Button>
        </span>
        <span className="rbc-btn-group">
          <Button
            type="button"
            style={{ borderRadius: "10px" }}
            className="rbc-btn rbc-btn-primary rbc-btn-today"
            onClick={() => onView("agenda")}
          >
            รายการ
          </Button>
        </span>
    </div>
  );
}
export default CustomToolbar;
