import Swal from 'sweetalert2';

import { kmlServices } from '../services/kmlServices';

import useErrorHandler from './useErrorHandler';

function useFile() {
    const { errorHandler } = useErrorHandler();

    const getKmlFile = async (filename) => {
        try {
            const res = await kmlServices.getKmlFile(filename);
            console.log('getKmlFile => ', res);
            if (res.status === 200) {
                const blobData = res.data;
                return blobData;
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: getKmlFile',
                status,
                data.message
            );
        }
    }

    const downloadFile = async (file) => {
        const blob = await getKmlFile(file.filename);

        if (!blob) return Swal.fire({
            title: "พบข้อผิดพลาด",
            text: "ไม่สามารถดาวน์โหลดได้",
            icon: "error",
        });

        const fileUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = file.originalname;
        document.body.appendChild(link);
        link.click();
    }

    const createFile = async (file) => {
        const blob = await getKmlFile(file.filename);

        if (!blob) return;

        const newFile = new File(
            [blob],
            file.originalname,
            { type: blob.type }
        );

        return {
            data: newFile,
            originalname: file.originalname,
        }
    }

    return { downloadFile, createFile }
}

export default useFile