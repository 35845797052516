import React, { useEffect, useState } from 'react'
import './FlightZipFile.css'

import { useSearchParams } from 'react-router-dom';

import { Badge, IconButton, TextField, Typography } from '@mui/material';
import { BackupOutlined, Delete, Edit, FileDownload, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

import { flightZipFileServices } from '../../../../services/flightZipFileServices';

import OptionButton from '../../../../components/OptionButton/OptionButton';

import useErrorHandler from '../../../../hooks/useErrorHandler';
import useFile from '../../../../hooks/useFile';

const initFile = {
    data: null,
    originalname: "",
}

function FlightZipFile() {
    const [searchParams] = useSearchParams();

    const { errorHandler } = useErrorHandler();

    const [file, setFile] = useState(null);

    const [uploadedFile, setUploadedFile] = useState(initFile);
    const [filename, setFilename] = useState("");

    const [isEdit, setEdit] = useState(false);
    const [isFileChange, setIsFileChange] = useState(false);
    const [loading, setLoading] = useState(false);
    const [percent, setPercent] = useState(0);

    useEffect(() => {
        const getByFlightCode = async () => {
            try {
                const res = await flightZipFileServices.getByFlightCode(searchParams.get("flightCode"));
                console.log("getByFlightCode => ", res);
                if (res.status === 200) {
                    const data = res.data.data;
                    if (data) {
                        setFile(data);
                        setFilename(data.file_name);
                        setUploadedFile({ data: true });
                    }
                }
            } catch (error) {
                console.log(error);
                const { status, data } = error.response;
                errorHandler(
                    'error: getByFlightCode',
                    status,
                    data.message
                );
            }
        }

        searchParams.has("flightCode") && getByFlightCode();
    }, [searchParams]);

    const handleFilenameChange = ({ target }) => setFilename(target.value);

    const handleEditClick = () => setEdit(true);

    const handleAddFileClick = async (e) => {
        e.preventDefault();
        const data = {
            flightZipFile: {
                flight_code: searchParams.get("flightCode"),
                file_name: filename,
                hasNewFile: isFileChange,
            },
            file: uploadedFile?.data,
        }
        console.log(data);

        setLoading(true);

        if (!isEdit) {
            createFlightZipFile(data);
        } else {
            updateFlightZipFile(data);
        }
    }

    const createFlightZipFile = async (data) => {
        try {
            const res = await flightZipFileServices.createFlightZipFile(data, setPercent);
            console.log("createFlightZipFile => ", res);
            if (res.status === 201) {
                const resData = res.data;
                setFile({
                    id: resData.id,
                    file_name: filename,
                    file: resData.file,
                });
                setIsFileChange(false);
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: createFlightZipFile',
                status,
                data.message
            );
        } finally {
            setLoading(false);
        }
    }

    const updateFlightZipFile = async (data) => {
        try {
            const res = await flightZipFileServices.updateFlightZipFile({ ...data, id: file.id }, setPercent);
            console.log("updateFlightZipFile => ", res);
            if (res.status === 201) {
                const resData = res.data;
                setFile({
                    id: resData.id,
                    file_name: filename,
                    file: resData.file,
                });
                setEdit(false);
                setIsFileChange(false);
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: updateFlightZipFile',
                status,
                data.message
            );
        } finally {
            setLoading(false);
        }
    }

    const handleDeleteClick = async () => {
        try {
            const res = await flightZipFileServices.deleteFlighZipFileById(file.id);
            console.log(res);
            if (res.status === 200) {
                setFile(null);
                setFilename("");
                setUploadedFile(initFile);
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: deleteFlighZipFileById',
                status,
                data.message
            );
        }
    }

    return (
        <div className="flight-zip">
            <form onSubmit={handleAddFileClick} className="zip-upload-form">
                <Typography sx={{ fontWeight: 700 }}>ชื่อไฟล์</Typography>
                <TextField
                    required
                    size="small"
                    placeholder='(ไม่ซ้ำกัน)'
                    InputProps={{
                        readOnly: !(!file || isEdit),
                    }}
                    value={filename}
                    onChange={handleFilenameChange}
                />

                {
                    (!file || isEdit) ? (
                        <UploadButton
                            uploadedFile={uploadedFile}
                            setUploadedFile={setUploadedFile}
                            setIsFileChange={setIsFileChange}
                        />
                    ) : (
                        <DownloadButton file={file} />
                    )
                }

                {
                    (!file || isEdit) ? (
                        <LoadingButton
                            variant="contained"
                            type="submit"
                            loading={loading}
                            loadingPosition="start"
                            startIcon={<Save />}
                            disabled={!uploadedFile.data}
                            sx={{
                                bgcolor: "var(--options-btn-bg-color-1)",
                                '&:hover': { bgcolor: "var(--options-btn-bg-hover-1)" },
                                fontWeight: 700,
                                borderRadius: "12px",
                            }}
                        >
                            {loading ? (
                                <span>กำลังอัปโหลดไฟล์ ({percent}%)</span>
                            ) : (
                                <span>บันทึก</span>
                            )}
                        </LoadingButton>
                    ) : (
                        <div className="btn">
                            <IconButton onClick={handleEditClick}>
                                <Edit />
                            </IconButton>
                            <IconButton onClick={handleDeleteClick}>
                                <Delete />
                            </IconButton>
                        </div>
                    )
                }

            </form>
        </div>
    )
}

export default FlightZipFile

const UploadButton = ({ uploadedFile, setUploadedFile, setIsFileChange }) => {

    const handleFileChange = ({ target }) => {
        const files = target.files;
        console.log(files);
        if (files.length === 0) return;

        const currentFile = files[0];

        setIsFileChange(true);
        setUploadedFile({
            data: currentFile,
            originalname: currentFile.name,
        });
    };

    return (
        <Badge variant="dot" color="primary" invisible={!uploadedFile.data}>
            <OptionButton
                input
                bgColor='var(--options-btn-bg-color-5)'
                hoverColor='var(--options-btn-bg-hover-5)'
                startIcon={<BackupOutlined />}
            >
                อัปโหลดไฟล์ชนิด ZIP 15GB {uploadedFile.data && " (1 ไฟล์)"}
                <input
                    hidden
                    type="file"
                    onChange={handleFileChange}
                    accept=".zip"
                />
            </OptionButton>
        </Badge>
    )
}

const DownloadButton = ({ file }) => {
    const { downloadFile } = useFile();

    const handleDownloadClick = async () => {
        const currentFile = file.file;
        console.log(currentFile);
        await downloadFile(currentFile);
    }

    return (
        <OptionButton
            bgColor="var(--options-btn-bg-color-1)"
            hoverColor="var(--options-btn-bg-hover-1)"
            onClick={handleDownloadClick}
            startIcon={<FileDownload />}
        >
            ดาวน์โหลด
        </OptionButton>
    )
}