import { useCallback, useEffect, useState } from 'react'

import { kmlServices } from '../services/kmlServices';

import { thaiCenter } from '../constants/mapConstants';

import useErrorHandler from './useErrorHandler';

function useKmlGeoJson(file) {
    const { errorHandler } = useErrorHandler();

    const [kml, setKml] = useState(null);
    const [center, setCenter] = useState(thaiCenter);

    const getKmlGeoJson = useCallback(async (file) => {
        try {
            const res = await kmlServices.getKmlGeoJson(file.data);
            console.log('getKmlGeoJson => ', res);
            if (res.status === 200) {
                const data = res.data.data;
                setCenter(data.center);
                const parser = new DOMParser();
                const kmlData = parser.parseFromString(data.kml, "text/xml");
                setKml(kmlData);
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: getKmlGeoJson',
                status,
                data.message
            );
        }
    }, []);

    useEffect(() => { file && getKmlGeoJson(file) }, [file])

    return {
        kml, setKml,
        center, setCenter,
        getKmlGeoJson,
    }
}

export default useKmlGeoJson