import React, { useEffect, useState } from 'react'
import './FlightRefenceUrl.css'

import { useSearchParams } from 'react-router-dom';

import { IconButton, Link, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography } from '@mui/material';
import { Delete, Launch } from '@mui/icons-material';

import { flightReferenceUrlServices } from '../../../../services/flightReferenceUrlServices';

import OptionButton from '../../../../components/OptionButton/OptionButton';

import useErrorHandler from '../../../../hooks/useErrorHandler';

function FlightRefenceUrl() {
    const [url, setUrl] = useState("");
    const [urlList, setUrlList] = useState([]);
    const [searchParams] = useSearchParams();

    const { errorHandler } = useErrorHandler();

    useEffect(() => {
        const getByFlightCode = async () => {
            try {
                const res = await flightReferenceUrlServices.getByFlightCode(searchParams.get("flightCode"));
                console.log('getByFlightCode => ', res);
                if (res.status === 200) {
                    const data = res.data.data;
                    if (data) {
                        setUrlList(data.url);
                    }
                }
            } catch (error) {
                console.log(error);
                const { status, data } = error.response;
                errorHandler(
                    'error: getByFlightCode',
                    status,
                    data.message
                );
            }
        }

        searchParams.has("flightCode") && getByFlightCode();
    }, [searchParams]);

    const handleUrlChange = ({ target }) => setUrl(target.value);

    const handleOpenNewTab = (url) => window.open(url, '_blank', 'noopener,noreferrer');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            flight_code: searchParams.get("flightCode"),
            url: [...urlList, url],
        }
        console.log(data);

        try {
            const res = await flightReferenceUrlServices.upsert(data);
            console.log(res);
            if (res.status === 201) {
                setUrlList(prev => {
                    return [...prev, url]
                });
                setUrl("");
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: upsert',
                status,
                data.message
            );
        }
    }

    return (
        <div className="flight-reference-url">
            <form onSubmit={handleSubmit} className="url-form">
                <Typography sx={{ fontWeight: 700 }}>ลิงก์</Typography>
                <TextField
                    required
                    size="small"
                    placeholder='URL'
                    type='url'
                    value={url}
                    onChange={handleUrlChange}
                />
                <OptionButton
                    text="บันทึก"
                    type="submit"
                    bgColor="var(--options-btn-bg-color-1)"
                    hoverColor="var(--options-btn-bg-hover-1)"
                />
            </form>

            <List>
                {urlList.map((url, index) => (
                    <ListItem
                        key={url.id || index}
                        secondaryAction={
                            <IconButton>
                                <Delete />
                            </IconButton>
                        }
                    >
                        <ListItemAvatar>
                            <IconButton onClick={() => handleOpenNewTab(url)}>
                                <Launch />
                            </IconButton>
                        </ListItemAvatar>

                        <ListItemText className="list-item">
                            <Link
                                target='_blank'
                                rel="noopener noreferrer"
                                href={url}
                                underline="hover"
                                sx={{ "&:hover": { cursor: "pointer" } }}
                            >
                                {url}
                            </Link>
                        </ListItemText>
                    </ListItem>
                ))}
            </List>
        </div>
    )
}

export default FlightRefenceUrl