import { useAxios } from '../guard/axiosConfig'

export const flightServices = {
    createFlight,
    getAllFlightsByFlightMissionCode,
    getAllFlightsByFlightMissionCodes
}

const route = '/flight';

function createFlight(data) {
    return useAxios.post(`${route}/createFlight`, data);
}

function getAllFlightsByFlightMissionCode(flightMissionCode) {
    return useAxios.get(`${route}/getAllFlightsByFlightMissionCode/${flightMissionCode}`);
}

function getAllFlightsByFlightMissionCodes(flightMissionCodes) {
    return useAxios.get(`${route}/getAllFlightsByFlightMissionCodes`, {
      params: {
        flightMissionCodes: flightMissionCodes
      }
    });
  }
