import React, { useEffect, useState } from 'react'
import './ManageAreaBoundaryForm.css'

import { Grid, IconButton, TextField, Typography } from '@mui/material'
import { Close, CloudUpload } from '@mui/icons-material'

import DragAndDrop from '../../components/DragAndDrop/DragAndDrop';
import OptionButton from '../../components/OptionButton/OptionButton';

import { useNavigate, useParams } from 'react-router-dom';

import { areaBoundaryServices } from '../../services/areaBoundaryServices';

import useErrorHandler from '../../hooks/useErrorHandler';
import useFile from '../../hooks/useFile';
import useKmlGeoJson from '../../hooks/useKmlGeoJson';

import { openStreetMapConfig, thaiCenter } from '../../constants/mapConstants';

import { MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'

import KML from 'react-leaflet-kml'

const fileTypes = { "application/vnd": [".kml", ".kmz"] };

function ManageAreaBoundaryForm() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [targetArea, setTargetArea] = useState('');
    const [file, setFile] = useState(null);
    const [isFileChange, setIsFileChange] = useState(false);

    const { errorHandler } = useErrorHandler();
    const { createFile } = useFile();
    const {
        kml, setKml,
        center, setCenter
    } = useKmlGeoJson(file);

    useEffect(() => {
        const getAreaBoundaryById = async () => {
            try {
                const res = await areaBoundaryServices.getAreaBoundaryById(id);
                console.log('getAreaBoundaryById', res);
                if (res.status === 200) {
                    const data = res.data.data;
                    setTargetArea(data.target_area);

                    if (data.area_boundary_file) {
                        const fileData = await createFile(data.area_boundary_file);
                        setFile(fileData);
                    }
                }
            } catch (error) {
                console.log(error);
                const { status, data } = error.response;
                errorHandler(
                    'error: getAreaBoundaryById',
                    status,
                    data.message,
                    false,
                    goToDashboard
                );
            }
        }

        id && getAreaBoundaryById();
    }, [id]);

    const handleDeleteFile = () => {
        setFile(null);
        setIsFileChange(true);
        setCenter(thaiCenter);
        setKml(null);
    };

    const handleFileChange = (acceptedFiles) => {
        console.log(acceptedFiles);
        if (acceptedFiles.length === 0) return;

        setIsFileChange(true);
        setFile({
            data: acceptedFiles[0],
            originalname: acceptedFiles[0].name,
        });
    };

    const handleTargetAreaChange = ({ target }) => setTargetArea(target.value);

    const handleSaveClick = (e) => {
        e.preventDefault();
        const data = {
            target_area: targetArea,
            file: isFileChange ? file?.data : null,
            hasNewFile: isFileChange,
        }
        console.log(data);

        if (!id) {
            createAreaBoundary(data);
        } else {
            updateAreaBoundary(data);
        }
    }

    const goToDashboard = () => navigate('/dashboard/manage-area-boundary');

    const createAreaBoundary = async (data) => {
        try {
            const res = await areaBoundaryServices.createAreaBoundary(data);
            console.log(res);
            if (res.status === 201) {
                console.log('area created');
                goToDashboard();
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: createAreaBoundary',
                status,
                data.message
            );
        }
    }

    const updateAreaBoundary = async (data) => {
        try {
            const res = await areaBoundaryServices.updateAreaBoundary({ ...data, id });
            console.log(res);
            if (res.status === 201) {
                console.log('area updated');
                goToDashboard();
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: updateAreaBoundary',
                status,
                data.message
            );
        }
    }

    return (
        <form onSubmit={handleSaveClick}>
            <Grid container className="manage-area-boundary-form">
                <Grid container item xs={6} padding={3}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            value={targetArea}
                            onChange={handleTargetAreaChange}
                            placeholder="พื้นที่เป้าหมาย"
                        />
                    </Grid>

                    <Grid item xs={12} className="file-upload">
                        <div className="header">
                            <CloudUpload className="icon" />
                            <Typography className="header-text">
                                อัปโหลดเส้นเขตพื้นที่ (ไฟล์ชนิด .KML .KMZ)
                            </Typography>
                        </div>
                        <DragAndDrop fileTypes={fileTypes} maxFiles={1} onDrop={handleFileChange}>
                            {file && (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography>{file.originalname}</Typography>
                                    <IconButton onClick={handleDeleteFile}>
                                        <Close />
                                    </IconButton>
                                </div>
                            )}
                        </DragAndDrop>
                    </Grid>
                </Grid>

                <Grid item xs={6}>
                    {
                        file && (
                            <MapContainer center={center} zoom={10} style={{ height: '100%' }} key={center.toString()}>
                                <TileLayer {...openStreetMapConfig} />
                                {kml && <KML kml={kml} />}
                            </MapContainer>
                        )
                    }
                </Grid>

                <Grid item xs={12}>
                    <div className="btn">
                        <OptionButton
                            text="Save"
                            type="submit"
                            bgColor="var(--options-btn-bg-color-5)"
                            hoverColor="var(--options-btn-bg-hover-5)"
                        />
                        <OptionButton
                            text="Cancel"
                            bgColor="var(--options-btn-bg-color-2)"
                            hoverColor="var(--options-btn-bg-hover-2)"
                            textColor="gray"
                            onClick={goToDashboard}
                        />
                    </div>
                </Grid>
            </Grid>
        </form>
    )
}

export default ManageAreaBoundaryForm