import { createContext } from 'react'

import useDrone from '../hooks/useDrone';
import useFlightTeam from '../hooks/useFlightTeam';
import useFlyingArea from '../hooks/useFlyingArea';
import useMission from '../hooks/useMission';
import useUser from '../hooks/useUser';


export const FlightDataListContext = createContext();

function FlightDataListProvider({ children }) {

    const { missionList } = useMission();
    const { userList } = useUser();
    const { droneList } = useDrone();
    const { flyingAreaList } = useFlyingArea();
    const { flightTeamList } = useFlightTeam();

    return (
        <FlightDataListContext.Provider
            value={{
                flyingAreaList,
                missionList,
                userList,
                droneList,
                flightTeamList,
            }}
        >
            {children}
        </FlightDataListContext.Provider>
    )
}

export default FlightDataListProvider