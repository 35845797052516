import environments from "../guard/environments";

export const thaiCenter = [13.03887, 101.490104];

export const openStreetMapConfig = {
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    subdomains: ['a', 'b', 'c'] ,
    attribution: 'Map Data &copy; <a href="https://openstreetmap.org">OpenStreetMap</a> contributors',
}

export const username = environments.mapbox.username;
const style_id = environments.mapbox.satelliteStyleID;
const tilesize = 256;
export const access_token = environments.mapbox.uploadToken;

export const satelliteMapConfig = {
    url: `https://api.mapbox.com/styles/v1/${username}/${style_id}/tiles/${tilesize}/{z}/{x}/{y}?access_token=${access_token}`,
    attribution: 'Mapbox Satellite Streets',
}