import React from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

import DroneStatusLabel from "../../../components/Drone/DroneStatusLabel";

function DroneCard({ cardData, onClick }) {

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        borderRadius: "10px",
      }}
    >
      {cardData.photos?.[0]?.imagePreview ? (
        <CardMedia
          sx={{ height: 200, width: "50%" }}
          component="img"
          src={cardData.photos[0].imagePreview}
          title={cardData.id}
        />
      ) : (
        <CardMedia component="div" sx={{ height: 200, width: "50%" }} title={cardData.id} />
      )}
      <CardContent sx={{ width: "30%", ml: "auto" }}>
        <div style={{ width: "100%" }}>
          <div style={{ height: "auto", wordWrap: "break-word" }}>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              style={{ fontSize: "1.2rem" }}
            >
              {cardData.drone_code}
            </Typography>
          </div>
        </div>
        <DroneStatusLabel drone_status={cardData.drone_status} />
        <Button onClick={onClick}>
          รายละเอียด...
        </Button>
      </CardContent>
    </Card>
  );
}

export default DroneCard;
