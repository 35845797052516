import React from 'react'

import { Grid } from '@mui/material'

import OptionButton from '../OptionButton/OptionButton'

import { excelServices } from '../../services/excelServices';

import useErrorHandler from '../../hooks/useErrorHandler';

function ExcelButton({ modelName, importCallback }) {
    const { errorHandler } = useErrorHandler();

    const handleExportClick = async () => {
        try {
            const res = await excelServices.exportExcel(modelName);
            console.log(res);
            if (res.status === 200) {
                const file = new Blob([res.data], { type: "application/vnd.ms-excel" });
                const fileUrl = URL.createObjectURL(file);
                const link = document.createElement("a");
                link.href = fileUrl;
                link.download = `${modelName}.xlsx`;
                document.body.appendChild(link);
                link.click();
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: exportExcel',
                status,
                data.message
            );
        }
    }

    const handleImportClick = async ({ target }) => {
        const data = {
            modelName,
            file: target.files[0],
            sheetName: 'Sheet1',
        }

        try {
            const res = await excelServices.importExcel(data);
            console.log(res);
            if (res.status === 200) {
                console.log('import success');
                importCallback();
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: importExcel',
                status,
                data.message
            );
        } finally {
            target.value = null;
        }
    }

    return (
        <Grid
            item xs={12} md={4}
            sx={{
                display: 'flex',
                '& :is(button, label)': { minWidth: '155px' },
                '& :last-child': { ml: 2 }
            }}
        >
            <OptionButton
                input
                disabled
                text='Import Excels'
                bgColor='var(--options-btn-bg-color-3)'
                hoverColor='var(--options-btn-bg-hover-3)'
            >
                Import Excels
                <input
                    hidden
                    type="file"
                    onChange={handleImportClick}
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
            </OptionButton>
            <OptionButton
                disabled
                text='Export Excels'
                bgColor='var(--options-btn-bg-color-4)'
                hoverColor='var(--options-btn-bg-hover-4)'
                onClick={handleExportClick}
            />
        </Grid>
    )
}

export default ExcelButton