import { useCallback, useEffect, useState } from 'react'

import { droneTypeServices } from '../services/droneTypeServices';

import useErrorHandler from './useErrorHandler';

function useDroneType(filters = {}, dependency = [], onSuccess) {
    const { errorHandler } = useErrorHandler();

    const [droneTypeList, setDroneTypeList] = useState([]);
    const [rowCount, setRowCount] = useState(0);

    const getAllDroneType = useCallback(async (currentFilters) => {
        try {
            const res = await droneTypeServices.getAllDroneType(currentFilters);
            console.log("getAllDroneType => ", res);
            if (res.status === 200) {
                const data = res.data.data;
                onSuccess ? onSuccess(data, setDroneTypeList) : setDroneTypeList(data);
                setRowCount(res.data.rowCount);
            }
        } catch (error) {
            console.log(error);
            errorHandler('error: getAllDroneType', error.response.status);
        }
    }, []);

    useEffect(() => {
        getAllDroneType(filters);
    }, dependency);

    return { droneTypeList, setDroneTypeList, rowCount, getAllDroneType };
}

export default useDroneType