import React, { useState } from 'react'

import { Switch } from '@mui/material';

import useErrorHandler from '../../hooks/useErrorHandler';

function StatusButton({ data, service }) {
    const [currentStatus, setCurrentStatus] = useState(data.table_status);

    const { errorHandler } = useErrorHandler();

    const handleStatusChange = async () => {
        const newData = {
            id: data.id,
            table_status: !currentStatus,
        }

        console.log(newData);

        try {
            const res = await service(newData);
            console.log(res);
            if (res.status === 201) {
                setCurrentStatus(!currentStatus);
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                `error: ${service.name}`,
                status,
                data.message
            );
        }
    }

    return (
        <Switch checked={currentStatus} onChange={handleStatusChange} />
    )
}

export default StatusButton