import Swal from "sweetalert2";
import moment from "moment";

const base64ImageToFile = (str, filename) => {
    // extract content type and base64 payload from original string
    const pos = str.indexOf(';base64,');
    const type = str.substring(5, pos);
    const b64 = str.substr(pos + 8);

    // decode base64
    const imageContent = atob(b64);

    // create an ArrayBuffer and a view (as unsigned 8-bit)
    const buffer = new ArrayBuffer(imageContent.length);
    const view = new Uint8Array(buffer);

    // fill the view, using the decoded base64
    for (let n = 0; n < imageContent.length; n++) {
        view[n] = imageContent.charCodeAt(n);
    }

    // convert ArrayBuffer to File
    const file = new File([buffer], filename, { type });

    return file;
}

function formatFileSize(fileSizeInBytes) {
    const sizes = ['bytes', 'KB', 'MB', 'GB'];
    var i = 0;
    while (fileSizeInBytes >= 1024 && i < sizes.length - 1) {
        fileSizeInBytes /= 1024;
        i++;
    }
    return fileSizeInBytes.toFixed(2) + ' ' + sizes[i];
}

const hashString = (str) => {
    var hash = 0, i, chr;

    for (i = 0; i < Math.min(str.length, 255); i++) {
        chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }

    return hash;
};

const jsonToQueryParam = (filters) => {
    let strParams = [];
    Object.keys(filters).map(key => {
        const filter = filters[key];

        if (Array.isArray(filter)) {
            filter.forEach(val => {
                strParams.push(`${key}[]=${val}`);
            })
        }

        if (typeof filter === "number" || typeof filter === "string") {
            strParams.push(`${key}=${filter}`);
        }

        return null
    }).join("&");

    return "?" + strParams.join("&")
}

function getCalendarRange(year, month) {
    // สร้างวันที่แรกและวันที่สุดท้ายของเดือนที่กำหนด
    let firstDayOfMonth = moment([year, month - 1, 1]).utcOffset(7);
    let lastDayOfMonth = moment([year, month - 1]).endOf('month').utcOffset(7);

    // หาวันแรกและวันสุดท้ายของสัปดาห์ที่ครอบคลุมวันที่เดือนนั้น
    let firstDayOfWeek = firstDayOfMonth.clone().startOf('week');
    let lastDayOfWeek = lastDayOfMonth.clone().endOf('week');

    // จัดรูปแบบวันที่เป็นปี-เดือน-วัน
    return {
        startDateRange: firstDayOfWeek.format('YYYY-MM-DD'),
        endDateRange: lastDayOfWeek.format('YYYY-MM-DD')
    };
}

const errorHandler = (defaultMessage, statusCode, customMessage, isToast = false, callback) => {
    const errorMessage = customMessage || getErrorMessageByStatusCode(defaultMessage, statusCode);

    if (!isToast) {
        showErrorAlert(errorMessage, callback);
    } else {
        showErrorToast(errorMessage, callback);
    }
}

const getErrorMessageByStatusCode = (defaultMessage, statusCode) => {
    switch (statusCode) {
        case 400:
            return defaultMessage;
        case 401:
            return 'Unauthorized';
        case 403:
            return 'บัญชีผู้ใช้งานนี้ถูกระงับการใช้งาน';
        case 404:
            return 'ไม่พบข้อมูล';
        case 500:
            return 'Internal Server Error';
        default:
            return defaultMessage;
    }
}

const showErrorToast = (errorMessage, callback = () => { }) => {
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-start',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });

    Toast.fire({
        title: "พบข้อผิดพลาด",
        text: errorMessage,
        icon: "error",
        didClose: () => callback(),
    });
}

const showErrorAlert = (errorMessage, callback = () => { }) => {
    Swal.fire({
        title: "พบข้อผิดพลาด",
        text: errorMessage,
        icon: "error",
        didClose: () => callback(),
    });
}

export {
    base64ImageToFile,
    formatFileSize,
    hashString,
    jsonToQueryParam,
    getCalendarRange,
    errorHandler,
}
