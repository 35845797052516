import React, { useState } from 'react'
import './ImagePreview.css'

import { IconButton, Typography } from '@mui/material'
import { Clear } from '@mui/icons-material'

function ImagePreview({ image, deleteable, onDelete }) {

    const [openImage, setOpenImage] = useState(false);

    const handleClose = () => setOpenImage(false);
    const handleToggle = () => setOpenImage(!openImage);

    const handleDeleteClick = () => {
        onDelete(image.imagePreview);
    }

    return (
        <div className="image-preview">
            <div className="image-with-popup" style={{ display: 'grid', placeItems: 'center' }}>
                <img className="image" src={image.imagePreview} alt="" onMouseEnter={handleToggle} onMouseLeave={handleClose} />
                <Typography>{image.originalname}</Typography>

                {
                    deleteable && (
                        <IconButton className="delete" onClick={handleDeleteClick}>
                            <Clear fontSize="small" />
                        </IconButton>
                    )
                }

                {/* <img 
                    className="hover-image" 
                    loading="lazy" 
                    src={image.imagePreview} 
                    alt="" 
                /> */}
            </div>
        </div>
    )
}

export default ImagePreview