import React, { useEffect, useState } from 'react'
import './ManageDroneForm.css'

import { 
    FormControl, FormControlLabel, FormLabel, 
    Grid, IconButton, InputLabel, 
    MenuItem, Radio, RadioGroup, 
    Select, TextField, Typography, 
} from '@mui/material'
import { Add, CloudUpload, Remove } from '@mui/icons-material';

import { useNavigate, useParams } from 'react-router-dom';

import { droneServices } from '../../services/droneServices';

import { base64ImageToFile } from '../../utils';

import DragAndDrop from '../../components/DragAndDrop/DragAndDrop';
import ImagePreview from '../../components/ImagePreview/ImagePreview'
import OptionButton from '../../components/OptionButton/OptionButton';

import useDroneSize from '../../hooks/useDroneSize';
import useDroneType from '../../hooks/useDroneType';
import useErrorHandler from '../../hooks/useErrorHandler';

const fileTypes = { 'image/png': [".jpeg", '.jpg', '.png'] };
const droneStatusList = {
    ready: {
        label: 'พร้อมใช้งาน',
        value: 'ready',
    },
    busy: {
        label: 'กำลังดำเนินการ',
        value: 'busy',
    },
    unavailable: {
        label: 'ไม่พร้อมใช้งาน',
        value: 'unavailable',
    }
}

function ManageDroneForm() {
    const { droneCode } = useParams();
    const navigate = useNavigate();

    const { errorHandler } = useErrorHandler();
    const { droneTypeList } = useDroneType();
    const { droneSizeList } = useDroneSize();

    const [drone, setDrone] = useState({
        droneCode: '',
        typeName: '',
        droneSize: '',
        equippedPayload: '',
        droneStatus: 'ready',
    });
    const [photos, setPhotos] = useState([]);
    
    const [openDroneTypeDropdown, setOpenDroneTypeDropdown] = useState(false);
    const [openDroneSizeDropdown, setOpenDroneSizeDropdown] = useState(false);

    useEffect(() => {
        const getDroneByDroneCode = async () => {
            try {
                const res = await droneServices.getDroneByDroneCode(droneCode);
                console.log(res);
                if (res.status === 200) {
                    const data = res.data.data;
                    setDrone({
                        droneCode: data.drone_code,
                        typeName: data.DroneType.type_name,
                        droneStatus: data.drone_status,
                        droneSize: data.DroneSize.size_name,
                        equippedPayload: data.equipped_payload,
                    });
                    setPhotos(data.photos.map(photo => {
                        return {
                            ...photo,
                            data: photo.imagePreview ? base64ImageToFile(photo.imagePreview, photo.originalname) : null,
                        }
                    }));
                }
            } catch (error) {
                console.log(error);
                const { status, data } = error.response;
                errorHandler(
                    'error: getDroneByDroneCode',
                    status,
                    data.message,
                    false,
                    goToDashboard
                );
            }
        }

        droneCode && getDroneByDroneCode();
    }, [droneCode]);

    const handleOpenDroneTypeDropdown = () => {
        setOpenDroneTypeDropdown(!openDroneTypeDropdown);
        setDrone(prev => {
            return {
                ...prev,
                typeName: '',
            }
        });
    }
    
    const handleOpenDroneSizeDropdown = () => {
        setOpenDroneSizeDropdown(!openDroneSizeDropdown);
        setDrone(prev => {
            return {
                ...prev,
                droneSize: '',
            }
        });
    }

    const handleDataChange = ({ target }) => {
        setDrone(prev => {
            return {
                ...prev,
                [target.name]: target.value
            }
        });
    }

    const handlePhotoChange = (acceptedFiles) => {
        console.log(acceptedFiles);
    
        setPhotos(prev => {
            return [
                ...prev,
                ...acceptedFiles.map(file => {
                    return {
                        data: file,
                        originalname: file.name,
                        imagePreview: URL.createObjectURL(file),
                    }
                }),
            ]
        });
    };

    const handleDeletePhotoClick = (currentPhoto) => {
        const deletedList = photos.filter(photo => photo.originalname !== currentPhoto.originalname);
        setPhotos(deletedList);
    }

    const handleSaveClick = (e) => {
        e.preventDefault();
        const data = {
            drone_code: drone.droneCode,
            type_name: drone.typeName,
            drone_size: drone.droneSize,
            equipped_payload: drone.equippedPayload,
            drone_status: drone.droneStatus,
            photos: photos.map(photo => photo.data),
        }
        console.log(data);

        if (!droneCode) {
            createDrone(data);
        } else {
            updateDrone(data);
        }
    }

    const goToDashboard = () => navigate('/dashboard/manage-drone');

    const createDrone = async (data) => {
        try {
            const res = await droneServices.createDrone(data);
            console.log(res);
            if (res.status === 201) {
                console.log('drone created');
                goToDashboard();
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: createDrone',
                status,
                data.message
            );
        }
    }

    const updateDrone = async (data) => {
        console.log(data);
        try {
            const res = await droneServices.updateDrone(data);
            console.log(res);
            if (res.status === 201) {
                console.log('drone updated');
                goToDashboard();
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: updateDrone',
                status,
                data.message
            );
        }
    }

    return (
        <form onSubmit={handleSaveClick}>
            <Grid container alignItems="flex-start" className="manage-drone-form">
                <Grid container item xs={6} gap={2}>
                    <GridForm>
                        <TextField 
                            fullWidth
                            required
                            name="droneCode"
                            value={drone.droneCode} 
                            onChange={handleDataChange}
                            placeholder="รหัสอากาศยาน" 
                        />
                    </GridForm>
                    <GridForm>
                        <div style={{ display: 'flex' }}>
                            {
                                openDroneTypeDropdown ? (
                                    <TextField 
                                        fullWidth 
                                        required
                                        placeholder="ประเภทอากาศยาน" 
                                        name="typeName"
                                        value={drone.typeName} 
                                        onChange={handleDataChange}
                                    />
                                ) : (
                                    <FormControl fullWidth>
                                        <InputLabel id="type-name">ประเภทอากาศยาน</InputLabel>
                                        <Select 
                                            required
                                            displayEmpty
                                            labelId="type-name" 
                                            label="ประเภทอากาศยาน"
                                            name="typeName"
                                            value={drone.typeName} 
                                            onChange={handleDataChange}
                                        >
                                            {droneTypeList.map(droneType => 
                                                <MenuItem 
                                                    key={droneType.id} 
                                                    value={droneType.type_name}
                                                    disabled={!droneType.table_status}
                                                >
                                                    {droneType.type_name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                )
                            }
                            <IconButton onClick={handleOpenDroneTypeDropdown}>
                                { openDroneTypeDropdown ? <Remove /> : <Add />}
                            </IconButton>
                        </div>
                    </GridForm>
                    <GridForm>
                        <div style={{ display: 'flex' }}>
                            {
                                openDroneSizeDropdown ? (
                                    <TextField 
                                        fullWidth
                                        required
                                        name="droneSize"
                                        value={drone.droneSize} 
                                        onChange={handleDataChange}
                                        placeholder="ขนาดอากาศยาน" 
                                    />
                                ) : (
                                    <FormControl fullWidth>
                                        <InputLabel id="drone-size">ขนาดอากาศยาน</InputLabel>
                                        <Select 
                                            required
                                            displayEmpty
                                            labelId="drone-size" 
                                            label="ขนาดอากาศยาน"
                                            name="droneSize"
                                            value={drone.droneSize} 
                                            onChange={handleDataChange}
                                        >
                                            {droneSizeList.map(droneSize => 
                                                <MenuItem 
                                                    key={droneSize.id} 
                                                    value={droneSize.size_name}
                                                    disabled={!droneSize.table_status}
                                                >
                                                    {droneSize.size_name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                )
                            }
                            <IconButton onClick={handleOpenDroneSizeDropdown}>
                                { openDroneSizeDropdown ? <Remove /> : <Add />}
                            </IconButton>
                        </div>
                    </GridForm>
                    <GridForm>
                        <TextField 
                            fullWidth
                            name="equippedPayload"
                            value={drone.equippedPayload} 
                            onChange={handleDataChange}
                            placeholder="สัมภาระที่ติดตั้งได้" 
                        />
                    </GridForm>
                    <Grid item xs={6}>
                        <div className="btn">
                            <OptionButton 
                                text="Save"
                                type="submit"
                                bgColor="var(--options-btn-bg-color-5)"
                                hoverColor="var(--options-btn-bg-hover-5)"
                            />
                            <OptionButton 
                                text="Cancel"
                                bgColor="var(--options-btn-bg-color-2)"
                                hoverColor="var(--options-btn-bg-hover-2)"
                                textColor="gray"
                                onClick={goToDashboard}
                            />
                        </div>
                    </Grid>
                </Grid>

                <Grid container item xs={6} gap={2}>
                    <Grid item xs={12}>
                        <FormLabel className="header-text">สถานะอากาศยาน</FormLabel>
                        <RadioGroup 
                            name="droneStatus"
                            value={drone.droneStatus}
                            onChange={handleDataChange}
                        >
                            {Object.keys(droneStatusList)
                                .map(status => {
                                    const value = droneStatusList[status].value;
                                    const label = droneStatusList[status].label;
                                    return (
                                        <FormControlLabel
                                            key={status}
                                            className="content-text"
                                            value={value} 
                                            control={<Radio />} 
                                            label={label} 
                                        />
                                    )
                                })
                            }
                        </RadioGroup>
                    </Grid>

                    <Grid item xs={12} className="image-upload">
                        <div className="header">
                            <CloudUpload className="icon" />
                            <Typography className="header-text">
                                อัปโหลดรูปภาพอากาศยาน (ไฟล์ชนิด jpg, png ขนาดไม่เกิน 1024x1024)
                            </Typography>
                        </div>
                        <DragAndDrop fileTypes={fileTypes} maxFiles={50} onDrop={handlePhotoChange}>
                            {
                                photos.length !== 0 && (
                                    <div className="image-container">
                                        {photos.map(photo => 
                                            photo.imagePreview && (
                                                <ImagePreview 
                                                    key={photo.imagePreview} 
                                                    deleteable
                                                    image={photo} 
                                                    onDelete={() => handleDeletePhotoClick(photo)}
                                                />
                                            )
                                        )}
                                    </div>
                                )
                            }
                        </DragAndDrop>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}

export default ManageDroneForm

const GridForm = ({ children }) => {
    return (
        <Grid item xs={10} sx={{ bgcolor: '#FAFAFA', borderRadius: 3, p: 3 }}>
            {children}
        </Grid>
    )
}