import React, { useCallback, useEffect, useState } from 'react'
import './SurveyResult.css'

import {
    Box, Checkbox, FormControl,
    FormControlLabel, Grid, InputAdornment,
    MenuItem, Select, TextField, Typography
} from '@mui/material'

import { useNavigate } from 'react-router-dom';

import DataTable from '../../components/DataTable/DataTable'
import OptionButton from '../../components/OptionButton/OptionButton';
import DeleteButton from '../../components/TableButton/DeleteButton';
import EditButton from '../../components/TableButton/EditButton';
import StatusButton from '../../components/TableButton/StatusButton';
import ExcelButton from '../../components/ExcelButton/ExcelButton';

import { surveyResultServices } from '../../services/surveyResultServices';

import useAreaBoundary from '../../hooks/useAreaBoundary';
import useDataTable from '../../hooks/useDataTable';
import useErrorHandler from '../../hooks/useErrorHandler';

const columns = [
    {
        field: 'row_num',
        headerName: 'ลำดับ',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        sortable: false,
    },
    {
        field: 'target_area',
        headerName: 'จังหวัด',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        minWidth: 150,
        sortable: false,
        valueGetter: (param) => param.row.AreaBoundary.target_area
    },
    {
        field: 'area_type',
        headerName: 'ประเภทพื้นที่',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        minWidth: 150,
        sortable: false,
        valueGetter: (param) => {
            const areaType = param.row.area_type;
            const label = areaTypes.find(at => at.value === areaType).label;
            return label;
        }
    },
    {
        field: 'category_name',
        headerName: 'หมวดหมู่',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        minWidth: 70,
        sortable: false,
        valueGetter: (param) => param.row.Category.category_name
    },
    {
        field: 'table_status',
        headerName: 'สถานะ',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        renderCell: (param) => <StatusButton data={param.row} service={surveyResultServices.updateSurveyResultStatus} />
    },
    {
        field: 'edit',
        headerName: 'แก้ไข',
        headerAlign: 'center',
        type: 'actions',
        sortable: false,
        renderCell: (param) => <EditButton id={param.row.id} formURL="survey-result-form" />
    },
    {
        field: 'delete',
        type: 'actions',
        sortable: false,
        renderCell: (param) => <DeleteButton id={param.row.id} service={surveyResultServices.deleteSurveyResultById} />
    },
];

const initFilters = {
    search: '',
    areaType: [],
    areaBoundaryId: '',
    dateFrom: '',
    dateTo: '',
}

const areaTypes = [
    { label: 'ชายฝั่งทะเล', value: 'sea-coast', checked: false },
    { label: 'ป่าชายเลน', value: 'mangrove-forest', checked: false },
]

function SurveyResult() {
    const { paginationModel, resetPage } = useDataTable();
    const { errorHandler } = useErrorHandler();

    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [paginationfilters, setPaginationFilters] = useState(initFilters);

    const getAllSurveyResult = useCallback(async (currentFilters) => {
        try {
            const res = await surveyResultServices.getAllSurveyResult(currentFilters);
            console.log('getAllSurveyResult => ', res);
            if (res.status === 200) {
                setRows(res.data.data);
                setRowCount(res.data.rowCount);
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: getAllSurveyResult',
                status,
                data.message
            );
        }
    }, []);

    useEffect(() => {
        getAllSurveyResult({ ...paginationfilters, ...paginationModel });
    }, [paginationModel.page, paginationfilters])

    return (
        <div className="survey-result">
            <Filters setPaginationFilters={setPaginationFilters} onSearchClick={resetPage} />
            <DataTable
                columns={columns}
                rows={rows}
                rowCount={rowCount}
                pageProps={paginationModel}
            />
        </div>
    )
}

export default SurveyResult

const Filters = ({ setPaginationFilters, onSearchClick }) => {
    const navigate = useNavigate();

    const { areaBoundaryList } = useAreaBoundary();

    const [filters, setFilters] = useState(initFilters);
    const [areaTypeList, setAreaTypeList] = useState([...areaTypes]);

    const handleFilterChange = ({ target }) => {
        const { name, value, type, checked } = target;
        console.log(value);
        console.log(checked);

        if (type === "checkbox") {
            setAreaTypeList(prev => {
                const index = prev.findIndex(a => a.value === value);
                const newAreaType = prev[index];

                newAreaType.checked = checked;

                prev[index] = newAreaType;

                return [...prev]
            });
        }

        setFilters(prev => {
            return {
                ...prev,
                [name]: type === "checkbox" ? (
                    checked ? (
                        [...prev.areaType, value]
                    ) : prev.areaType.filter(a => a !== value)
                ) : value,
            }
        });
    }

    const handleAddClick = () => navigate('/dashboard/survey-result-form');

    const handleSearchClick = () => {
        console.log(filters);
        setPaginationFilters({ ...filters });
        onSearchClick();
    }

    const handleClearClick = () => {
        setAreaTypeList(prev => prev.map(areaType => {
            return {
                ...areaType,
                checked: false,
            }
        }));
        setFilters({ ...initFilters });
        setPaginationFilters({ ...initFilters });
        onSearchClick();
    }

    return (
        <>
            <Grid container className="filters" gap={1}>
                <Grid container item md={12} display="flex" alignItems="center">
                    <Grid item md={1}>
                        <Typography>ค้นหา</Typography>
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            fullWidth
                            size="small"
                            name="search"
                            value={filters.search}
                            onChange={handleFilterChange}
                        />
                    </Grid>
                </Grid>
                <Grid container item md={12} display="flex" alignItems="center">
                    <Grid item md={1}>
                        <Typography>ประเภทพื้นที่</Typography>
                    </Grid>
                    <Grid item md={6}>
                        {areaTypeList.map(areaType => (
                            <FormControlLabel
                                key={areaType.value}
                                control={<Checkbox />}
                                label={areaType.label}
                                name="areaType"
                                checked={areaType.checked}
                                value={areaType.value}
                                onChange={handleFilterChange}
                            />
                        ))}
                    </Grid>
                </Grid>
                <Grid container item md={12} display="flex" alignItems="center">
                    <Grid item md={1}>
                        <Typography>จังหวัด</Typography>
                    </Grid>
                    <Grid item md={6}>
                        <FormControl fullWidth size="small">
                            <Select
                                name="areaBoundaryId"
                                value={filters.areaBoundaryId}
                                onChange={handleFilterChange}
                            >
                                {areaBoundaryList.map(areaBoundary =>
                                    <MenuItem
                                        key={areaBoundary.id}
                                        value={areaBoundary.id}
                                        disabled={!areaBoundary.table_status}
                                    >
                                        {areaBoundary.target_area}
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container item md={12} display="flex" alignItems="center">
                    <Grid item md={1}>
                        <Typography>วันที่เพิ่ม</Typography>
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            fullWidth
                            type="date"
                            size="small"
                            name="dateFrom"
                            value={filters.dateFrom}
                            onChange={handleFilterChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Box>From</Box>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            fullWidth
                            type="date"
                            size="small"
                            name="dateTo"
                            value={filters.dateTo}
                            onChange={handleFilterChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Box>To</Box>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className="options">
                <Grid item md={1}></Grid>
                <Grid
                    item xs={12} md={6}
                    sx={{
                        '& button': { width: '25%' },
                        '& :not(:nth-of-type(1))': { ml: 2 }
                    }}
                >
                    <OptionButton
                        text='ค้นหา'
                        bgColor='var(--options-btn-bg-color-1)'
                        hoverColor='var(--options-btn-bg-hover-1)'
                        onClick={handleSearchClick}
                    />
                    <OptionButton
                        text='ล้าง'
                        bgColor='var(--options-btn-bg-color-2)'
                        hoverColor='var(--options-btn-bg-hover-2)'
                        textColor='gray'
                        onClick={handleClearClick}
                    />
                    <OptionButton
                        text='เพิ่ม'
                        bgColor='var(--options-btn-bg-color-1)'
                        hoverColor='var(--options-btn-bg-hover-1)'
                        onClick={handleAddClick}
                    />
                </Grid>

                <ExcelButton modelName="SurveyResult" importCallback={handleClearClick} />
            </Grid>
        </>
    )
}