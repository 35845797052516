import { useAxios } from "../guard/axiosConfig"

export const surveyResultServices = {
    getAllSurveyResult,
    getSurveyResultById,
    createSurveyResult,
    updateSurveyResult,
    updateSurveyResultStatus,
    deleteSurveyResultById,
    getMapData,
}

const route = '/surveyResult';

function getAllSurveyResult(filters) {
    return useAxios.get(`${route}/getAllSurveyResult`, { params: filters });
}

function getSurveyResultById(id) {
    return useAxios.get(`${route}/getSurveyResultById/${id}`);
}

function createSurveyResult(data) {
    return useAxios.post(`${route}/createSurveyResult`, data);
}

function updateSurveyResult(data) {
    return useAxios.put(`${route}/updateSurveyResult`, data);
}

function updateSurveyResultStatus(data) {
    return useAxios.put(`${route}/updateSurveyResultStatus`, data);
}

function deleteSurveyResultById(id) {
    return useAxios.delete(`${route}/deleteSurveyResultById/${id}`);
}

function getMapData(filters) {
    return useAxios.get(`${route}/getMapData`, { params: filters });
}