import React, { useEffect, useRef } from 'react'
import './FormModal.css'

import { useSearchParams } from 'react-router-dom';

import { Box, Modal, TextField, Typography } from '@mui/material';

import OptionButton from '../OptionButton/OptionButton';

import useErrorHandler from '../../hooks/useErrorHandler';

function FormModal({ findById, service, modalTitle, fieldName, openModal, onClose, onDataChange }) {
    const inputRef = useRef();
    const [searchParams, setSearchParams] = useSearchParams();

    const { errorHandler } = useErrorHandler();

    useEffect(() => {
        const getDataById = async () => {
            try {
                const res = await findById(searchParams.get("id"));
                console.log(res);
                if (res.status === 200) {
                    inputRef.current.value = res.data.data[fieldName];
                }
            } catch (error) {
                console.log(error);
                const { status, data } = error.response;
                errorHandler(
                    `error: ${findById.name}`,
                    status,
                    data.message,
                    false,
                    handleCloseModal
                );
            }
        }

        if (searchParams.has("id")) {
            searchParams.get("id") && getDataById();
        }
    }, [searchParams]);

    const handleCloseModal = () => {
        const currentParams = Object.fromEntries([...searchParams]);
        delete currentParams.id
        setSearchParams({ ...currentParams });
        onClose();
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = { [fieldName]: inputRef.current.value }
        console.log(data);

        if (searchParams.has("id")) {
            data.id = searchParams.get("id");
        }

        try {
            const res = await service(data);
            console.log(res);
            if (res.status === 201) {
                console.log('created/updated');
                onDataChange(res.data.data);
                handleCloseModal();
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                `error: ${service.name}`,
                status,
                data.message
            );
        }
    }

    return (
        <Modal className="form-modal" open={openModal} onClose={handleCloseModal}>
            <Box className="content" sx={{ boxShadow: 24 }}>
                <Typography variant="h6" component="h2">
                    {modalTitle}
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        required
                        fullWidth
                        size="small"
                        inputRef={inputRef}
                    />
                    <div className="button">
                        <OptionButton
                            text='Cancel'
                            bgColor='var(--options-btn-bg-color-2)'
                            hoverColor='var(--options-btn-bg-hover-2)'
                            textColor="gray"
                            onClick={handleCloseModal}
                        />
                        <OptionButton
                            text='Save'
                            type='submit'
                            bgColor='var(--options-btn-bg-color-5)'
                            hoverColor='var(--options-btn-bg-hover-5)'
                        />
                    </div>
                </form>
            </Box>
        </Modal>
    )
}

export default FormModal