import React from 'react'
import './Sidebar.css'

import { 
    Box, Divider, Drawer, List, ListItem, 
    ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography 
} from '@mui/material';

import { useLocation, useNavigate } from 'react-router-dom';

import { sidebarMenu } from '../../constants/sidebarMenu'

const DRAWER_WIDTH = 240;

function Sidebar(props) {
    const navigate = useNavigate();
    const { window, mobileOpen, handleDrawerToggle } = props;
    const container = window !== undefined ? () => window().document.body : undefined;

    const handleHomeClick = () => navigate('/');
    
    const menuList = (
        <div>
            <Toolbar 
                className="home"
                onClick={handleHomeClick}
                sx={{ 
                    color: 'var(--sidebar-text-color)', 
                    '& img': { ml: '1rem', width: '65%', aspectRatio: '3/2', objectFit: 'contain' } 
                }}
            >
                <Typography sx={{ fontSize: '1.2rem', fontWeight: 700 }}>Home</Typography>
                <img src="/logo-default.png" alt="" />
            </Toolbar>

            <Divider sx={{ backgroundColor: "#666666" }}/>

            <List>
                {sidebarMenu.map((menu) => (
                    <MenuItem
                        key={menu.menuName}
                        menu={menu}
                        mobileOpen={mobileOpen}
                        handleDrawerToggle={handleDrawerToggle}
                    />
                ))}
            </List>
        </div>
    );

    const mobileDrawer = (
        <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
                keepMounted: true,
            }}
            sx={{
                display: { sm: 'block', md: 'none' },
                '& .MuiDrawer-paper': { 
                    boxSizing: 'border-box', 
                    width: DRAWER_WIDTH, 
                    bgcolor: 'var(--sidebar-bg-color)',
                },
            }}
        >
            {menuList}
        </Drawer>
    )

    const desktopDrawer = (
        <Drawer
            open
            variant="permanent"
            sx={{
                display: { xs: 'none', sm: 'none', md: 'block' },
                '& .MuiDrawer-paper': { 
                    boxSizing: 'border-box', 
                    width: DRAWER_WIDTH, 
                    bgcolor: 'var(--sidebar-bg-color)',
                },
            }}
        >
            {menuList}
        </Drawer>
    )

    return (
        <Box
            component="nav"
            className="sidebar"
            sx={{ width: { md: DRAWER_WIDTH }, flexShrink: { md: 0 } }}
        >
            {mobileDrawer}
            {desktopDrawer}
        </Box>
    )
}

export default Sidebar

function MenuItem({ menu, mobileOpen, handleDrawerToggle }) {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const handleMenuClick = (menu) => {
        navigate(`/dashboard${menu.path}`);

        if (mobileOpen) {
            handleDrawerToggle();
        }

    }

    return (
        <div className="menu-item">
            <ListItem
                key={menu.menuName}
                disablePadding
                onClick={() => handleMenuClick(menu)}
                sx={{ color: pathname.includes(menu.path) && 'var(--sidebar-text-color-hover)' }}
            >
                <ListItemButton>
                    <ListItemIcon>
                        {menu.icon}
                    </ListItemIcon>
                    <ListItemText primary={menu.menuName} sx={{ '& span': { fontSize: '0.8rem', fontWeight: 700 } }} />
                </ListItemButton>
            </ListItem>
        </div>
    )
}