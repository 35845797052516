import { useAxios } from "../guard/axiosConfig"

export const targetTypeServices = {
    createTargetType,
    getAllTargetType,
    getTargetTypeById,
    updateStatus,
    updateTargetType,
    deleteTargetTypeById,
}

const route = '/targetType';

function createTargetType(data) {
    return useAxios.post(`${route}/createTargetType`, data);
}

function getAllTargetType(filters) {
    return useAxios.get(`${route}/getAllTargetType`, { params: filters });
}

function getTargetTypeById(id) {
    return useAxios.get(`${route}/getTargetTypeById/${id}`);
}

function updateStatus(data) {
    return useAxios.put(`${route}/updateStatus`, data);
}

function updateTargetType(data) {
    return useAxios.put(`${route}/updateTargetType`, data);
}

function deleteTargetTypeById(id) {
    return useAxios.delete(`${route}/deleteTargetTypeById/${id}`);
}